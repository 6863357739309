import * as actionTypes from "../../actions/NewStoreApprove/NewApproveActionTypes"
import { nsApproveInitState } from "./NewStoreApproveInitState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    nsApproveReducer: handleActions(
        {
            [actionTypes.GET_NEW_STORE_LIST]: (state, payload) => {
                return immutable(state, {
                    data: { $set: [] },
                    dataLoading: { $set: true },
                    dataError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_STORE_LIST_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    data: { $set: payload?.data || [] },
                    dataLoading: { $set: false },
                    dataError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_STORE_LIST_ERROR]: (state, payload) => {
                return immutable(state, {
                    data: { $set: [] },
                    dataLoading: { $set: false },
                    dataError: { $set: payload?.error }
                });
            },
            [actionTypes.GET_NEW_STORE_ARTICLE_DETAILS]: (state, payload) => {
                return immutable(state, {
                    articleData: { $set: {} },
                    articleDataLoading: { $set: true },
                    articleDataError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_STORE_ARTICLE_DETAILS_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    articleData: { $set: payload?.data || {} },
                    articleDataLoading: { $set: false },
                    articleDataError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_STORE_ARTICLE_DETAILS_ERROR]: (state, payload) => {
                return immutable(state, {
                    articleData: { $set: {} },
                    articleDataLoading: { $set: false },
                    articleDataError: { $set: payload?.error }
                });
            },
            [actionTypes.APPROVE_NEW_STORE_ARTICLES]: (state, payload) => {
                return immutable(state, {
                    approveNewStoreArticlesSuccess: { $set: false },
                    approveNewStoreArticlesLoading: { $set: true },
                    approveNewStoreArticlesError: { $set: null }
                });
            },
            [actionTypes.APPROVE_NEW_STORE_ARTICLES_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    approveNewStoreArticlesSuccess: { $set: true },
                    approveNewStoreArticlesLoading: { $set: false },
                    approveNewStoreArticlesError: { $set: null }
                });
            },
            [actionTypes.APPROVE_NEW_STORE_ARTICLES_ERROR]: (state, payload) => {
                return immutable(state, {
                    approveNewStoreArticlesSuccess: { $set: false },
                    approveNewStoreArticlesLoading: { $set: false },
                    approveNewStoreArticlesError: { $set: payload?.error }
                });
            },
            [actionTypes.RESET_APPROVE_DETAILS]: (state, data) => {
                return immutable(state, {
                    approveNewStoreArticlesLoading: { $set: false },
                    approveNewStoreArticlesError: { $set: null },
                    approveNewStoreArticlesSuccess: { $set: false }
                });
            },
            [actionTypes.RESET_NEW_STORE_APPROVE]: (state, data) => {
                return immutable(state, {
                    data: { $set: [] },
                    dataLoading: { $set: false },
                    dataError: { $set: null },
                    articleData: { $set: {} },
                    articleDataLoading: { $set: false },
                    articleDataError: { $set: null },
                    approveNewStoreArticlesLoading: { $set: false },
                    approveNewStoreArticlesError: { $set: null },
                    approveNewStoreArticlesSuccess: { $set: false }
                });
            }
        },
        nsApproveInitState
    )
}