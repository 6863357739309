import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Calendar } from "primereact/calendar";
import { cloneDeep, isEmpty } from 'lodash';
import moment from "moment";
import ReactTable from '../../../../components/Table/ReactTable';
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import Select from "../../../../components/Filters/SingleSelect";
import * as storeConfigDetailsActions from '../../actions/StoreDetailsConfigActions/StoreConfigActions';
import * as StoreDetailsConsts from './StoreDetailsDcConfigConstants';
import "./Storedetailsconfig.css";
import { getStoreGradeColumnNamesByClient, getCapacityColumnsByClient } from "../../../../utils/commonUtilities"
import MultiSelect from '../../../../components/Filters/MultiSelect';
import { handleSelectAll } from '../../../../utils/SelectAllHandler/SelectAllHander';
import * as Notify from "../../../../components/Notification/Notifications";
import DcLeadTimeCard from './DcLeadTimeCard';
import { saveLeadTimeDcMapping } from "../../actions/DCConfigAction/DCConfigAction"
import PageLoader from '../../../../components/Loader/PageLoader';

/**
 * Table Plugin
 */
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

/**
 * New Store Filetring Component
 * @param {*} props 
 * @returns 
 */
const StoreOpeningDetails = (props) => {
    let groupId = localStorage.getItem('group_id');

    const [dcLeadTimeCards, setDcLeadTimeCards] = useState([{}])
    const [leadTimeDcOptions, setDcOptions] = useState([])
    const [leadTimeDcMapping, setLeadTimeMapping] = useState({})

    const { storeConfigDetailsActionsProp, newStoreDetailsData, newStoreOptionsData, dcDetails, saveLeadTimeDcMapping,
        selectedNewStoreCode, selectedNewStore, newStoreOpeningDate, editModeDetails, storeGroups, dcLeadMapping, editMode, approved
    } = props;


    useEffect(() => {
        if (!isEmpty(editModeDetails) && editModeDetails?.isEditMode && !selectedNewStore?.length) {
            storeConfigDetailsActionsProp.setSelectedNewStoreCode(
                {
                    value: editModeDetails?.editModeNewStoreCode,
                    label: editModeDetails?.editModeNewStoreCode
                }
            );
            const selectedStore = newStoreDetailsData.filter(detail => detail.store_code === editModeDetails?.editModeNewStoreCode);
            storeConfigDetailsActionsProp.setSelectedNewStoreDetail(selectedStore);
        }
    }, []);

    useEffect(() => {
        // setDcOptions(dcDetails[0]?.value === "*" ? dcDetails.slice(1) : dcDetails)
        if (dcLeadMapping?.cards) {
            Object.values(dcLeadMapping?.cards?.[0])?.length && setDcLeadTimeCards(dcLeadMapping?.cards)
        }
        else {
            // let dcLeadTimeCards = dcDetails.filter(dc => dc.transit_time)?.map(item => {return {dc: {label: item.dc_name, value: item.dc}, leadTime: item.transit_time}})
            let dcCards = dcDetails?.filter(dc => dc.value !== "*").map(item => {return {dc: {...item}, leadTime: item.leadTime}})
            
            setDcOptions(dcCards?.length ? dcCards : [])
            if(dcCards?.length) {
                // dcCards = [dcCards[0]]
                let dcMapping = {}
                dcCards.forEach(item => {
                    dcMapping[item.dc.value] = item.leadTime
                })
                setLeadTimeMapping(dcMapping)
            }
            setDcLeadTimeCards(dcCards?.length ? dcCards : [{}])
        }
        if (!isEmpty(dcLeadMapping?.mapping)) {
            setLeadTimeMapping(dcLeadMapping?.mapping)
            setDcOptions(dcDetails.filter(item => !Object.keys(dcLeadMapping?.mapping).includes(item.value) && item.value !== "*"))
        }
        if(!dcDetails?.length || (!selectedNewStore[0]?.primary_dc?.length && !selectedNewStore[0]?.PDC?.length) && !editMode) {
            if(dcLeadTimeCards?.length > 1) {
                setDcLeadTimeCards([{}])
            }
            // setLeadTimeMapping({})
            setDcOptions([])
        }
    }, [dcDetails, dcLeadMapping])

    const onStoreNumberChange = (storeCode) => {
        storeConfigDetailsActionsProp.setSelectedNewStoreCode(storeCode);
        const selectedStore = newStoreDetailsData.filter(detail => detail.store_code === storeCode.value);
        storeConfigDetailsActionsProp.setSelectedNewStoreDetail(selectedStore);
    };

    const handleOpeningDateChange = (e) => {
        if (e) {
            let selectedStore = cloneDeep(selectedNewStore)

            const changedOpeningDate = moment(e.value).format("YYYY-MM-DD");
            storeConfigDetailsActionsProp.setSelectedNewStoreDetail([{ ...selectedStore[0], open_date: changedOpeningDate, reservation_start_date: "" }]);
        } else {
            //No code here
        }
    };

    const handleReservationDateChange = (e) => {
        if (e) {
            let selectedStore = cloneDeep(selectedNewStore)

            const changedReserveDate = moment(e.value).format("YYYY-MM-DD");
            storeConfigDetailsActionsProp.setSelectedNewStoreDetail([{ ...selectedStore[0], reservation_start_date: changedReserveDate }]);

        } else {
            //No code herez
        }
    };

    const updateNewStoreDetail = (key, value) => {
        let selectedStore = cloneDeep(selectedNewStore)
        storeConfigDetailsActionsProp.setSelectedNewStoreDetail([{ ...selectedStore[0], [key]: value }]);
    }

    const onStoreGroupChange = (val, event, that) => {
        let valuesArray = handleSelectAll(val, event, that);
        updateNewStoreDetail("store_group", valuesArray[1])
    };

    const saveLeadTimeCard = (status, value, index) => {
        if (status) {
            let preCards = cloneDeep(dcLeadTimeCards)
            preCards[index] = { dc: value?.selectedDC, leadTime: value?.leadTime }
            setDcLeadTimeCards(preCards)
            let obj = cloneDeep(leadTimeDcMapping)
            obj[value?.selectedDC?.value] = Number(value?.leadTime)
            // value?.selectedDC?.forEach(element => {
            //     if (element.value !== "*") {
            //         obj[element?.value] = value?.leadTime
            //     }
            // });
            setLeadTimeMapping(obj)
            let dcOptions = cloneDeep(leadTimeDcOptions)
            setDcOptions(dcOptions.filter(item => !Object.keys(obj).includes(item.value)))
            // saveLeadTimeDcMapping({ data: { mapping: obj, cards: preCards } })
        }
    }

    useEffect(() => {
        if(props.onNext){
            saveLeadTimeDcMapping({ data: { mapping: leadTimeDcMapping, cards: dcLeadTimeCards } })
        }
    }, [props.onNext])

    const addFilterSet = () => {
        if (!leadTimeDcOptions.length || dcLeadTimeCards?.length >= dcDetails?.filter(dc => dc.value !== "*")?.length) {
            Notify.error("Maximum limit reached!!")
        }
        else if (dcLeadTimeCards[dcLeadTimeCards.length - 1]?.leadTime >= 0) {
            setDcLeadTimeCards([...dcLeadTimeCards, {}])
        }
        else {
            Notify.error("Please Fill Current Card to add new!!")
        }
    }

    const getNewStoreFields = () => {
        let d = new Date(selectedNewStore[0]?.reservation_start_date);
        d.setDate(d.getDate() + 1)

        const maxDate = new Date(new Date(selectedNewStore[0]?.open_date).getTime() + (- 1) * 24 * 60 * 60 * 1000)
        const storeOpenMaxDate = new Date(new Date().getTime() + (8*7) * 24 * 60 * 60 * 1000)
        const arr = [
            <section className="section mt-4 m-0">

                <div className='column-1'>
                    <div className='store-info-container'>
                    <div className="p-field p-grid required">
                            <label className="non-editable-labels p-col-fixed store_opening_width label-35">
                                Store Opening Date
                            </label>
                            <div className="p-col" style={{ marginTop: "1%" }}>
                                <Calendar
                                    minDate={new Date()}
                                    // maxDate={storeOpenMaxDate}
                                    id="OPENING_DATE"
                                    className="p-col p-mb-2 opening-date-cal"
                                    dateFormat="mm-dd-yy"
                                    value={editModeDetails?.isEditMode && editModeDetails?.editModeOpeningDate ? new Date(editModeDetails.editModeOpeningDate) : new Date(selectedNewStore[0]?.open_date || "")}
                                    onChange={(e) => handleOpeningDateChange(e)}
                                    readOnlyInput
                                    showIcon
                                    // disabled={groupId != 1 || (editMode && new Date(selectedNewStore[0]?.reservation_start_date) < new Date())}
                                    disabled={groupId != 1 || (editMode && approved)}
                                />
                            </div>
                        </div>

                        <div className="p-field p-grid required">
                            <label className="non-editable-labels p-col-fixed store_opening_width label-35">
                                Reservation Start Date
                            </label>
                            <div className="p-col" style={{ marginTop: "1%" }}>
                                <Calendar
                                    minDate={new Date()}
                                    maxDate={maxDate}
                                    id="OPENING_DATE"
                                    className="p-col p-mb-2 opening-date-cal"
                                    dateFormat="mm-dd-yy"
                                    value={selectedNewStore[0]?.reservation_start_date ? new Date(selectedNewStore[0]?.reservation_start_date || "") : ((editModeDetails?.isEditMode && editModeDetails?.editModeOpeningDate) ? new Date(editModeDetails.editModeOpeningDate || "") : "")}
                                    onChange={(e) => handleReservationDateChange(e)}
                                    readOnlyInput
                                    showIcon
                                    disabled={groupId != 1 || (editMode && approved)}
                                />
                            </div>
                        </div>

                        {/* <div className="p-field p-grid required">
                            <label className="non-editable-labels p-col-fixed" style={{ width: "45%" }}>
                                Store Group
                            </label>
                            <div className="p-col w-50" style={{ marginTop: "1%" }}>
                                <MultiSelect
                                    id="NEW_STORE_SELECTOR"
                                    name="storeNumber"
                                    placeHolder={`Select Store Group(s) `}
                                    optionsArray={storeGroups}
                                    dropdownValue={selectedNewStore[0]?.store_group}
                                    className="width-50"
                                    isDisabled={editModeDetails?.isEditMode}
                                    onDropDownChange={onStoreGroupChange}
                                    isDisabled={groupId != 1}
                                />
                            </div>
                        </div> */}
                    </div>

                    <div className="card__wrapper filter-set-container">
                        {dcLeadTimeCards?.map((dc, index) => (
                            <DcLeadTimeCard approved={approved} saveCard={saveLeadTimeCard} allCards={dcLeadTimeCards} dcDetails={dcDetails} card={dc} index={index} />
                        ))}
                        {/* <button className="btn-primary-rounded" onClick={addFilterSet}>
                            <i
                                className="fa fa-plus"
                                title="Create Allocation"
                                aria-hidden="true"
                            ></i>
                        </button> */}
                    </div>
                </div>
            </section>

        ]
        return arr
    }

    return (
        <>
            <h3 className="fnt-bold m-0 px-4 py-2">Store Opening Info</h3>
            <hr />
            <div className="store-search-container">
                <PageLoader loader = {props?.isDcSourceDataLoading}>
                    {getNewStoreFields().map(element => element)}
                </PageLoader>
            </div>
        </>
    );
}

const mapStateToProps = ({ newStoreDetailConfig, sisterStoreDCConfig }) => ({
    newStoreDetailsData: newStoreDetailConfig.newStoreDetailsData,
    newStoreOptionsData: newStoreDetailConfig.newStoreOptionsData,
    selectedNewStoreCode: newStoreDetailConfig.selectedNewStoreCode,
    selectedNewStore: newStoreDetailConfig.selectedNewStore,
    newStoreOpeningDate: newStoreDetailConfig.newStoreOpeningDate,
    editModeDetails: newStoreDetailConfig?.editModeDetails,
    storeGroups: newStoreDetailConfig?.storeGroup,
    dcDetails: newStoreDetailConfig.dcCardDetails,
    dcLeadMapping: sisterStoreDCConfig?.leadTimeDcMapping,
    isDcSourceDataLoading: newStoreDetailConfig?.isDcSourceDataLoading
});

const mapDispatchToProps = (dispatch) => ({
    storeConfigDetailsActionsProp: bindActionCreators(storeConfigDetailsActions, dispatch),
    saveLeadTimeDcMapping: (payload) => dispatch(saveLeadTimeDcMapping(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreOpeningDetails);