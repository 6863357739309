import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import WeekFilter from "./WeekFilter";
import PageLoader from "../../../components/Loader/PageLoader";
import {
  addDollar,
  numberUSFormatting
} from "../../../utils/formatters/valueFormatters";
import {
  LOST_SALES_DETAILS_TABLE_HEADERS,
  LOST_SALES_REVENUE_LABEL,
  LOST_SALES_UNITS_LABEL,
  LOST_SALES_DETAILS_TABLE_HEADER,
  LOST_SALES_NETWORK_ERROR_MSG,
  LOST_SALES_NO_RECORDS_HELPER_MSG,
  LOST_SALES_NO_RECORDS_MSG,
} from "./LostSalesConstants";
import * as lostSalesActions from "./actions/LostSalesReportActions";
import "./LostSalesReport.css";
import { cloneDeep, isEmpty } from "lodash";
import ColumnFilterServerSide from "../../../components/Filters/ColumnFilterServerSide";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import NumberRangeColumnFilterServerSide from "../../../components/Filters/NumberRangeColumnFilterServerSide";
import { LOST_SALES_EXCEL_COLUMNS_FORMATTING } from "../../../components/ExportFile/ExportExcelConstants";
import { getLostSalesReportDetailsTable } from "../../../routes/api";
import ParallelExcelDownload from "../../../components/ParallelExcelDownload/ParallelExcelDownload";
import SwitchInput from "../../../components/SwitchInput/SwitchInput";

const pageSize = 10;

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const LostSalesReportDetailsTable = (props) => {
  const [lostSalesTableInst, setLostSalesTableInst] = useState(null);
  const [lostSalesTablesData, setTableData] = useState([]);
  const [prevIndex, setPrevIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);
  const [searchTermReq, setSearchTermReq] = useState({});
  const [sortReq, setSortReq] = useState({});
  const [isFirstCall, setisFirstCall] = useState(true);
  const [reqBody, setRequest] = useState({});
  const [isQuarterView, setIsQuarterView] = useState(false);
  const [initialHiddenColumns, setInitialHiddenColumns] = useState([])
  const [isShowTable, setIsShowTable] = useState(true);

  const handleLostSalesMount = (inst) => {
    setLostSalesTableInst(inst);
  };

  const lostSalesTableColumns = [
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.FISCAL_YEAR,
        accessor: "year",
        disableFilters: true,
        width: 100,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.FISCAL_QUARTER,
        accessor: "quarter",
        disableFilters: true,
        width: 120,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.FISCAL_WEEK,
        accessor: "week",
        disableFilters: true,
        width: 120,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STORE_ID,
        accessor: "store_code",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.store_code?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        filter: "bulkFilterSpaceSeperated",
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STORE_NAME,
        accessor: "store_name",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.store_name?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 200,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.ARTICLE_ID,
        accessor: "article",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.article?.search}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "array", ",")
            }
            placeholder="Search in bulk by ','"
          />
        ),
        filter: 'bulkFilterCommaSeperated',
        width: 140,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.SIZE,
        accessor: "size",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.size?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 100,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_ID,
        accessor: "style_code",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.style_code?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 100,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.COLOR_ID,
        accessor: "color_code",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.color_code?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 100,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_DESC,
        accessor: "style_name",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.style_name?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 300,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_COLOR,
        accessor: "color_desc",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.color_desc?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.COLOR,
        accessor: "color",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.color?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.UNITS_SOLD,
        accessor: "units",
        Cell: (inst) => numberUSFormatting(inst?.value),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.units?.value?.min}
            maxSearchTermValue={searchTermReq?.units?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.AVG_STORE_CLUSTER_UNITS,
        accessor: "cluster_avg_sales",
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.cluster_avg_sales?.value?.min}
            maxSearchTermValue={searchTermReq?.cluster_avg_sales?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.LOST_OPPORTUNITY_SALES,
        accessor: "lost_sales",
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.lost_sales?.value?.min}
            maxSearchTermValue={searchTermReq?.lost_sales?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        Cell: (inst) => (
          <div>
            {inst.value || inst.value === 0
              ? addDollar(numberUSFormatting(inst.value))
              : null}
          </div>
        ),
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.LOST_OPPORTUNITY_UNITS,
        accessor: "lost_units",
        Cell: (inst) => numberUSFormatting(inst?.value),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.lost_units?.value?.min}
            maxSearchTermValue={searchTermReq?.lost_units?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.OPENING_INVENTORY,
        accessor: "opening_inventory",
        Cell: (inst) => numberUSFormatting(inst?.value),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.opening_inventory?.value?.min}
            maxSearchTermValue={searchTermReq?.opening_inventory?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
  ];

  const {
    lostSalesDetailsTableData,
    lostSalesUnitsValue,
    lostSalesRevenueValue,
    fiscalWeekOptions,
    lostSalesActionsProp,
    lostSalesRequestBody,
    isLostSalesAllDataFailed,
    isLostSalesDetailsDataLoading,
    isLostSalesDetailsDataFailed,
    latestWeek,
    quarters,
    isLostSalesDetailsDataSuccess,
    isLostSalesAllDataSuccess
  } = props;
  const {
    getLostSalesReportDetailsTableData,
  } = lostSalesActionsProp;

  const handleOnChange = (year, quarter, weeks) => {
    setSearchTermReq({});
    setSortReq({});
    setTableData([]);
    setLostSalesTableInst(null);
    setPrevIndex(0);
    setInitialIndex(0)
    setTotalCount(0);
    setNextIndex(0);
    const request = {
      ...lostSalesRequestBody,
      week: weeks,
      year: [year],
      quarter: [quarter],
      // year: [new Date().getFullYear()],
      row_index: 0,
      row_count: 100,
      screen_type: isQuarterView ? "Quarter" : "Week",
      sortColumn: {id: isQuarterView ? "quarter" : "week", desc: true},
    };
    setRequest(request);
    isFirstCall && getLostSalesReportDetailsTableData(request);
  };

  useEffect(() => {
    // const yearData = props.lostSalesGraphData.find(item => item.week == latestWeek?.value)?.year;
    setRequest({ ...lostSalesRequestBody, week: quarters[0]?.weeks, quarter: [quarters[0]?.quarter], year: [quarters[0]?.year]});
  }, [lostSalesRequestBody]);

  useEffect(() => {
    if (lostSalesDetailsTableData?.length) {
      if (lostSalesTableInst && lostSalesTableInst.data) {
        setTableData([
          ...lostSalesTableInst.data,
          ...lostSalesDetailsTableData,
        ]);
      } else {
        setTableData(lostSalesDetailsTableData);
      }
    }
  }, [lostSalesDetailsTableData]);

  const fetchData = (index) => {
    if (prevIndex >= index || nextIndex >= totalCount) return;
    setPrevIndex(index);
    setInitialIndex(Math.floor(nextIndex / pageSize - 1));
    getLostSalesReportDetailsTableData({
      ...reqBody,
      row_index: nextIndex,
      searchColumns: searchTermReq,
      sortColumn: sortReq,
    });
  };

  useEffect(() => {
    if (props.nextIndex == 100) {
      setPrevIndex(0);
      // setInitialIndex(0)
    }
    setTotalCount(props.totalCount);
    setNextIndex(props.nextIndex);
  }, [props.totalCount, props.nextIndex]);

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if(split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",")
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        var id = p_term.split(split_type ? split_type : " ");
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            values: id,
            search: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        let min = p_term[0] == "" ? null : p_term[0];
        let max = p_term[1] == "" ? null : p_term[1];
        if (min == null && max == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: {
              min: min,
              max: max,
            },
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    }
    isFirstCall && setisFirstCall(false);
  };

  const fecthDataWithSorting = (p_sort) => {
    if (!isEmpty(sortReq)) {
      isFirstCall && setisFirstCall(false);
    }
    setSortReq(p_sort[0]);
  };

  useEffect(() => {
    if (
      (!isEmpty(searchTermReq) || !isEmpty(sortReq) || !isFirstCall) &&
      !isEmpty(reqBody)
    ) {
      setLostSalesTableInst(null);
      setTableData([]);
      setInitialIndex(0);
      setPrevIndex(0);
      getLostSalesReportDetailsTableData({
        ...reqBody,
        row_index: 0,
        searchColumns: searchTermReq,
        sortColumn: sortReq,
      });
    }
  }, [searchTermReq, sortReq]);

  const formatExcelData = (data) => {
    let downloadData = [];
    let obj = {};
     data.forEach(detail => {
      obj = {}
      obj["Store"] = detail.store_code
      obj["Store Name"] = detail.store_name
      !isQuarterView && (obj["Fiscal Week"] = detail.week)
      obj["Fiscal Quarter"] = detail.quarter
      obj["Fiscal Year"] = detail.year
      obj[`${updateArticleName(false)} ID`] = detail.article
      obj["Color"] = detail.color
      obj["Style Color"] = detail.color_desc
      obj["Size"] = detail.size
      obj["Units Sold"] = detail.units
      obj["Avg Store Cluster Units"] = detail.cluster_avg_sales
      obj["Opening Inventory"] = detail.opening_inventory
      obj["Lost Opportunity (Units)"] = detail.lost_units
      obj["Lost Opportunity (Revenue)"] = detail.lost_sales
      obj["Style Description"] = detail.style_name
      downloadData.push(obj);
     });
    return downloadData
  };

  const toggleQuarterView = (status) => {
    setIsShowTable(false)
    setTableData([])
    setIsQuarterView(status);
    let request = {
      ...reqBody,
      row_index: 0,
      sortColumn: {id: status ? "quarter" : "week", desc: true},
      screen_type: status ? "Quarter" : "Week",
    }
    setInitialHiddenColumns(status ? ["week"] : [])
    setRequest(request)
    // getLostSalesReportDetailsTableData({
    //   ...request,
    //   row_index: 0,
    //   sortColumn: {id: status ? "quarter" : "week", desc: true},
    //   screen_type: status ? "Quarter" : "Week",
    // });
    setTimeout(() => {
      setIsShowTable(true)
    }, 100)
  }

  useEffect(() => {
    if(quarters?.length) {
      getLostSalesReportDetailsTableData({
        ...lostSalesRequestBody,
        quarter: quarters[0].quarter,
        week: quarters[0].weeks,
        year: quarters[0].year,
        row_index: 0,
      });
    }
  }, [quarters])

  return (
    <PageLoader
      loader={isLostSalesDetailsDataLoading}
      loaderMessage="Loading..."
    >
      {isLostSalesAllDataSuccess || isLostSalesDetailsDataSuccess ? (
        <div
          id="LOST_SALES_REPORT_DETAILS_TABLE_CONTAINER"
          className="lost_sales_details_table_container shadow-sm"
        >
          <h4 className="line-chart__heading">
            {LOST_SALES_DETAILS_TABLE_HEADER}
          </h4>
          <div className="d-flex justify-content-between">
            <span className="total_lost_opportunity">
              <h5 className="line-chart__heading">
                {LOST_SALES_UNITS_LABEL}:{" "}
                {numberUSFormatting(lostSalesUnitsValue)}
              </h5>
              <h5 className="line-chart__heading">
                {LOST_SALES_REVENUE_LABEL}:{" "}
                {`$${numberUSFormatting(lostSalesRevenueValue?.toFixed(0))}`}
              </h5>
            </span>
            <SwitchInput label={"Aggregate by Quarter"} onChangeHandler={toggleQuarterView} />
          </div>
          <ParallelExcelDownload
            apiURL={getLostSalesReportDetailsTable}
            filters={reqBody}
            formatExcelData={formatExcelData}
            existingData={lostSalesTablesData}
            excelColumnsToBeFormatted={LOST_SALES_EXCEL_COLUMNS_FORMATTING}
            searchTermReq={searchTermReq}
            sortReq={sortReq}
            totalCount={totalCount}
            nextIndex={nextIndex}
            fileName={`Lost_Sales_Report_${new Date().getTime()}`}
            excelFormatColumnIndex={{hideColumnIndex: isQuarterView ? 2 : 0, diff: isQuarterView ? -1 : 0}}
          />
          <div className="mr-4" style={{ display: "flex" }}>
            <WeekFilter
              defaultValue={isQuarterView}
              showWeekFilter={!isQuarterView}
              quarters={quarters}
              // fiscalWeekOptions={fiscalWeekOptions}
              handleOnChange={handleOnChange}
              // latestWeek={latestWeek}
              lostSalesGraphData={props.lostSalesGraphData}
            />
          </div>
          {isShowTable &&
          <ReactTableWithPlugins
            getInstanceOnMount={(inst) => handleLostSalesMount(inst)}
            shouldPagination
            hideDropdown
            pageSize={pageSize}
            totalRecordsLen={lostSalesDetailsTableData.length}
            data={lostSalesTablesData}
            columns={lostSalesTableColumns}
            renderMarkup="TableMarkup"
            keyRT="sortAndSearch"
            tableId="lost_sales"
            fetchData={(data) => fetchData(data)}
            initialPageIndex={initialIndex}
            totalCount={totalCount}
            getManualSortByOptions={(instance) => {
              fecthDataWithSorting(instance);
            }}
            manualSortBy={true}
            sortBy={[{ id: isQuarterView ? "quarter" : "week", desc: true }]}
            initialHiddenColumns={initialHiddenColumns}
          />
          }
        </div>
      ) : isLostSalesAllDataFailed || isLostSalesDetailsDataFailed ? (
        <div className="error">{LOST_SALES_NETWORK_ERROR_MSG}</div>
      ) : (
        <div className="empty">
          {LOST_SALES_NO_RECORDS_MSG}
          <p>{LOST_SALES_NO_RECORDS_HELPER_MSG}</p>
        </div>
      )}
    </PageLoader>
  );
};

const mapStateToProps = ({ lostSalesReport }) => ({
  lostSalesDetailsTableData: lostSalesReport.lostSalesDetailsTableData,
  lostSalesUnitsValue: lostSalesReport.lostSalesUnitsValue,
  lostSalesRevenueValue: lostSalesReport.lostSalesRevenueValue,
  fiscalWeekOptions: lostSalesReport.fiscalWeekOptions,
  lostSalesRequestBody: lostSalesReport.lostSalesRequestBody,
  isLostSalesAllDataFailed: lostSalesReport.isLostSalesAllDataFailed,
  isLostSalesDetailsDataLoading: lostSalesReport.isLostSalesDetailsDataLoading,
  isLostSalesDetailsDataFailed: lostSalesReport.isLostSalesDetailsDataFailed,
  latestWeek: lostSalesReport.latestWeek,
  nextIndex: lostSalesReport.nextIndex,
  totalCount: lostSalesReport.totalCount,
  lostSalesGraphData: lostSalesReport.lostSalesGraphData,
  quarters: lostSalesReport.quarters,
  isLostSalesDetailsDataSuccess: lostSalesReport.isLostSalesDetailsDataSuccess,
  isLostSalesAllDataSuccess: lostSalesReport.isLostSalesAllDataSuccess
});

const mapDispatchToProps = (dispatch) => ({
  lostSalesActionsProp: bindActionCreators(lostSalesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LostSalesReportDetailsTable);
