export function twoDecimal(value) {
  return value ? value.toFixed(2) : value;
}

export function addDollar(value) {
  return value == "-" ? `${value}` : `$${value}`;
}

export function addPer(value) {
  return `${value}%`;
}

export function oneDecimal(value) {
  return value.toFixed(1);
}

export function zeroDecimal(value) {
  return value.toFixed();
}

export function numberFormatting(value) {
  var str = value.toString().split('.');
  if (str[0] && str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str?.join('.');
}

export const numberUSFormatting = (value) => value ? (isNaN(Number(value)) ? value : Number(value)?.toLocaleString('en-US')) : (value == 0 ? value : null)