import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import '../../ProductStoreStatus.css'
import {
    getStoreGroupMappingData, getProductProfiles, getStoreSizeContribution, getDcData,
    createConfig, saveSelectedStoreGroups, resetAll
} from "../Actions/CreateConfigAction"
import { saveSelectedFilters } from "../Actions/ProductStatusAction"
import { storeGroupActionForEdit } from '../../../StoreGroup/StoreGroupAction'

import ProductStatusFilters from "../ProductStatusFilters"
import StoreGroupMappingTable from "../Popups/StoreGroupMappingTable"
import PageLoader from '../../../../components/Loader/PageLoader';
import { PRODUCT_STORE_STATUS } from "../../../../constants/routeConstants";
import ProductProfileMapping from "./ProductProfileMapping"
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from '../../../UserManagement/ColumnFilter';
import * as Notify from '../../../../components/Notification/Notifications'
import Notification from '../../../../components/Notification/Notifications'
import EnterNameModal from "../Popups/EnterNameModal"
import CreateEditStoreGroup from '../../../StoreGroup/CreateEditStoreGroup/CreateEditStoreGroup';
import { CREATEEDIT_PROFILE_PAGE } from "../../../../constants/routeConstants";
import { cloneDeep } from "lodash";
import StepperComponent from '../../../../components/StepperComponent/StepperComponent';
import { isCoach } from '../../../../utils/commonUtilities';
import StyleMapping from '../Popups/StyleMapping';

const initialProceedCount = 0

let storeGroupSelectedRows = {}
let ppSelectedRows = {}
let initDcSelectedRows = {}

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const CreateConfig = (props) => {
    const history = useHistory();
    const [configuration_id, setConfigId] = useState(props?.location?.state?.filters?.configuration_id ? props?.location?.state?.filters?.configuration_id : null)
    const [selectedFilters, setFilters] = useState(props?.location?.state?.filters ? props?.location?.state?.filters : {})
    const [storeGroupData, setStoreGroupData] = useState([])
    const [ppData, setPPData] = useState([])
    const [dcData, setDCData] = useState([])

    const [storeGroupTableInstance, setStoreGroupTableInstance] = useState({})
    const [ppTableInstance, setPPTableInstance] = useState({})
    const [dcTableInstance, setDCTableInstance] = useState({})
    const [styleMappingInstance, setStyleInstance] = useState({})
    const [proceedCount, setProceedCount] = useState(initialProceedCount)
    const [dcSelectedRows, setDcSelectedRows] = useState({})
    const [showNameModal, setShowModal] = useState(false)
    const [isEditFlow, setIsEditFlow] = useState(false)
    const [auto_apply, setAutoApply] = useState(props?.location?.state?.filters?.auto_apply || false)
    const [showEditStoreGroup, setShowEditStoreGroup] = useState(false)
    const [existingStoreCode, setExistingStoreCode] = useState({})
    const [errorModalMessage, setErrorModalMessage] = useState("")
    const [isCreatePP, setIsCreatePP] = useState(false)
    const [sendSelectedRows, setSendSelectedRows] = useState(false)
    const [configName, setConfigName] = useState("")
    const dcColumns = [
        // {
        //     Header: "DC ID",
        //     accessor: "dcId",
        //     Filter: ColumnFilter
        // },
        {
            Header: "DC Name",
            accessor: "store_name",
            width: 250,
            Filter: ColumnFilter
        },
        // {
        //     Header: "City",
        //     accessor: "city",
        //     Filter: ColumnFilter
        // },
        // {
        //     Header: "State",
        //     accessor: "state",
        //     width: 250,
        //     Filter: ColumnFilter
        // },
        {
            Header: "Country",
            accessor: "country",
            width: 200,
            Filter: ColumnFilter
        }
    ]

    const getStoreGroupData = (filters) => {
        setSendSelectedRows(false)
        storeGroupSelectedRows = {}
        ppSelectedRows = {}
        initDcSelectedRows = {}
        setStoreGroupData([])
        setStoreGroupTableInstance({})
        let req = {}
        if (filters) {
            req = filters
            setFilters(filters)
        }
        else {
            req = { ...selectedFilters }
        }
        props.getStoreGroupMappingData(req)

    }

    const setRTinstance = (instance) => {
        setStoreGroupTableInstance(instance)
        storeGroupSelectedRows = {}
        instance?.getSelectedRows?.map(id => {
            storeGroupSelectedRows[id] = true
        })
    }

    const setPPRTInstance = (instance) => {
        setPPTableInstance(instance)
        ppSelectedRows = {}
        instance.getSelectedRows.map(id => {
            ppSelectedRows[id] = true
        })
    }

    const setDCRTInstance = (instance) => {
        setDCTableInstance(instance)
        initDcSelectedRows = {}
        instance?.getSelectedRows?.map(id => {
            initDcSelectedRows[id] = true
        })
        // initDcSelectedRows = selectedRows

    }

    const moveForward = (isSkip) => {
        if(isSkip) {
            ppSelectedRows = {}
            setPPTableInstance({})
        }
        if (
            ((proceedCount === 0 && Object.keys(storeGroupSelectedRows).length) ||
            (proceedCount === 1 && Object.keys(ppSelectedRows).length) ||
            (proceedCount === 2 && Object.keys(initDcSelectedRows).length)) || isSkip) {

            if (proceedCount === 1 && Object.keys(ppSelectedRows).length > 1) {
                Notify.error("Please Select Exactly One Product Profile!!")
                return;
            }

            let count = proceedCount + 1;
            if (count == 4) {
                if (isEditFlow) {
                    saveConfig()
                }
                else {
                    setShowModal(true)
                }
            }
            if (proceedCount < 3) {
                setProceedCount(count)
            }

            switch (count) {

                case 1:
                    saveSelectedStoreGroups()
                    props.getProductProfiles(selectedFilters)
                    break;
                case 2:
                    let req={
                        filters:selectedFilters
                    }
                    props.getDcData(req)
                    break;
            }
        }
        else {
            Notify.error("Please Select atleast One Item!!");
        }
    }

    const goBack = () => {
        setSendSelectedRows(true)
        let count = proceedCount - 1
        setProceedCount(count)

        if (count < 0) {
            history.push(`${PRODUCT_STORE_STATUS}`);
        }
    }

    const closeModal = () => {
        setShowModal(false)
        setErrorModalMessage("")
    }

    const saveConfig = (name) => {
        let req = {
            auto_apply,
            filters: selectedFilters,
            storeGroups: storeGroupTableInstance.selectedFlatRows,
            pp: ppTableInstance?.selectedFlatRows || [],
            dc: dcTableInstance.selectedFlatRows,
        }
        if (!isEditFlow) {
            req.user_consent = name === true ? true : false
            req.created_by = localStorage.getItem("email")
        }
        if ((name && name !== true) || configName) {
            req.name = (name && name !== true) ? name : configName
            if (name && name !== true) {
                setConfigName(name)
            }
        }
        props.createConfig(req)
    }

    useEffect(() => {
        let dcSelected = {}
        if (props.dcData?.dcs && !isEditFlow) {
            props?.dcData?.dcs.map((item, index) => {
                dcSelected[`${index}`] = true
            })
            initDcSelectedRows = dcSelected
        }
    }, [props.dcData])

    const makeStoreGroupData = () => {

        let data = []
        let remaining = []
        let selected = props.storeGroupData?.selected_groups?.length ? props.storeGroupData?.selected_groups : []
        props.storeGroupData?.store_groups?.map(store => {
            if (selected.includes(store.store_group_code)) {
                data.push(store)
            }
            else {
                remaining.push(store)
            }
        });
        let initialSelectedRows = storeGroupSelectedRows
        for (let i = 0; i < data.length; i++) {
            initialSelectedRows[i] = true
        }
        storeGroupSelectedRows = initialSelectedRows
        let allData = data.concat(remaining)

        setStoreGroupData(allData)
    }

    const makePPData = () => {
        let data = []
        let remaining = []
        let selected = props.productProfilesData?.selected_profiles?.length ? props.productProfilesData?.selected_profiles : []
        props.productProfilesData?.product_profiles?.map(pro => {
            if (selected.includes(pro.product_profile_code)) {
                let getSalesAttribute = val => {
                    let l_salesattribute = ""
                    if(Number(val?.clearance)){
                      l_salesattribute+='Clearance, '
                    }
                    if(Number(val?.regular)){
                      l_salesattribute+='Regular, '
                    }
                    if(Number(val?.promo)){
                      l_salesattribute+='Promo'
                    }
                    return l_salesattribute
                  }

                 data.push({
                     ...pro,
                     'sales_attribute':getSalesAttribute(pro)
                 })
                //   let responseWithSalesAttribute
                //      responseWithSalesAttribute = res.data.data.map(val => {
                //       return {
                //         ...val,
                //         'sales_attribute': getSalesAttribute(val),
                //       }
                //     })
            
            }
            else {
                let getSalesAttribute = val => {
                    let l_salesattribute = ""
                    if(Number(val?.clearance)){
                      l_salesattribute+='Clearance, '
                    }
                    if(Number(val?.regular)){
                      l_salesattribute+='Regular, '
                    }
                    if(Number(val?.promo)){
                      l_salesattribute+='Promo'
                    }
                    return l_salesattribute
                  }
                remaining.push({
                    ...pro,
                    'sales_attribute':getSalesAttribute(pro)
                })
            }
        });
        let initialSelectedRows = ppSelectedRows
        for (let i = 0; i < data.length; i++) {
            initialSelectedRows[i] = true
        }
        ppSelectedRows = initialSelectedRows
        let allData = data.concat(remaining)
        setPPData(allData)
    }

    const makedcData = () => {
        let data = []
        let remaining = []
        let selected = props.dcData?.selected_dcs?.length ? props.dcData?.selected_dcs : []
        props.dcData?.dcs?.map(dc => {
            if (selected.includes(Number(dc.store_code))) {
                data.push(dc)
            }
            else {
                remaining.push(dc)
            }
        });
        let initialSelectedRows = initDcSelectedRows
        for (let i = 0; i < data.length; i++) {
            initialSelectedRows[i] = true
        }
        initDcSelectedRows = initialSelectedRows
        let allData = data.concat(remaining)
        setDCData(allData)
    }

    useEffect(() => {
        if (isEditFlow) {
            makeStoreGroupData()
            makePPData()
            makedcData()
        }
        else {
            setStoreGroupData(props.storeGroupData?.store_groups && props.storeGroupData?.store_groups)

            setPPData(props.productProfilesData?.product_profiles && props.productProfilesData?.product_profiles)

            setDCData(props.dcData?.dcs && props.dcData?.dcs)
        }
    }, [props.storeGroupData, props.productProfilesData, props.dcData])


    useEffect(() => {
        return () => {
            storeGroupSelectedRows = {}
            ppSelectedRows = {}
            initDcSelectedRows = {}
            props.resetAll()
        }
    }, [])

    useEffect(() => {
        setErrorModalMessage("")
        setConfigName("")
        if (props.createConfigData?.configuration_id || props.createConfigDataSuccess) {
            props.saveSelectedFilters(selectedFilters)
            setShowModal(false)
            let text = isEditFlow ? "Updated" : "Created"
            if (props?.location?.state?.isArticlesBulkEdit) {
                Notify.success(`Updated Successfully!!`)
            }
            else {
                Notify.success(`Product Configuration ${text} Successfully!!`)
            }
            if (proceedCount == 3) {
                setTimeout(() => {
                    history.push(`${PRODUCT_STORE_STATUS}`)
                }, 3000)
            }
        }
        else if (props.createConfigDataError) {
            setErrorModalMessage(props.createConfigDataError)
        }

    }, [props.createConfigData, props.createConfigDataError,props.createConfigDataSuccess])

    useEffect(() => {
        if (props?.location?.state?.filters?.configuration_id || isCoach()) {
            setIsEditFlow(true)
            getStoreGroupData()
        }
        else if(props?.location?.state?.isCreateMissing) {
            getStoreGroupData()
        }
    }, [])

    const editStoreGroupModal = () => {
        if(storeGroupTableInstance?.expandedDepth !== 0) {
            Notify.error("Please Collapse the Expanded Row(s) to Edit Store Group!!")
        }
        else if (storeGroupTableInstance?.selectedFlatRows?.length != 1) {
            Notify.error("Select Exactly One Row to Edit Store Group!!")
        }
        else {
            let req = {
                storeGroupCode: storeGroupTableInstance?.selectedFlatRows[0]?.original?.store_group_code,
                storeCode: [],
                storeGroupName: "",
                description: "",
            }
            setExistingStoreCode(req)
            setShowEditStoreGroup(true)
            props.storeGroupActionForEdit({
                store_group_code: [req?.storeGroupCode],
            });
        }
    }

    const closeEditStoreGroupModal = (status) => {
        setShowEditStoreGroup(false)
        if (status == true) {
            Notify.success("Store Group Edited(Copied) Successfully!!")
            getStoreGroupData()
        }
    }

    const goToCreateProductProfile = () => {
        props.saveSelectedFilters(selectedFilters)
        history.push(`${CREATEEDIT_PROFILE_PAGE}`, { selectedFilters: selectedFilters });
    }

    const saveSelectedStoreGroups = () => {
        let instance = cloneDeep(storeGroupTableInstance)
        let obj = {
            selectedFlatRows: [],
            getSelectedRows: instance.getSelectedRows
        }
        instance.selectedFlatRows.map((item, index) => {
            obj.selectedFlatRows.push({ original: item.original })
        })
        props.saveSelectedStoreGroups(obj)

    }


    useEffect(() => {
        if (props.location?.state?.isPPCreated) {
            setRTinstance(props.selectedStoreGroups)
            setProceedCount(1)
            if (props.location?.state?.selectedFilters) {
                setFilters(props.location?.state?.selectedFilters)
                props.getProductProfiles(props.location?.state?.selectedFilters)
            }
        }
    }, [props.selectedStoreGroups])

    useEffect(() => {
        if (props.noArticleError) {
            if (window.confirm(props.noArticleError)) {
                saveConfig(true)
            }
        }
    }, [props.noArticleError])


    const storeGroupMapping =
        <div>
            <ProductStatusFilters applyFilters={getStoreGroupData} isCreateConfig={true} isArticlesBulkEdit={props?.location?.state?.isArticlesBulkEdit}
                setAutoApply={setAutoApply} selectedFilters={{ ...selectedFilters, auto_apply }} isDisabled={configuration_id ? true : isCoach() ? true :false} />
            <section className="section mr-4">
                <div className="container__header m-0">
                    <h3 className="fnt-bold">Store Group Mapping</h3>
                </div>
                <hr />
                {(props.storeGroupDataError) ? (<div className="error">{props.storeGroupDataError}</div>) :
                    <PageLoader loader={props.storeGroupDataLoading} gridLoader={true}>
                        <StoreGroupMappingTable data={storeGroupData}
                            initialSelectedRows={sendSelectedRows || isEditFlow && !props?.location?.state?.isArticlesBulkEdit ? storeGroupSelectedRows : {}}
                            setRTinstance={setRTinstance} />
                    </PageLoader>
                }
            </section>
        </div>

    const productProfile =
        <>
            {/* <div className="row config-btn-container mr-4 mb-2 create-pp-btn" onClick={goToCreateProductProfile}>
                <button className="btn-primary-rounded create-config-btn mr-2">
                    <i
                        className="fa fa-plus"
                        title="Create Allocation"
                        aria-hidden="true"
                    ></i>
                </button>
                <p className="fnt-sm m-0">Create Product Profile</p>
            </div> */}
            <ProductProfileMapping {...props} data={ppData} initialSelectedRows={!props?.location?.state?.isArticlesBulkEdit ? ppSelectedRows : {}}
                setRTinstance={setPPRTInstance} />
        </>

    const dcTable =
        <section className="section mr-4">
            <div className="container__header m-0">
                <h3 className="fnt-bold">Select DC Source</h3>
            </div>
            <hr />
            <PageLoader loader={props.dcDataLoading} gridLoader={true}>
                <ReactTableWithPlugins
                    shouldPagination
                    totalRecordsLen={dcData?.length}
                    data={dcData ? dcData : []}
                    columns={dcColumns}
                    initialSelectedRows={!props?.location?.state?.isArticlesBulkEdit ? initDcSelectedRows : {}}
                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    renderMarkup='TableMarkup'
                    keyRT="StrategyPageTable"
                    hideColumnsFilter
                    getInstanceOnMount={(instance) => {
                        setDCRTInstance(instance)
                    }}
                    tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                    // tableId="product_config_create"
                />
            </PageLoader>
        </section>


    const componentArr = [{
        label: "Store Group Mapping",
        component: storeGroupMapping,
        funcButtons: [{ name: "Edit Store Group", onClickHandler: editStoreGroupModal,isShow: true }]
    },
    {
        label: "Product Profile Mapping",
        component: productProfile,
        funcButtons: [{ name: "Skip", onClickHandler: () => moveForward(true),isShow: true }]
    },
    {
        label: "DC Source Mapping",
        component: dcTable,
        // nextButtonText: (isEditFlow ? "Update " : "Save ") + "Configuration",
        // hideNext: props?.location?.state?.isArticlesBulkEdit,
        // funcButtons: [{ name: "Save", onClickHandler: () => saveMapping(3), isShow: props?.location?.state?.isArticlesBulkEdit }]
    },
    {
        label: "Style Mapping",
        component: <StyleMapping filters={selectedFilters} selectedArticles = {props?.location?.state?.selectedArticles} setStyleInstance={setStyleInstance} />,
        nextButtonText: (isEditFlow ? "Update " : "Save ") + "Configuration",
        hideNext: props?.location?.state?.isArticlesBulkEdit,
        funcButtons: [{ name: "Save", onClickHandler: () => saveMapping(3), isShow: props?.location?.state?.isArticlesBulkEdit }]
    }]

    const saveMapping = (step) => {
        let req = {
            // configuration_id: selectedFilters?.configuration_id,
            storeGroups: storeGroupTableInstance.selectedFlatRows,
            pp: ppTableInstance.selectedFlatRows || [],
            dc: dcTableInstance.selectedFlatRows,
            article: props?.location?.state?.selectedArticles,
            related_article: styleMappingInstance?.selectedFlatRows[0]?.original?.article,
            isArticleEdit: true,
            filters:{...selectedFilters}
        }
        if (step === 1) {
            delete req?.pp
            delete req?.dc
        }
        else if (step === 2) {
            delete req?.dc
        }
        props.createConfig(req)
    }

    return (
        <>
            {/* <Notification /> */}
            <PageLoader loader={(props?.location?.state?.isArticlesBulkEdit && props.createConfigDataLoading)} gridLoader={true}>
            <StepperComponent activeStep={proceedCount} steps={componentArr}
                heading={`Configuration/${isEditFlow ? "Edit" : "Create"} Product Configuration`}
                goBack={goBack} moveForward={() => moveForward()} />
             </PageLoader>

            {
                showNameModal &&
                <EnterNameModal closeModal={closeModal} showModal={showNameModal}
                    loading={props.createConfigDataLoading}
                    saveConfig={saveConfig} errorModalMessage={errorModalMessage} />
            }
            {
                showEditStoreGroup &&
                <CreateEditStoreGroup
                    showAlert={true}
                    closeAlert={closeEditStoreGroupModal}
                    existingStoreCode={existingStoreCode}
                    isCreateStoreGroup={false}
                    isCreateConfigFlow={true}
                />
            }
        </>
    )
}

const mapStateToProps = ({ createConfig }) => ({
    storeGroupData: createConfig.storeGroupData,
    storeGroupDataLoading: createConfig.storeGroupDataLoading,
    storeGroupDataError: createConfig.storeGroupDataError,
    productProfilesData: createConfig.productProfilesData,
    productProfilesDataLoading: createConfig.productProfilesDataLoading,
    productProfilesDataError: createConfig.productProfilesDataError,
    storeSizeContributionData: createConfig.storeSizeContributionData,
    storeSizeContributionDataLoading: createConfig.storeSizeContributionDataLoading,
    storeSizeContributionDataError: createConfig.storeSizeContributionDataError,
    dcData: createConfig.dcData,
    dcDataLoading: createConfig.dcDataLoading,
    dcDataError: createConfig.dcDataError,
    createConfigData: createConfig.createConfigData,
    createConfigDataSuccess: createConfig.createConfigDataSuccess,
    createConfigDataLoading: createConfig.createConfigDataLoading,
    createConfigDataError: createConfig.createConfigDataError,
    selectedStoreGroups: createConfig.selectedStoreData,
    noArticleError: createConfig.noArticleError
})

const mapDispatchToProps = (dispatch) => ({
    getStoreGroupMappingData: (payload) => dispatch(getStoreGroupMappingData(payload)),
    getProductProfiles: (payload) => dispatch(getProductProfiles(payload)),
    getStoreSizeContribution: (payload) => dispatch(getStoreSizeContribution(payload)),
    getDcData: (payload) => dispatch(getDcData(payload)),
    createConfig: (payload) => dispatch(createConfig(payload)),
    saveSelectedFilters: (payload) => dispatch(saveSelectedFilters(payload)),
    storeGroupActionForEdit: (payload) => dispatch(storeGroupActionForEdit(payload)),
    saveSelectedStoreGroups: (payload) => dispatch(saveSelectedStoreGroups(payload)),
    resetAll: () => dispatch(resetAll())
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateConfig)