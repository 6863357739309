import React, { useCallback, useRef, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import "./TableStyle.css";
import ExportFile from "../../components/ExportFile/ExportFile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { debounce, filter, forEach, isEmpty, map } from "lodash";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import * as adaVisualActions from "./actions/AdaVisualActions";
import {
  EMPTY_DATA_HELPER_MSG,
  EMPTY_STORE_MSG,
  ITEM_PER_PAGE_STORES,
} from "./AdaVisualConstants";
import { sliceObject } from "../../utils/commonUtilities";

const StoreView = (props) => {
  const {
    article,
    k,
    tableHeader,
    storeHierarchyData,
    currentWeek,
    handleHierarchyChange,
    isStoreHierarchyLoading,
    sortData
  } = props;
  const [filterValue, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  let storeMapping = {};
  // This is done to manage the missing week data and show "-" for that.
  forEach(storeHierarchyData?.[article], (storeData, storeId) => {
    forEach(storeData, (item, index) => {
      forEach(tableHeader, (week) => {
        if (storeMapping[storeId]) {
          storeMapping[storeId][week] = {
            adjusted_forecast: "-",
            store: storeId,
            article: article,
            fw: week,
          };
        } else {
          storeMapping[storeId] = {
            [week]: {
              adjusted_forecast: "-",
              store: storeId,
              article: article,
              fw: week,
            },
          };
        }
      });
    });
  });

  forEach(storeHierarchyData?.[article], (storeData, storeId) => {
    forEach(storeData, (item) => {
      storeMapping[storeId][item.fw] = item;
    });
  });

  const debouncedHandleChange = useCallback(
    debounce((data) => {
      setFilter(data);
    }, 500),
    []
  );

  const handleChange = (e) => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => {
    if (filterValue == "") {
      setFilteredData({});
      setCurrentPage(0);
    } else {
      const searchedData = filter(storeMapping, (e, storeId) => {
        return storeId == filterValue // Exact match of the store will be filtered.
      });
      setFilteredData(searchedData);
    }
  }, [filterValue]);

  const getStore = (item) => {
    let store;
    if (item) {
      store = new Set(map(item, (e) => e.store));
      store = [...store];
    }
    return store?.[0] ? store?.[0] : store?.[1];
  };

  const getStoreName = (item) => {
    let store;
    if (item) {
      store = new Set(map(item, (e) => e.store_name));
      store = [...store];
    }
    return store?.[0] ? store?.[0] : store?.[1];
  };

  // calculate the total number of pages
  const totalPages = Math.ceil(
    Object.values(!isEmpty(filteredData) ? filteredData : storeMapping).length /
      ITEM_PER_PAGE_STORES
  );

  // define a function to handle page change events
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // slice the list of items based on the current page and number of items per page
  const itemsToShow = sliceObject(
    !isEmpty(filteredData) ? filteredData : storeMapping,
    !isEmpty(filteredData) ? 0 : currentPage * ITEM_PER_PAGE_STORES,
    !isEmpty(filteredData) ? ITEM_PER_PAGE_STORES : (currentPage + 1) * ITEM_PER_PAGE_STORES
  );

  return (
    // For Stable loading screen added hidden class while data is loading.
    <>
      <td
        className={isStoreHierarchyLoading ? "hidden" : ""}
        colspan={tableHeader.length + 3}
      >
        <div className="table-container">
          <table className="sub-table">
            <tbody>
              <tr className={isStoreHierarchyLoading ? "hidden" : ""}>
                <td className="td-set sticky-col" />
                <td
                  className={
                    filterValue && isEmpty(filteredData)
                      ? ""
                      : "header-back-color"
                  }
                  colspan={tableHeader.length + 2}
                >
                  <th className="th-set sticky-col2">
                    Store
                    <input
                      className="table-input-field"
                      onChange={(e) => handleChange(e)}
                      placeholder={"Search by Store..."}
                    />
                  </th>
                  <th className="th-set sticky-col3" key={3}>
                    Store Name
                  </th>

                  {filterValue && isEmpty(filteredData) ? (
                    <div className="empty">
                      {EMPTY_STORE_MSG}
                      <p>{EMPTY_DATA_HELPER_MSG}</p>
                    </div>
                  ) : (
                    map(itemsToShow, (storeData, storeKey) => (
                      <tr
                        className="attribute"
                        key={
                          !isEmpty(filteredData)
                            ? getStore(filteredData[storeKey])
                            : storeKey
                        }
                      >
                        <td className="td-set sticky-col2">
                          {!isEmpty(filteredData)
                            ? getStore(filteredData[storeKey])
                            : storeKey}
                        </td>
                        <td className="td-set sticky-col3">
                          {getStoreName(
                            !isEmpty(filteredData)
                              ? filteredData[storeKey]
                              : storeMapping[storeKey]
                          )}
                        </td>
                        {map(sortData(Object.values(storeData), tableHeader), (item) => {
                          return item.fy_fw < currentWeek ||
                            item.adjusted_forecast == "-" ? (
                            <td
                              className="td-set data_disabled"
                              key={`${item.store}-${item.fw}`}
                            >
                              <InputNumber
                                value={
                                  item?.adjusted_forecast !== "-"
                                    ? item["adjusted_forecast"].toFixed(2)
                                    : item["adjusted_forecast"]
                                }
                                key={`${item.store}-${item.fw}`}
                                id={`${item.article}-${item.store}`}
                                name={`${item.fy_fm}-${item.fy_fw}`}
                                className="span-data"
                                disabled
                              />
                            </td>
                          ) : (
                            <td
                              className="td-set data_input"
                              key={`${item.store}-${item.fw}`}
                            >
                              <InputNumber
                                min={0}
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                value={
                                  item?.adjusted_forecast !== "-"
                                    ? item["adjusted_forecast"].toFixed(2)
                                    : item["adjusted_forecast"]
                                }
                                key={`${item.store}-${item.fw}`}
                                name={`${item.fy_fm}-${item.fy_fw}`}
                                id={`${item.article}-${item.store}`}
                                onChange={(e) => handleHierarchyChange(e, item.article, item.store)}
                                className="edit-span"
                              />
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
      {totalPages > 1 && (
        <tr>
          <td colSpan={4} className="sticky-col">
            <ReactPaginate
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </td>
        </tr>
      )}
    </>
  );
};

const mapStateToProps = ({ adaVisual }) => ({
  storeHierarchyData: adaVisual.storeHierarchyData,
  isStoreHierarchyLoading: adaVisual.isStoreHierarchyLoading,
});

const mapDispatchToProps = (dispatch) => ({
  adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
  getAdaStoreHierarchyData: (payload) =>
    dispatch(adaVisualActions.getAdaStoreHierarchyData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreView);
