import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PageLoader from '../../../components/Loader/PageLoader';
import ConstraintsModal from '../../../components/Modal/ConstraintsModal';
import Collapse from '../../../components/svg/Collapsesvg';
import Expand from '../../../components/svg/Expandsvg';
import InputCell from '../../../components/Table/Cellrenderer/InputCell';
import ReactTable from '../../../components/Table/ReactTable';
import WithDynamicPlugins from '../../../components/Table/WithPlugins';
import ColumnFilter from '../../UserManagement/ColumnFilter';
import { fetchPopUpData, updateTableData } from '../ConstraintsAction';
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import { numberUSFormatting } from '../../../utils/formatters/valueFormatters';
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { ERROR_MSG } from '../../../constants/globalConstant';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const pageSize = 20

function StoreGrade(props) {

    const initialState = {
        storeName : "",
        department: "",
        gender: "",
        rbu: "",
        style: "",
        colour: "",
        size: ""
      }
    const [RTinstance, setRTinstance] = useState(null)
    const [showStoreModal, setShowStoreModal] = useState(false)
    const [storeDetails, setStoreDetails] = useState(initialState)
    const [filters, setFilters]= useState(props.filters)
    const [isButtonClicked, setIsButtonClicked]= useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [shouldLoading, setShouldLoading] = useState(false)
    const [loaderMessage, setLoaderMessage] = useState(null)
    const [prevIndex,setPrevIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(null)
    const [nextIndex, setIndex] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    // const [isDataLoading, setIsDataLoading] = useState(false)
    const [initialIndex, setInitialIndex] = useState(0)
    // const [initialSelectedRow, setInitialSelectedRow] = useState({ "1.1": true})
    const [dcColumns, setDCColumns] = useState([])
    const [hideTotalRecords, setHideTotalRecords] = useState(true)
    const [hideTotalPages, setHideTotalPages] = useState(true)

    useEffect(() => {
      if(props.out_of_data) {
        setHideTotalPages(false);
        setHideTotalRecords(false);
      }
      else{
        setHideTotalPages(true);
        setHideTotalRecords(true);
      }
    },[props.out_of_data])

    const closeConstraintsModal = () => {
        setShowStoreModal(false);
     };
    
    const storeModal = (p_bool,p_data) => {
      setIsButtonClicked(false)
      setShowStoreModal(p_bool)
      setStoreDetails({
        ...storeDetails,
        storeName : `${p_data?.store_grade}_Stores`,
        department: p_data?.l1_name,
        gender: p_data?.l2_name,
        rbu: p_data?.l3_name,
        dcs: p_data?.l4_name,
        style: p_data?.style_name,
        colour: p_data?.color,
        size: p_data?.size_desc
      })
      let req ={
        screen_type: "store_grade",
        filters: filters,
        rowData: {...p_data,'store_grade':[p_data?.store_grade]}
      }
      props.fetchPopUpData(req)
    }

    const getExpandedObject = (p_length) => {
      let expanded = {}
      for (let i = 0; i < p_length; i++){
        expanded[i] = true
      }
      return expanded;
    }

    const memorizedExpanded = React.useMemo(() => getExpandedObject(props?.storeGradeData?.length),[props?.storeGradeData?.length])


    const columns = React.useMemo(
        () => [
          {
            // Build our expander column
            id: 'expander', // Make sure it has an ID
            Cell: ({ row }) =>
              // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
              // to build the toggle for expanding a row
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps()}
                >
                  {row.isExpanded ? <Expand/>:<Collapse/>}
                </span>
              ) : <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
              width:70,
              // disableFilters: true
          },
          {
            Header:  `${updateArticleName(false)} ID`,
            accessor: 'article',
            Cell: ({ value,row }) =>
            (row.canExpand || row.depth === 0) ? (
             <span>{value}</span>
            ) :   <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
          },
          {
            Header: "Style ID",
            accessor: "style_code",
            Cell: ({ value,row }) =>
            (row.canExpand || row.depth === 0) ? (
             <span>{value}</span>
            ) :   <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
          },
          {
            Header: "Color ID",
            accessor: "color_code",
            Cell: ({ value,row }) =>
            (row.canExpand || row.depth === 0) ? (
             <span>{value}</span>
            ) :   <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
          },
          {
            Header: "Assortment Indicator",
            accessor: "assortment_indicator",
            width: 250,
            // sticky: "left"
          },
              {
                Header: levelFiltersLabelFormatter("level1"),
                accessor: 'l1_name',
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // disableFilters: true
              },
              {
                Header: props?.filterLabels.level2 || levelFiltersLabelFormatter("level2"),
                accessor: 'l2_name',
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // disableFilters: true
              },
              {
                Header: props?.filterLabels.level3 || levelFiltersLabelFormatter("level3"),
                accessor: 'l3_name',
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // disableFilters: true
              },
              {
                Header: props?.filterLabels.level4 || 'DCS',
                accessor: 'l4_name',
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :   <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // disableFilters: true
              },
              {
                Header: props?.filterLabels.level5 || levelFiltersLabelFormatter("level5"),
                accessor: 'l5_name',
                width: 200,
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // disableFilters: true
              },
              {
                Header: props?.filterLabels?.level6 || levelFiltersLabelFormatter("level6"),
                accessor: 'l6_name',
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // disableFilters: true
              },
              {
                Header: 'Style Description',
                accessor: 'style_name',
                width: 330,
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
              },
              {
                Header: 'Global Color',
                accessor: 'color',
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
              },
            {
              Header: 'Style Color',
              accessor: 'color_desc',
              width: 200,
              Cell: ({ value,row }) =>
              (row.canExpand || row.depth === 0) ? (
               <span>{value}</span>
              ) :   <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
              // Filter: (instance) => (
              //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              // ),
            },
            {
              Header: 'Width',
              accessor: 'width',
              Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
            },
              {
                Header: 'Size',
                accessor: 'size_desc',
                Cell: ({ value,row }) =>
                (row.canExpand || row.depth === 0) ? (
                 <span>{value}</span>
                ) :  <span style={{backgroundColor:"rgb(224,224,224)", minHeight:"100%", minWidth:"100%"}}> </span>,
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
              },
              {
                Header: 'Store Grade',
                accessor: 'store_grade',
                Cell: (instance) => (
                  <div>
                    <div className="clickable" onClick= {() => storeModal(true,instance.row?.original)}>{instance.row?.original?.store_grade}</div>
                  </div> 
                ),
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
              },
              {
                Header: 'Aggre.Min',
                accessor: 'min_store_sum',
                Cell: (inst) => numberUSFormatting(inst?.value)
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
              },
              {
                Header: 'Aggre.Max',
                accessor: 'max_store_sum',
                Cell: (inst) => numberUSFormatting(inst?.value)
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
                // filter: 'filterRowsWithSubrows',
              },
              {
                Header: 'Avg. Min per store',
                accessor: 'min_store',
                Cell:(instance) => <InputCell blurHandler={(rowIdx, columnId, values, row_id, temp_id, row, focusedValue) => setIsDisabled((pre) => focusedValue == values && pre)}
                shouldOnBlur roundOffOnChange min={0} max={Number(instance.row?.original?.max_store) } step={1} type = "number" {...instance}/>,
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
                disableSortBy: true,
              },
              {
                Header: 'Avg. Max.per store',
                accessor: 'max_store',
                Cell:(instance) => <InputCell blurHandler={(rowIdx, columnId, values, row_id, temp_id, row, focusedValue) => setIsDisabled((pre) => focusedValue == values && pre)}
                shouldOnBlur roundOffOnChange min={Number(instance.row?.original?.min_store) } step={1} type = "number" {...instance}/>,
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
                // filter: 'filterRowsWithSubrows',
                disableSortBy: true,
              },
              {
                Header: 'WOS',
                id: "wos",
                // accessor: 'wos_sum',
                accessor:(data) =>  data["wos"].toFixed(1),
                // Filter: (instance) => (
                //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
                // ),
              },
              // {
              //   Header: 'Transit Time',
              //   // accessor: 'transit_time_sum',
              //   accessor:(data) =>  data["transit_time_sum"].toFixed(1),
              //   // Filter: (instance) => (
              //   //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              //   // ),
              // },
              {
                Header: "Transit Time",
                columns: dcColumns
              //   columns: [
              // {
              //   Header: 'PUMA Retail Stores WH10',
              //   accessor: 'dc_store_1',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //   // Filter: (instance) => (
              //   //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              //   // ),
              //   // filter: 'filterRowsWithSubrows',
              //   disableSortBy: true,
              // },
              // {
              //   Header: 'U.S. Continental',
              //   accessor: 'dc_store_2',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //   // Filter: (instance) => (
              //   //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              //   // ),
              //   // filter: 'filterRowsWithSubrows',
              //   disableSortBy: true,
              // },
              // {
              //   Header: 'Retail Bulk',
              //   accessor: 'dc_store_3',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //   // Filter: (instance) => (
              //   //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              //   // ),
              //   // filter: 'filterRowsWithSubrows',
              //   disableSortBy: true,
              // },
              // {
              //   Header: 'Retail Bulk WH20',
              //   accessor: 'dc_store_4',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //   // Filter: (instance) => (
              //   //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              //   // ),
              //   // filter: 'filterRowsWithSubrows',
              //   disableSortBy: true,
              // },
              // {
              //   Header: 'United Legwear WHS',
              //   accessor: 'dc_store_5',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //   // Filter: (instance) => (
              //   //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              //   // ),
              //   // filter: 'filterRowsWithSubrows',
              //   disableSortBy: true,
              // },
              // {
              //   Header: 'Infinity',
              //   accessor: 'dc_store_6',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //   // Filter: (instance) => (
              //   //   <ColumnFilter  {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index)}}/>
              //   // ),
              //   // filter: 'filterRowsWithSubrows',
              //   disableSortBy: true,
              // }]
            },
        ],
        [dcColumns]
      )

      useEffect(() => {
        if(props.storeGradeData) {
          // const {dcs} = props.storeGradeData[0]
          let dcColumnNames = []
          props?.storeGradeData[0]?.dcs?.forEach(dcName => {
            dcColumnNames.push({
              Header: dcName,
              accessor: dcName,
              disableSortBy: true,
            })
          })
          setDCColumns(dcColumnNames)
        }
      }, [props.storeGradeData])
      
      // useEffect(() => {
      //   if(props.isUpdated && isButtonClicked){
      //     setShouldLoading(false)
      //       props.callAllApis(filters)
      //   }
      // }, [props.isUpdated])

      useEffect(() => {
        if(props.isUpdated && isButtonClicked){
          setShouldLoading(false)
          setLoaderMessage(null)
            // props.callAllApis(filters)
            // props.fetchStoreGradeData({filters:props.filters,"rowCount":props.rowsCount,"rowIndex":props.initialindex})
            // props.fetchMinPerStore(filters)
        }
      }, [props.isUpdated])

      const applyConstraints = () => {
        setIsDisabled(true);
        let data = RTinstance?.obje
        for (let i = 0 ;i< data.length; i++) {
          if(Number(data[i].min_store || 0) > Number(data[i].max_store|| 0)) {
            Notify.error("Make sure min is less than or equal to max !!")
            return;  
          }
        }
        setIsButtonClicked(true)
        setShouldLoading(true)
        setLoaderMessage('Applying your edits')
        let req = {
          data: RTinstance?.obje,
          "screen_type":"store_grade",
        }
        setInitialIndex(0)
        setPrevIndex(0)
        props.setOtherViewsEmpty('storeGrade')
        props.updateTableData(req)
        RTinstance.setObject([])
      }

      const callBackSetOtherViewsEmpty = (p_view) => {
        props.setOtherViewsEmpty(p_view,true)
      } 


    const fetchData = (index) => {
      if(prevIndex >= index )
      return;
        // setIsDataLoading(true)
        setPrevIndex(index)
        setInitialIndex(Math.floor(pageIndex/pageSize))
        props.fetchStoreGradeData({filters:props.filters,"rowCount":props.rowsCount,"rowIndex":nextIndex,"styleIndex":props.styleIndex})
    }

    useEffect(() => {
      setTotalCount(props.storeGradeTotalCount)
      setIndex(props.storeGradeIndex)
      setPageIndex(props.pageIndex)
    },[ props.storeGradeTotalCount,props.storeGradeIndex,props.pageIndex])

    return (
    <>
    <div>
    {/* <Notification /> */}

        <PageLoader loaderMessage={loaderMessage} loader={props.loading || shouldLoading} gridLoader={true}>
        {(props.storeGradeError) ?  (<div className="error">{ERROR_MSG}</div>) :
             <ReactTableWithPlugins
                //  totalCount={totalCount}
                 totalRecordsLen={props?.storeGradeData?.length}
                 shouldPagination
                 data={props?.storeGradeData}
                 columns={columns}
                 hideDropdown
                 renderMarkup='TableMarkup'
                 tableWrapperStyle={{height:'fit-content', maxHeight:'50rem'}}
                 headerWrapperStyle={{position:'sticky', top:0}}
                //  keyRT="constraintsPageTable"
                //  features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                //  keyRT="sortAndSearch"
                 expanded={memorizedExpanded}
                //  initialSelectedRows={initialSelectedRow}
                 containSubRow={true}
                 pageSize={pageSize}
                 prepareRequest={true}
                //  setIsDisabled={setIsDisabled}
                 getInstanceOnMount={(instance) => {
                   setRTinstance(instance)
                   props.setRTinstancecallbackStoreGrade(instance)
                 }}
                 fetchData={(data) => fetchData(data)}
                //  loadingRT={isDataLoading}
                 initialPageIndex={initialIndex}
                 tableId="constraint_store_grade"
                 hideTotalRecords = {hideTotalRecords}
                 hideTotalPages = {hideTotalPages}
                 />}
        </PageLoader>
        {showStoreModal ? (
            <ConstraintsModal
              showAlert={true}
              closeAlert={closeConstraintsModal}
              storeDetails={storeDetails}
              // callAllApis={props.callAllApis}
              callBackSetOtherViewsEmpty={callBackSetOtherViewsEmpty}
              view="storeGrade"
              filters={filters}
            />
          ) : null}
    </div>
    <div className="constraints__apply__button">
    <button disabled={isDisabled} onClick={applyConstraints} class="btn btn-primary center" title="Apply All filters">Apply All</button>
    </div>
    </>
    )
}

const mapStateToProps = ({ constraints }) => ({
  filterLabels: constraints.filterlabels,
  isUpdated: constraints.isUpdated,
  storeGradeError: constraints.storeGradeError,
  loading: constraints?.storeGradeloading,
  storeGradeTotalCount: constraints?.storeGradeTotalCount,
  storeGradeIndex: constraints?.storeGradeIndex,
  styleIndex: constraints?.styleIndex,
  pageIndex: constraints?.pageIndex,
  out_of_data: constraints?.out_of_data
});

const mapDispatchToProps = (dispatch) => ({
  fetchPopUpData: (payload) => dispatch(fetchPopUpData(payload)),
  updateTableData: (payload) => dispatch(updateTableData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreGrade)
