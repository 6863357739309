import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import { ARTICLE_TABLE_HEADER } from "../../NewStoreApproveConstants";
import EditIconCell from '../../../../components/Table/Cellrenderer/EditIconCell';
import EditModal from "./EditModal";
import * as Notify from "../../../../components/Notification/Notifications";
import EnterNameModal from "../../../ProductStoreStatus/ProductStatus/Popups/EnterNameModal";
import { cloneDeep } from "lodash";
import { PRODUCT_STORE_STATUS } from "../../../../constants/routeConstants";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const ArticleListTable = (props) => {
    let history = useHistory();

    const { data, getPackDetails, selectedStore, approveNewStoreArticles, type, loading, approveNewStoreArticlesSuccess,
        page, getNewStoreArticleDetails, errorMessage, setErrorMessage, getNewStoreApproveList } = props

    const [showModal, setShowModal] = useState(false)
    const [selectedDetails, setSelectedDetails] = useState({})
    const [tableInstance, setTableInstance] = useState({})
    const [tableColumns, setTableColumns] = useState([])
    const [showNameModal, setShowNameModal] = useState(false)
    const [articleData, setArticleData] = useState([])
    const [disableApproveButton, setDisableApproveButton] = useState()
    const [alreadyApprovedLength, setAlreadyApprovedLength] = useState(0)

    const getColumnBehaviour = (isApproved, value) => {
        return <div className="row min-100">
            <span className={"min-100 pt-2 " + (isApproved ? "sister-store__map" : "")}>
                {value}
            </span>
        </div>
    }

    const columns = [
        {
            // sticky: "left",
            Header: ARTICLE_TABLE_HEADER.STYLE_ID,
            accessor: 'article',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
            filter: 'bulkFilterCommaSeperated',
            width: 200
        },
        {
            Header: ARTICLE_TABLE_HEADER.DESC,
            accessor: 'style_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: ARTICLE_TABLE_HEADER.STYLE_COLOR,
            accessor: 'color_desc',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: 'l1_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: 'l2_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: 'l3_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: 'l4_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: 'l5_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: levelFiltersLabelFormatter("level6"),
            accessor: 'l6_name',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: ARTICLE_TABLE_HEADER.RESERVED_UNITS,
            accessor: 'demand_estimated',
            Cell: (instance) => getColumnBehaviour(instance?.row?.original?.approved, instance.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: "Actions",
            accessor: "actions",
            sticky: "right",
            Cell: (instance) => (
                <div className={instance?.row?.original?.approved ? "disable__column" : ""}>
                    <EditIconCell
                        handleEdit={editUnits}
                        instance={instance}
                    ></EditIconCell>
                </div>
            ),
            width: 100,
            resizable: true,
            disableFilters: true,

        },
    ]

    const additionalReleaseColumns = [
        {
            Header: ARTICLE_TABLE_HEADER.PO,
            accessor: 'po_id',
            Cell: (instance) => getColumnBehaviour(false, instance.value),
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: ARTICLE_TABLE_HEADER.ASN,
            accessor: 'asn_id',
            Cell: (instance) => getColumnBehaviour(false, instance.value),
            Filter: ColumnFilter,
        },
        {
            Header: ARTICLE_TABLE_HEADER.INVENTORY,
            accessor: 'inventory_source',
            Cell: (instance) => getColumnBehaviour(false, instance.value),
            Filter: ColumnFilter,
        },
        {
            Header: ARTICLE_TABLE_HEADER.ORIGINAL_RESERVED,
            accessor: 'original_reserved',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        // {
        //     Header: ARTICLE_TABLE_HEADER.PACK,
        //     accessor: 'pack_id',
        //     Cell: (instance) => getColumnBehaviour(false, instance.value),
        //     Filter: ColumnFilter,
        // },
        {
            Header: ARTICLE_TABLE_HEADER.ALLOCATED_UNITS,
            columns: [
                {
                    Header: ARTICLE_TABLE_HEADER.PACKS_COUNT,
                    accessor: 'packs_allocated',
                    Cell: (instance) =>
                        <div>
                            <div className="clickable" onClick={() => { editUnits(instance?.row?.original, true, "packs") }}>
                                {instance?.value}
                            </div>
                        </div>,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between'
                },
                {
                    Header: ARTICLE_TABLE_HEADER.EACHES_COUNT,
                    accessor: 'eaches_allocated_total',
                    Cell: (instance) =>
                        <div>
                            <div className="clickable" onClick={() => { editUnits(instance?.row?.original, true, "eaches") }}>
                                {instance?.value}
                            </div>
                        </div>,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between'
                },
                {
                    Header: ARTICLE_TABLE_HEADER.TOTAL,
                    accessor: 'total_units',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between'
                }
            ]
        }
    ]

    const editUnits = (inst, isRelease, type) => {
        let obj = {
            "packs": { units: "pack_sizes_allocated", size: "pack_sizes", total: "packs_allocated_total" },
            "eaches": { units: "eaches_allocated", size: "each_sizes", total: "total_units" }
        }
        setSelectedDetails({ store: selectedStore?.store_code || inst?.store_code, article: inst?.article })
        setShowModal(true)
        let req = {
            "article": inst?.article,
            "store_code": selectedStore?.store_code,
            "isNewStoreSetup": true
        }
        if (isRelease) {
            getPackDetails({ sizeData: { data: [{ ...inst?.[obj[type]["units"]], pack_id: "eaches", allocated_units: inst?.[obj[type]["total"]] }], pack_sizes: inst?.[obj[type]["size"]] }, "isNewStoreSetup": true, isRelease })
        }
        else {
            getPackDetails(req);
        }
    }

    const closeEditModal = () => {
        setShowModal(false)
    }

    const onSave = () => {
        if (tableInstance?.selectedFlatRows?.length) {
            if (type === "new_store_release") {
                setShowNameModal(true)
            }
            else {
                let articles = tableInstance?.selectedFlatRows?.filter(row => !row?.original?.approved).map(item => item?.original?.article)
                approveNewStoreArticles({ store_code: selectedStore?.store_code, articles })
            }
        }
        else {
            Notify.error("Please Select atleast one row!!")
        }
    }

    const releaseArticles = (name) => {
        let req = {
            name,
            "user_name": localStorage?.getItem("email"),
            isRelease: true,
            store_code: selectedStore?.store_code,
            articles: tableInstance?.selectedFlatRows?.map(row => row?.original?.article)
        }
        approveNewStoreArticles(req)
    }

    useEffect(() => {
        if (type === "new_store_release") {
            setTableColumns([...columns.slice(0, columns.length - 2), ...additionalReleaseColumns])
        }
        else {
            setTableColumns(columns)
        }
    }, [])

    useEffect(() => {
        if (approveNewStoreArticlesSuccess) {
            setShowNameModal(false)
        }
    }, [approveNewStoreArticlesSuccess])

    useEffect(() => {
        if(data?.data) {
            let articles = cloneDeep(data?.data)
            let approvedLength = 0;
            articles.forEach(row => {
                if(row.approved) {
                    approvedLength++;
                }
                row.showCheckbox = row.approved
                // row.checkColor = row.approved
            })
            setAlreadyApprovedLength(approvedLength)
            setArticleData(articles)
        }
    }, [data])

    const setArticleTableInstance = (instance) => {
        setTableInstance(instance)
        if(alreadyApprovedLength === articleData.length) {
            setDisableApproveButton(true)
        }
        else {
            setDisableApproveButton(!instance?.getSelectedRows.length)
        }
    }

    const closeModal = () => {
        setShowNameModal(false)
        setErrorMessage(false)
    }

    return (
        <section className="section m-0">
            <ReactTableWithPlugins
                shouldPagination
                // embededScroll
                data={(articleData ? articleData : [])}
                columns={tableColumns}
                renderMarkup='TableMarkup'
                keyRT={page !== "view_allocation" ? "StrategyPageTable" : "sortAndSearch"}
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                tableId="new_store_approve_article"
                getInstanceOnMount={(instance) => { setArticleTableInstance(instance) }}
                tableWrapperStyle={{
                    height: "fit-content",
                    maxHeight: "50rem",
                }}
                headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
            />
            {
                showModal &&
                <EditModal
                    getNewStoreApproveList={getNewStoreApproveList}
                    showAlert={true}
                    closeAlert={closeEditModal}
                    data={selectedDetails}
                    isRelease={type === "new_store_release"}
                    getNewStoreArticleDetails={getNewStoreArticleDetails}
                    dcMap={data?.dcMap}
                />
            }
            {
                type === "new_store_approval" &&
                <div className="mt-3 d-flex align-items-center">
                    <div className="indicate-div sister-store__map"></div>
                    <span className="ml-2 font-italic mb-0">Already Approved</span>
                </div>
            }
            {
                page !== "view_allocation" &&
                <div className="row justify-content-center">
                    <button
                        className="btn btn-primary mr-4"
                        onClick={() => history.push(`${PRODUCT_STORE_STATUS}`, { isNewStore: true })}
                    >
                        Back
                    </button>

                    <button
                        disabled={disableApproveButton}
                        className="btn btn-primary mr-4"
                        onClick={onSave}
                    >
                        {type === "new_store_approval" ? "Approve" : "Finalize"}
                    </button>

                </div>
            }
            {
                showNameModal &&
                <EnterNameModal closeModal={closeModal} showModal={showNameModal} loading={loading}
                    saveConfig={releaseArticles} heading={"Plan Name"} width={"30%"} inputWidth={"w-75"} errorModalMessage={errorMessage} />
            }
        </section>
    )
}

export default ArticleListTable;