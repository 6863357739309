import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { connect } from 'react-redux'
import Card from '../../../components/Card/Card'
import ExportFile from '../../../components/ExportFile/ExportFile'
import PageLoader from '../../../components/Loader/PageLoader'
import ReactTable from '../../../components/Table/ReactTable'
import WithDynamicPlugins from '../../../components/Table/WithPlugins'
import { scrollIntoView } from '../../../utils/commonUtilities'
import { fetchDailySummaryData, generateExcel } from './DailySummaryAction'
import DailySummaryFilters from './DailySummaryFilters'
import "../Reporting.css"
import Notification from '../../../components/Notification/Notifications'
import * as Notify from "../../../components/Notification/Notifications";
import { DAILY_SUMMARY_TABLE_HEADERS } from './DailySummaryConstants'
import ColumnFilter from '../../UserManagement/ColumnFilter'
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping'
import SKUStoreLevelDetails from './SKUStoreLevelDetails'
import { isEmpty } from 'lodash'
import { numberUSFormatting } from '../../../utils/formatters/valueFormatters'
import ToggleComponent from '../../../components/Toggle/ToggleComponent';
import "./DailySummary.css"
import StyleColorTable from './StyleColorTable'
import StoreView from './StoreView/StoreView'
import { ERROR_MSG } from '../../../constants/globalConstant'
import { DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING } from '../../../components/ExportFile/ExportExcelConstants'

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const DailySummary = (props) => {

    const tableRef = useRef()

    const [req, setReq] = useState({})
    const [tableData, setTableData] = useState([])
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [clickedArticle, setClickedArticle] = useState({})
    const [isStoreView, setIsStoreView] = useState(false)

    useEffect(() => {
        if (props.tableData?.length)
            scrollIntoView(tableRef)
    }, [props.tableData])

    useEffect(() => {
        setDownloadExcel(false)
        if (props.tableData?.length > 0) {
            setTableData([...props.tableData]);
        }
        else {
            setTableData([])
        }
    }, [props.tableData])

    const handleFilterRequest = React.useCallback((req) => {
        setIsStoreView(false)
        setClickedArticle({})
        setDownloadExcel(false)
        setReq(req)
        props.fetchDailySummaryData({ request: req })
        setTableData([])
    }, [])

    useEffect(() => {
        if (props.excelError) {
            Notify.error("Error in downloading Excel!!")
        }
    }, [props.excelError])

    const getSKUStoreDetails = React.useCallback((row) => {
        let l_req = { "article": [{ "value": row.article }], released_date: row.org_updated_at, allocationcode: row.allocation_code }
        setClickedArticle(l_req)
    }, [])


    return (
        <div>
            {/* <Notification /> */}
            <DailySummaryFilters activeTab={props.activeTab} setClickedArticle={() => setClickedArticle({})} handleFilterRequest={(p_req) => handleFilterRequest(p_req)} />
            <div>
                <PageLoader loader={props.loading} gridLoader={true}>
                    {
                        props.error ? <div className="error">{props.tableDataError ||  ERROR_MSG}</div>
                            : <>
                                {!!tableData?.length &&
                                    <div className="daily-alloc__toggle row justify-content-end">
                                        <ToggleComponent
                                            secondaryToggleText={"Style Color View"}
                                            primaryToggleText={"Store View"}
                                            // primaryToggleState={!isDefaultDynamic}
                                            onToggle={() => setIsStoreView((old) => !old)}
                                        />
                                    </div>}
                                {
                                    !isStoreView &&
                                    <div className="card__wrapper">
                                        {props?.cardData?.map((val, ind) => (
                                            <Card key={ind} title={val.title} value={val.value} />)
                                        )}
                                    </div>
                                }
                                {tableData.length && !isStoreView ?
                                    <div className="deepdive__export__wrapper">
                                        <ExportFile text={"Excel"} data={props.excelData} sheetName={"Daily Allocation Summary"} fileName={`daily_summary_${new Date().getTime()}`} type={1} formateXLSX={true} excelColumnsToBeFormatted={DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING}/>
                                    </div>
                                    : null}
                                {
                                    isStoreView ? <StoreView selectedFilters={req} />
                                        : <div style={{ paddingTop: '2rem' }} ref={tableRef}>
                                            <StyleColorTable dc={props.dc} data={tableData} getDetails={getSKUStoreDetails} tableId={"dailySummary-table"} />
                                        </div>
                                }
                            </>
                    }
                </PageLoader>
            </div>
            {
                !isStoreView &&
                <div>
                    {!isEmpty(clickedArticle) ?
                        <>
                            <div className="storesStore__heading mt-4">
                                <h3 className="fnt-md fnt-bold ml-4">Style Color ID - {clickedArticle?.article[0]?.value}</h3>
                                <span></span>
                            </div>
                            <hr />
                            <SKUStoreLevelDetails dc={props.dc} req={{ ...req, ...clickedArticle }} />
                        </>
                        : null}
                </div>
            }
        </div>
    )
}

const mapStateToProps = ({ dailySummary }) => ({
    error: dailySummary.error,
    loading: dailySummary.loading,
    cardData: dailySummary.cardData,
    tableData: dailySummary.tableData,
    excelData: dailySummary.excelData,
    dc: dailySummary.dc
});

const mapDispatchToProps = (dispatch) => ({
    fetchDailySummaryData: (payload) => dispatch(fetchDailySummaryData(payload)),
    generateExcel: (payload) => dispatch(generateExcel(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DailySummary);
