import { takeLatest, put, call, all } from "redux-saga/effects";
import * as actionTypes from "./POAllocationAction/actionTypes"
import {
    getAutoApprovalDetails,
    getPOASNDetails
} from "../../../routes/api";
import { dateFormat } from "../../../utils/commonUtilities";
import { cloneDeep } from "lodash";
import { ERROR_MSG } from "../../../constants/globalConstant";


function* getPOAllocationDataWorker(action) {
    try {
        const { payload } = action;
        const isAutoApprove = ["coach", "SW"].includes(process.env.REACT_APP_CLIENT)

        const res = yield call(isAutoApprove ? getAutoApprovalDetails : getPOASNDetails, payload);
        let { data, message, status } = res?.data
        if (status) {
            let poData = cloneDeep(data)
            for (let index = 0; index < poData.length; index++) {
                poData[index].inventory_date = dateFormat(poData[index].inventory_date);
                if(poData[index]?.subRows?.length) {
                    poData[index]?.subRows?.forEach(row => {
                        row.intro_date = row?.intro_date ? dateFormat(row.intro_date) : "";
                        row.max_allocation_date = row?.max_allocation_date ? dateFormat(row.max_allocation_date) : "";
                    })
                }
            }
            
            yield put({ type: actionTypes.GET_PO_DATA_SUCCESS, data: poData });
        }
        else {
            yield put({ type: actionTypes.GET_PO_DATA_ERROR, error: message ? message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_PO_DATA_ERROR, error: ERROR_MSG });
    }
}

function* getPOAllocationDataWatcher() {
    yield takeLatest(actionTypes.GET_PO_DATA, getPOAllocationDataWorker)
}

export function* poAllocationSaga() {
    yield all([
        getPOAllocationDataWatcher()
    ])
}