import * as actionTypes from "../Actions/ActionTypes"
import { initialState } from "./InitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    excelDownload: handleActions(
        {
            [actionTypes.GET_EXCEL_DATA]: (state, { payload }) => {
                return immutable(state, {
                    excelData: { $set: [] },
                    downloadExcelError: { $set: null },
                    downloadLoading: { $set: true }
                });
            },
            [actionTypes.GET_EXCEL_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    // tableDataLoading: { $set: false },
                    excelData: { $set: (payload && payload.excelData) || [] },
                    tableDataSuccess: { $set: true },
                    downloadNextIndex: { $set: (payload && state.downloadLoading && payload.nextIndex) || 0 },
                    downloadTotalCount: { $set: (payload && payload.totalCount) || 0 },
                    downloadExcelError: { $set: null }
                });
            },
            [actionTypes.GET_EXCEL_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    excelData: { $set: [] },
                    downloadNextIndex: { $set: 0 },
                    downloadTotalCount: { $set: 0 },
                    downloadExcelError: { $set: payload.error }
                });
            },
            [actionTypes.RESET_DOWNLOAD_EXCEL]: (state, { payload }) => {
                return immutable(state, {
                  excelData: { $set: [] },
                  downloadNextIndex: { $set: 0 },
                  downloadTotalCount: { $set: 0 },
                  downloadExcelError: { $set: null },
                  downloadLoading: { $set: false },
                });
              },
        },

        initialState
    )
}