import * as StoreConfigActionTypes from './actionTypes';

/**
 * Action function to fetch store details list
 * 
 */
export const getStoreConfigDetails = () => ({
    type: StoreConfigActionTypes.GET_STORE_CONFIG_DETAILS_REQUEST,
});

/**
 * Action function to set selected new store Details
 */
export const setSelectedNewStoreDetail = (payload) => ({
    type: StoreConfigActionTypes.SET_SELECTED_NEW_STORE_DETAIL,
    payload,
});

/**
 * Action function to set selected new store code
 */
export const setSelectedNewStoreCode = (payload) => ({
    type: StoreConfigActionTypes.SET_SELECTED_NEW_STORE_CODE,
    payload,
});

/**
 * Action function to set selected sister store Details
 */
export const setSelectedSisterStoreDetail = (payload) => ({
    type: StoreConfigActionTypes.SET_SELECTED_SISTER_STORE_DETAIL,
    payload,
});

/**
 * Action function to set selected sister store code
 */
export const setSelectedSisterStoreCode = (payload) => ({
    type: StoreConfigActionTypes.SET_SELECTED_SISTER_STORE_CODE,
    payload,
});


/**
 * Action function to fetch dc source details
 *  
 */
export const getDcSourceDetailsData = (payload) => ({
    type: StoreConfigActionTypes.GET_DC_SOURCE_DATA_REQUEST,
    payload,
});

/**
 * Action function to set selected Dc Source Table Rows Details
 */
export const setSelectedDcSourceRowsDetail = (payload) => ({
    type: StoreConfigActionTypes.SET_SELECTED_DC_ROWS_DETAIL,
    payload,
});

/**
 * Action function to set selected Dc Source Table Rows
 */
export const setDcSourceRowsSelected = (payload) => ({
    type: StoreConfigActionTypes.SET_DC_ROWS_SELECTED,
    payload,
});

/**
 * Action function to reset Store Details Config
 */
export const resetStoreDetailsConfig = () => ({
    type: StoreConfigActionTypes.RESET_STORE_DETAILS_CONFIG,
});

/**
 * Action function to edit opening Date
 */
export const editOpeningDate = (payload) => ({
    type: StoreConfigActionTypes.EDIT_OPENING_DATE,
    payload,
});

/**
 * Action function to edit Dc Source Table Lead Time
 */
export const editDcLeadTime = (payload) => ({
    type: StoreConfigActionTypes.EDIT_DC_SOURCE_LEAD_TIME,
    payload,
});

/**
 * Action function to edit Dc Source Table Lead Time
 */
export const updateDcSourceLeadTime = (payload) => ({
    type: StoreConfigActionTypes.UPDATE_DC_SOURCE_LEAD_TIME_REQ,
    payload,
});

/**
 * Action function to set Edit mode details
 */
export const setEditModeDetails = (payload) => ({
    type: StoreConfigActionTypes.SET_EDIT_MODE_DETAILS,
    payload,
});

export const setDCCardDetails = (payload) => ({
    type: StoreConfigActionTypes.SET_DC_CARD_DETAILS,
    payload,
})