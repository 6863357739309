import { cloneDeep } from "lodash";
import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ColumnFilter from "../../UserManagement/ColumnFilter";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const AvailableTable = forwardRef((props, ref) => {
    const { data: availData, tableData } = props
    const { sizes, dcs } = tableData
    const [availableColumns, setAvailableColumns] = useState([]);
    const [availableData, setAvailableData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if(availData?.length) {
      const articleObj = {};
      availData.forEach((row) => {
        if (!articleObj?.[row.article]) {
          articleObj[row.article] = row;
        }
        else {
          articleObj[row.article] = {
            ...articleObj[row.article], 
            is_eaches: true,
            eaches_available: { ...articleObj[row.article]?.eaches_available, ...row?.eaches_available },
          }
        }
      });
      const articleData = Object.values(articleObj);
      const is_pack = articleData.some(item => item.is_pack)
      const is_eaches = articleData.some(item => item.is_eaches)
      
      let data = [];
      let columnData = [];
      let column = [];
      setTotalCount(articleData?.length)

      articleData.forEach((row) => {
        row.is_eaches = true;
        dcs.forEach((dc, index) => {
          let key = Object.keys(dc)[0];

          let pushObj = {
            article: row.article,
            dc_name: key,
            isShowArticle: index === 0
          };
          if(row.is_eaches) {
            pushObj = {
              ...pushObj,
              size: row.eaches_available?.[key],
            }
          }
          
          if (row.is_pack) {
              pushObj = {
                  ...pushObj,
                  packs_available: row.packs_available?.[key],
              };
              if(index === dcs?.length - 1) {
                let packConfig = Object.values(row.pack_config)[0];
                data.push(pushObj)
                pushObj = {
                  dc_name: "Pack Config",
                  size: packConfig,
                  article: row.article,
                  isShowArticle: false
                }
              }
          }
          data.push({...pushObj, isBorder: index === dcs?.length - 1 });
      });
      });
      columnData = [
        {
          Header: "Style Color",
          accessor: "article",
          sticky: "left",
          Cell: ({ value, row }) =>
            row.original?.isShowArticle ? (
              <td className="row-span2__column">{value}</td>
            ) : row.original.isBorder ? (
              <td className="row-span2__column border-bottom__td"></td>
            ) : (
              <></>
            ),
          rowSpan: 2,
          Filter: ColumnFilter
        },
        {
          Header: " ",
          accessor: "dc_name",
          sticky: "left",
          width: 200,
          disableFilters: true
        },
      ];
      column = sizes?.map((val) => {
        return {
          Header: val,
          accessor: (data) => data?.size?.[val] >= 0 ? data?.size?.[val] : "",
          disableFilters: true
        };
      });

      if (is_pack) {   
        column.push({
          Header: "# Packs Avaialble",
          accessor: "packs_available",
          disableFilters: true
        });
      }
      if (is_eaches) {
        // setEachesAvailable(max_eaches_available);
        // setUpdatesEachesSizeMapping({});

        // let table_data = cloneDeep(availData);

        // let dcMapping = {};
        // dcs.forEach((item) => {
        //   let dcKey = Object.keys(item)[0];
        //   dcMapping[dcKey] = Object.values(item)[0];
        // });
        // table_data.forEach((item) => {
        //   for (let dc in dcMapping) {
        //     if (Object.keys(item.size_value).includes(dc)) {
        //       sizes.forEach((s, index) => {
        //         item[dcMapping[dc] + s] = item.size_value[dc][index];
        //       });
        //     }
        //   }
        // });
      }
      columnData = [...columnData, ...column];


      setAvailableColumns(columnData);
      setAvailableData(data);
      // setRequest1({});
      // setShowAvailable(true);
      // setSelectedRows(Object.keys(RTinstance.state.selectedRowIds));
    }
  }, [availData]);

  useImperativeHandle(ref, () => ({
    updateAvailableData(value, focusedValue, article, s, dcKey, isPack) {
      let copyData = cloneDeep(availableData)
      let modified = []
      copyData.forEach(val => {
          if (val.dc_name === dcKey && val.article === article) {
            if(isPack) {
              val['packs_available'] = (Number(val['packs_available']) || 0) - (Number(value) - Number(focusedValue))
            }
            else {
              val.size[s] = (Number(val?.size?.[s]) || 0) - (Number(value) - Number(focusedValue))
            }
          }
          modified.push(val)
      })
      setAvailableData(modified)
    }
  }));

  

  return (
    <div style={{ margin: "3rem 0" }}>
      <div className="row justify-content-center mb2">
        <h1 className="fnt-md fnt-bold">Available eaches</h1>
      </div>
      <ReactTableWithPlugins
        tableWrapperStyle={{ height: "fit-content", maxHeight: "60rem" }}
        headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
        embededScroll
        hideColumnsFilter
        data={availableData.length ? availableData : []}
        columns={availableColumns.length ? availableColumns : []}
        renderMarkup="TableMarkup"
        keyRT="sortAndSearch"
        isHideCount={true}
        hideDropdown
        totalCount={totalCount}
      />
    </div>
  );
});


export default React.memo(AvailableTable)