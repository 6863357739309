import { takeLatest, put, call, all } from "redux-saga/effects";
import * as actionTypes from "../actions/NewStoreApprove/NewApproveActionTypes"
import {
    getNewReservedStoreList, getReservedArticlesInfo, approveNewStoreArticles, getNewStoreAllocatedArticles, releaseNewStoreArticles
} from "../../../routes/api";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import { ERROR_MSG } from "../../../constants/globalConstant";


function* getNewStoreApproveListWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getNewReservedStoreList, payload);
        if (res.data.status) {
            let data = cloneDeep(res?.data?.new_reserved_store_details)
            data.forEach(item => {
                item.reservation_start_date = moment(item.reservation_start_date).format("MM-DD-YYYY")
                item.open_date = moment(item.open_date).format("MM-DD-YYYY")
            })
            yield put({ type: actionTypes.GET_NEW_STORE_LIST_SUCCESS, data });
        }
        else {
            yield put({ type: actionTypes.GET_NEW_STORE_LIST_ERROR, error: res?.data?.message ? res.data.message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_NEW_STORE_LIST_ERROR, error: ERROR_MSG });
    }
}

function* getNewStoreArticleDetailsWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for(let key in payload) {
            if(!isEmpty(payload[key])) {
                req[key] = payload[key]
            }
        }
        delete req?.isRelease
        const res = yield call(payload?.isRelease ? getNewStoreAllocatedArticles : getReservedArticlesInfo, req);
        if (res.data.status) {
            let data = payload?.isRelease ? res?.data?.new_reserved_article_details : res?.data?.reserved_article_info
            yield put({ type: actionTypes.GET_NEW_STORE_ARTICLE_DETAILS_SUCCESS, data: { data, dcMap: res?.data?.dc_map } });
        }
        else {
            yield put({ type: actionTypes.GET_NEW_STORE_ARTICLE_DETAILS_ERROR, error: res?.data?.message ? res.data.message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_NEW_STORE_ARTICLE_DETAILS_ERROR, error: ERROR_MSG });
    }
}

function* approveNewStoreArticlesWorker(action) {
    try {
        const { payload } = action;
        let req = cloneDeep(payload)
        delete req?.isRelease
        const res = yield call(payload?.isRelease ? releaseNewStoreArticles : approveNewStoreArticles, req);
        if (res?.status === 200) {
            if(res?.data?.status) {
                yield put({ type: actionTypes.APPROVE_NEW_STORE_ARTICLES_SUCCESS, data: true });
            }
            else {
                yield put({ type: actionTypes.APPROVE_NEW_STORE_ARTICLES_ERROR, error: "Name already exists!!"});
            }
        }
        else {
            yield put({ type: actionTypes.APPROVE_NEW_STORE_ARTICLES_ERROR, error: res?.data?.message ? res.data.message : `Error in ${payload?.isRelease ? "releasing" : "approving"}!` });
        }
    } catch (error) {
        yield put({ type: actionTypes.APPROVE_NEW_STORE_ARTICLES_ERROR, error: ERROR_MSG });
    }
}

function* getNewStoreApproveListWatcher() {
    yield takeLatest(actionTypes.GET_NEW_STORE_LIST, getNewStoreApproveListWorker)
}

function* getNewStoreArticleDetailsWatcher() {
    yield takeLatest(actionTypes.GET_NEW_STORE_ARTICLE_DETAILS, getNewStoreArticleDetailsWorker)
}

function* approveNewStoreArticlesWatcher() {
    yield takeLatest(actionTypes.APPROVE_NEW_STORE_ARTICLES, approveNewStoreArticlesWorker)
}

export function* nsApproveSaga() {
    yield all([
        getNewStoreApproveListWatcher(),
        getNewStoreArticleDetailsWatcher(),
        approveNewStoreArticlesWatcher()
    ])
}