import React from "react";
import NewStoresTable from "../../NewStoreSetUp/views/NewStoresList/NewStoreListTable";
import { TABLE_HEADERS } from "../../NewStoreSetUp/views/NewStoresList/NewStoreConstants"
import ColumnFilter from "../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";

const NewStoreApproveTable = (props) => {
    const { data, getArticleDetails } = props

    const columns = [
        {
            sticky: "left",
            Header: TABLE_HEADERS.STORES,
            accessor: 'store_code',
            Filter: ColumnFilter,
            width: 200
        },
        {
            sticky: "left",
            Header: TABLE_HEADERS.STORE_NAME,
            accessor: 'store_name',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: TABLE_HEADERS.START_DATE,
            accessor: 'reservation_start_date',
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: TABLE_HEADERS.OPENING_DATE,
            accessor: 'open_date',
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: TABLE_HEADERS.LEAD_TIME,
            accessor: 'lead_time',
            Filter: ColumnFilter
        },
        {
            Header: TABLE_HEADERS.RESERVED_QTY,
            accessor: 'reserve_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: TABLE_HEADERS.APPROVED_QTY,
            accessor: 'approved_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: TABLE_HEADERS.ALLOCATED_QTY,
            accessor: 'allocated_quantity',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        // {
        //     Header: "Actions",
        //     accessor: "actions",
        //     sticky: "right",
        //     Cell: (instance) => (
        //         <div>
        //             <button
        //                 className="btn btn-outline-secondary btn-sm px-2 rounded-lg text-body"
        //                 onClick={() => getArticleDetails(instance.row.original)}
        //             >
        //                 Review
        //                 <i
        //                     className="fa fa-angle-right ml-2"
        //                     title="Configure"
        //                     aria-hidden="true"
        //                 ></i>
        //             </button>
        //         </div>
        //     ),
        //     width: 100,
        //     disableFilters: true,
        //     resizable: true
        // }
    ]

    return (
        <div className="mb-4">
            <NewStoresTable data={data} columns={columns} tableId={"new_store_approve_table"} />
        </div>
    )
}


export default NewStoreApproveTable;