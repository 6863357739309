import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Modal.css"

import * as Notify from "../Notification/Notifications";
import Notification from "../Notification/Notifications";
import ReactTable from "../Table/ReactTable";
import WithDynamicPlugins from "../Table/WithPlugins";
import SelectCellComponent from "../Table/Cellrenderer/SelectCellComponent";
import MultiSelectCell from "../Table/Cellrenderer/MultiSelectCell";
import InputCell from "../Table/Cellrenderer/InputCell";
import Select from "../Filters/SingleSelect";
import PageLoader from "../Loader/PageLoader";
import { updateTableData } from "../../containers/Constraints/ConstraintsAction";
import ColumnFilter from "../../containers/UserManagement/ColumnFilter";
import { levelFiltersLabelFormatter } from "../../utils/filterLevelMapping"
import NumberRangeColumnFilter from "../Filters/NumberRangeColumnFilter";
import { shouldHideForCurrentClient } from "../../utils/commonUtilities";
import { ERROR_MSG } from "../../constants/globalConstant";

export const GetInstanceContext = React.createContext();
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

function ConstraintsModal(props) {

    const [RTinstance, setRTinstance] = useState(null)
    const [isButtonClicked, setIsButtonClicked]= useState(false)
    const [filters, setFilters]= useState(props.filters)
    const [shouldLoading, setShouldLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [dcColumns, setDCColumns] = useState([])

    const columns = React.useMemo(
        () => [
              {
                Header: 'Store Number',
                accessor: 'store_code',
                width: 200,
                Filter: ColumnFilter
              },
              {
                Header: 'Store Name',
                accessor: 'store_name',
                width:200,
                Filter: ColumnFilter
              },
              {
                Header: 'Store Grade',
                accessor: 'store_grade',
                Filter: ColumnFilter,
                filter: 'exactText',
              },
              {
                Header: 'Ref. Store',
                accessor: 'ref_store',
                Cell: (instance) => <SelectCellComponent 
                    defaultValue={instance.row.original?.referStore}
                    options={instance?.row?.original?.ref_store}
                    {...instance}/>,
                width:200,
                disableFilters: true
              },
              {
                Header: 'Min',
                accessor: 'min_store',
                Cell:(instance) => <InputCell shouldOnBlur roundOffOnChange min={0} max={Number(instance.row?.original?.max_store) } step={1} type = "number" {...instance}/>,
               Filter: NumberRangeColumnFilter,
               filter: 'between',
              },
              {
                Header: 'Max',
                accessor: 'max_store',
                Cell:(instance) => <InputCell shouldOnBlur roundOffOnChange min={Number(instance.row?.original?.min_store) } step={1} type = "number" {...instance}/>,
               Filter: NumberRangeColumnFilter,
               filter: 'between',
              },
              {
                Header: 'WOS',
                accessor: 'wos',
                Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
               Filter: NumberRangeColumnFilter,
               filter: 'between',
              },
              // {
              //   Header: 'Transit Time',
              //   accessor: 'transit_time_sum',
              //   Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //  Filter: NumberRangeColumnFilter,
              //  filter: 'between',
              // },
              {
                Header: "Transit Time",
                columns: dcColumns,
              //   columns: [
              // {
              //   Header: 'PUMA Retail Stores WH10',
              //   accessor: 'dc_store_1',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //  Filter: NumberRangeColumnFilter,
              //  filter: 'between',
              // },
              // {
              //   Header: 'U.S. Continental',
              //   accessor: 'dc_store_2',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //  Filter: NumberRangeColumnFilter,
              //  filter: 'between',
              // },
              // {
              //   Header: 'Retail Bulk',
              //   accessor: 'dc_store_3',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //  Filter: NumberRangeColumnFilter,
              //  filter: 'between',
              // },
              // {
              //   Header: 'Retail Bulk WH20',
              //   accessor: 'dc_store_4',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //  Filter: NumberRangeColumnFilter,
              //  filter: 'between',
              // },
              // {
              //   Header: 'United Legwear WHS',
              //   accessor: 'dc_store_5',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //  Filter: NumberRangeColumnFilter,
              //  filter: 'between',
              // },
              // {
              //   Header: 'Infinity',
              //   accessor: 'dc_store_6',
              //   // Cell:(instance) => <InputCell roundOffOnChange min={0} step={1} type = "number" {...instance}/>,
              //  Filter: NumberRangeColumnFilter,
              //  filter: 'between',
              // }]
            },
        ],[dcColumns]
      )

      useEffect(() => {
        if(props.isUpdated && isButtonClicked){
            props.closeAlert();
            setShouldLoading(false)
            // props.callAllApis(filters)
            props.callBackSetOtherViewsEmpty(props.view)
        }
      }, [props.isUpdated])

      useEffect(() => {
        if(props.popUpData) {
          let dcColumnNames = []
          props?.popUpData[0]?.dcs.forEach(dcName => {
            dcColumnNames.push({
              Header: dcName,
              accessor: dcName,
              Filter: NumberRangeColumnFilter,
              filter: 'between'
            })
          })
          setDCColumns(dcColumnNames)
        }
      }, [props.popUpData])

      const savePopUpData = () => {
        let data = RTinstance?.obje
        for (let i = 0 ;i< data.length; i++) {
          if(Number(data[i].min_store || 0) > Number(data[i].max_store|| 0)) {
            Notify.error("Make sure min is less than or equal to max !!")
            return;  
          }
        }
        setIsButtonClicked(true);
        setShouldLoading(true);
        let req = {
          data: RTinstance?.data,
          "screen_type": "constraint_popup"
        }
        props.updateTableData(req)
      }
  return (
    <Modal
      visible={props.showAlert}
      width="85%"
      // height="95%"
      effect="fadeInDown"
      onClickAway={props.closeAlert}
    >
      {/* <Notification /> */}

      <div className="modal-container">
        <div class="modal-header">
          <h5 className="page-heading text-left m-0">
            {props.storeDetails.storeName}
          </h5>
          <button type="button" class="close" data-dismiss="modal">
            <span style={{ cursor: "pointer" }}>
              <i
                className="fa fa-times text-right m-2 "
                aria-hidden="true"
                onClick={props.closeAlert}
              />
            </span>
          </button>
        </div>
        <div className="modal-middle-container modal-body">
          <div className="row mx-5 p-2">
            <div className="col-md-3">
              <label className="input-label mt-2">
                {levelFiltersLabelFormatter("level1")} :{" "}
                <b style={{ color: "rgba(0,0,0,0.7)" }}>
                  {props.storeDetails.department}
                </b>
              </label>
            </div>
            <div className="col-md-3">
              <label className="input-label mt-2 required">
                {levelFiltersLabelFormatter("level2")} :{" "}
                <b style={{ color: "rgba(0,0,0,0.7)" }}>
                  {props.storeDetails.gender}
                </b>
              </label>
            </div>
            {!shouldHideForCurrentClient("l4_name") && (
              <>
                <div className="col-md-3">
                  <label className="input-label mt-2">
                    {levelFiltersLabelFormatter("level3")} :{" "}
                    <b style={{ color: "rgba(0,0,0,0.7)" }}>
                      {props.storeDetails.rbu}
                    </b>
                  </label>
                </div>
                <div className="col-md-3">
                  <label className="input-label mt-2">
                  {levelFiltersLabelFormatter("level4")} :{" "}
                    <b style={{ color: "rgba(0,0,0,0.7)" }}>
                      {props.storeDetails.dcs}
                    </b>
                  </label>
                </div>
              </>
            )}
            <div className="col-md-3">
              <label className="input-label mt-2">
                Style :{" "}
                <b style={{ color: "rgba(0,0,0,0.7)" }}>
                  {props.storeDetails.style}
                </b>
              </label>
            </div>
            <div className="col-md-3">
              <label className="input-label mt-2">
                Color :{" "}
                <b style={{ color: "rgba(0,0,0,0.7)" }}>
                  {props.storeDetails.colour}
                </b>
              </label>
            </div>
            <div className="col-md-3">
              <label className="input-label mt-2">
                Size :{" "}
                <b style={{ color: "rgba(0,0,0,0.7)" }}>
                  {props.storeDetails.size}
                </b>
              </label>
            </div>
          </div>
          <div className="constraintsModal__Table">
            <PageLoader
              loader={props.popUpLoader || shouldLoading}
              gridLoader={true}
            >
              {props.popUpError ? (
                <div className="error">{ERROR_MSG}</div>
              ) : props.view == "storeGroup" ? (
                <ReactTableWithPlugins
                  shouldPagination
                  totalRecordsLen={props.popUpData.length}
                  data={props.popUpData}
                  columns={columns}
                  tableWrapperStyle={{height:'fit-content', maxHeight:'50rem'}}
                  headerWrapperStyle={{position:'sticky', top:0}}
                  renderMarkup="TableMarkup"
                  keyRT="sortAndSearch"
                  prepareRequest={true}
                  setIsDisabled={setIsDisabled}
                  getInstanceOnMount={(instance) => {
                    setRTinstance(instance);
                  }}
                  // containSubRow={true}
                  tableId="constraint_modal1"
                />
              ) : (
                <ReactTableWithPlugins
                  shouldPagination
                  initialHiddenColumns={["store_grade"]}
                  totalRecordsLen={props.popUpData.length}
                  data={props.popUpData}
                  columns={columns}
                  tableWrapperStyle={{height:'fit-content', maxHeight:'50rem'}}
                  headerWrapperStyle={{position:'sticky', top:0}}
                  renderMarkup="TableMarkup"
                  keyRT="sortAndSearch"
                  prepareRequest={true}
                  setIsDisabled={setIsDisabled}
                  getInstanceOnMount={(instance) => {
                    setRTinstance(instance);
                  }}
                  tableId="constraint_modal2"
                  // containSubRow={true}
                />
              )}
            </PageLoader>
          </div>
        </div>
        {/* <div className="modal-foote" style={{ padding: "10px" }}> */}
        <div className="text-center">
          <button
            onClick={savePopUpData}
            className="btn btn-primary"
            style={{ margin: "2.5rem 1.5rem 1.5rem" }}
            disabled={isDisabled}
          >
            Save
          </button>
          <button
            onClick={props.closeAlert}
            className="btn btn-primary"
            style={{ margin: "2.5rem 1.5rem 1.5rem" }}
          >
            Cancel
          </button>
        </div>
        {/* </div> */}
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ constraints }) => ({
  popUpData: constraints.popUpData,
  popUpLoader: constraints.popUpLoader,
  isUpdated: constraints.isUpdated,
  popUpError: constraints.popUpError
});

const mapDispatchToProps = (dispatch) => ({
  updateTableData: (payload) => dispatch(updateTableData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsModal);

