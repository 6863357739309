import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import "../../ProductStoreStatus.css"
import PageLoader from '../../../../components/Loader/PageLoader';
import { getStoreGroupMappingData, getProductProfiles, createConfig, resetAll, getStoreSizeContribution, getMappingStyles } from "../Actions/CreateConfigAction"
import * as Notify from '../../../../components/Notification/Notifications'
import Notification from '../../../../components/Notification/Notifications'
import StyleMappingTable from "./StlyeMappingTable";
import { updateArticleName } from "../../../../utils/filterLevelMapping";
import { ERROR_MSG } from "../../../../constants/globalConstant";
import { cloneDeep, isEmpty } from "lodash";

const StyleMapping = (props) => {
    const { isPopup, stylesData, dataLoading, getArticleConfigData, selectedArticles, setStyleInstance, articleDetails,
        closeModal, showModal, filters, createConfig, resetCreateConfigData, createConfigDataLoading } = props

    const [tableData, setTableData] = useState([])
    const [RTInstance, setRTInstance] = useState({})
    const [showData, setShowData] = useState(false)
    const [selectedsRow, setSelectedRow] = useState({})
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [isFirstCall, setisFirstCall] = useState(true)
    const [selectedFilters, setFilters] = useState({})

    const closeHandler = (editStatus) => {
        closeModal(editStatus)
    }

    const getStyles = (filters) => {
        setFilters(filters)
        setTableData([])
        setShowData(true)
        getArticleConfigData({ ...filters, is_style_mapping: true, row_index: 0, row_count: 1000 })
        setSearchTermReq({})
        setSortReq({})
        setTableData([])
        setRTInstance(null)
        setInitialIndex(0)
        setPrevIndex(0)
        setTotalCount(0);
        setNextIndex(0);
    }

    const saveMapping = () => {
        let req = {
            filters: { ...filters },
            related_article: RTInstance?.selectedFlatRows?.[0]?.original?.article,
            article: [articleDetails.article]
        }
        createConfig(req)
    }

    useEffect(() => {
        if (stylesData?.length) {
            let data = stylesData.filter(item => !selectedArticles.includes(item.article))
            let styles = data.map(item => item.article)
            if(articleDetails?.related_article) {
                let ele = data.splice(styles.indexOf(articleDetails?.related_article), 1)
                if(showData) {
                    if(RTInstance?.data?.length) {
                        setTableData([ele[0], ...RTInstance?.data, ...data])
                    }
                    else {
                        setTableData([ele[0], ...data])
                    }
                }
                setSelectedRow({0: true})
            }
            else {
                showData && setTableData(RTInstance?.data?.length ? [...RTInstance?.data, ...data]: data)
            }
        }
    }, [stylesData])

    useEffect(() => {
        if (isPopup) {
            if ((props.createConfigData?.configuration_id || props.createConfigDataSuccess)) {
                resetCreateConfigData()
                Notify.success(`Style ID Mapped Successfully!!`)
                setTimeout(() => {
                    closeHandler(true)
                }, 2000)
            }
            else if (props.createConfigDataError) {
                resetCreateConfigData()
                Notify.error( ERROR_MSG)
            }
        }
    }, [props.createConfigData, props.createConfigDataError, props.createConfigDataSuccess])

    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
            return;
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / 10))
        getArticleConfigData({ ...selectedFilters, row_index: nextIndex, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq })
    }

    const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
        if (p_type === "array") {
          if(split_type) {
            p_term = p_term.replace(/\s*,\s*/g, ",")
          }
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                var id = p_term.split(split_type ? split_type : " ")
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {

                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "values": id,
                        "search": p_term
                    }
                }
                return {
                    ...l_searchTermReq
                }
            })
        }
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)

    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq)) {
            isFirstCall && setisFirstCall(false)
        }
        setSortReq(p_sort[0])
    }

    useEffect(() => {
        if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(selectedFilters)) {
            setTableData([])
            setRTInstance(null)
            setInitialIndex(0)
            setPrevIndex(0)
            getArticleConfigData({...selectedFilters, row_index: 0, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq})
        }
    }, [searchTermReq, sortReq])

    useEffect(() => {
        // if (props.nextIndex == 100) {
        //     setPrevIndex(0)
        //     // setInitialIndex(0)
        // }
        setTotalCount(props.totalCount)
        setNextIndex(props.nextIndex)
    }, [props.totalCount, props.nextIndex])

    const setTableInstance = (instance) => {
        setRTInstance(instance)
        !isPopup && setStyleInstance(instance)
    }


    return (
        <>
            {
                isPopup &&
                <div className="modal__div">
                    <Modal
                        visible={showModal}
                        width="70%"
                        // height="95%"
                        effect="fadeInDown"
                        onClickAway={() => {closeHandler(false)}}
                    >
                        {/* <Notification /> */}
                        <div className="modal-container">
                            <div className="modal-header">
                                <h5 className="page-heading text-center m-0">
                                    Style Mapping
                                </h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span style={{ cursor: "pointer" }}>
                                        <i
                                            className="fa fa-times text-right m-2 "
                                            aria-hidden="true"
                                            onClick={() => {closeHandler(false)}}
                                        />
                                    </span>
                                </button>
                            </div>
                            <PageLoader loader={dataLoading || createConfigDataLoading} gridLoader={true}>
                                <div className="modal-middle-container modal-body">
                                    <div className="mx-2 pr-4 pl-4 row justify-content-around mb-4">
                                        <div className="detail__div mr-4">
                                            <span className="fnt-bold mr-2"> {updateArticleName(false)} ID : </span>
                                            <span>{articleDetails.article}</span>
                                        </div>

                                        <div className="detail__div ml-4 mr-4">
                                            <span className="fnt-bold mr-2">Style Color : </span>
                                            <span>{articleDetails.color_desc}</span>
                                        </div>

                                        <div className="detail__div ml-4 mr-4">
                                            <span className="fnt-bold mr-2">Style Description : </span>
                                            <span>{articleDetails.style_name}</span>
                                        </div>
                                    </div>
                                    <div className="mx-2 p-2">
                                        <StyleMappingTable data={tableData ? tableData : []} getStyles={getStyles} hideCheckBox={true}
                                            setRTinstance={setTableInstance} initialSelectedRows={selectedsRow}
                                            selectedFilters={filters} initialIndex={initialIndex} totalCount={totalCount}
                                            fecthDataWithSorting={fecthDataWithSorting} fecthDataWithSearchTerm={fecthDataWithSearchTerm}
                                            setFilters={setFilters} fetchData={fetchData} searchTermReq={searchTermReq} />
                                    </div>
                                </div>
                            </PageLoader>

                            <div className="text-center" style={{ padding: "1rem" }}>
                                <div className="center">
                                    <button
                                        className="btn btn-primary store-grp-modal__btn"
                                        onClick={() => {closeHandler(false)}}
                                    >
                                        Close
                                    </button>

                                    <button
                                        className="btn btn-primary fn__btn"
                                        onClick={saveMapping}
                                    >
                                        Edit Mapping

                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
            {
                !isPopup &&
                <PageLoader loader={dataLoading} gridLoader={true}>
                    <StyleMappingTable selectedFilters={filters} setRTinstance={setTableInstance} data={tableData} getStyles={getStyles}
                    fecthDataWithSorting={fecthDataWithSorting} fecthDataWithSearchTerm={fecthDataWithSearchTerm} 
                    setFilters={setFilters} fetchData={fetchData} searchTermReq={searchTermReq} initialIndex={initialIndex}
                    totalCount={totalCount} />
                </PageLoader>
            }
        </>
    )
}

const mapStateToProps = ({ createConfig }) => {
    return {
        stylesData: createConfig.mappingStyles,
        dataLoading: createConfig.mappingStylesLoading,
        dataError: createConfig.mappingStyles,
        createConfigData: createConfig.createConfigData,
        createConfigDataSuccess: createConfig.createConfigDataSuccess,
        createConfigDataLoading: createConfig.createConfigDataLoading,
        createConfigDataError: createConfig.createConfigDataError,
        nextIndex: createConfig.styleMappingNextIndex,
        totalCount: createConfig.styleMappingTotalCount
    }
}

const mapDispatchToProps = (dispatch) => ({
    getArticleConfigData: (payload) => dispatch(getMappingStyles(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(StyleMapping)