import { updateArticleName } from "../../../../utils/filterLevelMapping"

export const DEMAND_HEADER = "Import Demand & Constraints"
export const DEMAND_TABLE_HEADERS = {
    ARTICLE:  `${updateArticleName(false)} ID`,
    STYLE_NAME: "Style Color",
    DESC: "Style Description",
    GLOBAL_COLOR: "Global Color",
    STORE_GROUP: "Store Group",
    DEPARTMENT: "Department",
    GENDER: "Gender",
    SUB: "Sub Category",
    DCS: "DCS",
    PRODUCT_TYPE: "Product Type",
    SISTER_STORE: "Reference Store",
    MIN: "Min",
    MAX: "Max",
    WOS: "WOS",
    APS: "APS",
    ACTUAL_ROS: "Actual ROS",
    DEMAND_ESTIMATED: "Demand Estimated",
    PRODUCT_PROFILE: "Product Profile",
    INV: "Inv Available",
    SELECT_STORE: "Reference Sister Store",
    ASSORT: "Assortment Indicator"
}