import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as AdaActionsTypes from '../actions/actionTypes';
import { chunkArray, getWeeklyGraphData } from '../AdaUtilities';
import {
    getAdaVisualFilterData, getAdaAllocationData, getAdaStandAloneData,
    getAdaDriversData, getAdaHierarchyData, postAdaReviewData, postAdaSaveData, saveChangesCaVisual ,getAdaHierarchyAllocationData, getAdaStoreHierarchyData
} from "../../../routes/api";
import { ERROR_MSG } from "../../../constants/globalConstant";
import { forEach, groupBy,isEmpty } from "lodash";
import { fetchExcelDataError, fetchExcelDataSuccess } from "../actions/AdaVisualActions";
import { CHUNK_SIZE } from "../AdaVisualConstants";

function* postAdaSaveReq(action) {
    const { payload } = action;

    try {
        const res = yield call(postAdaSaveData, payload);
        const { data } = res;

        let response = {
            adaData: data.overall_data.AdaData,
            adaGraphData: getWeeklyGraphData(data.overall_data.AdaData, payload.current_fw),
            indHierarchyData: { ...data.individual_data },
        };

        yield put({ type: AdaActionsTypes.SAVE_ADA_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.SAVE_ADA_DATA_FAILED, error: "Network Request Failed!!!" }));
    }
}

function* postAdaReviewReq(action) {
    const { payload } = action;
    const { changed_combinations_lower_hierarchy_week, changed_combinations_adjusted_forecast_week } = payload;
    let changedArticles = changed_combinations_lower_hierarchy_week ? Object.keys(changed_combinations_lower_hierarchy_week) : [];
    let isAdjustedForecastChanged = !!changed_combinations_adjusted_forecast_week;
    try {
        const res = yield call(postAdaReviewData, payload);
        const { data } = res;

        let response = {
            adaData: data.overall_data.AdaData,
            adaGraphData: getWeeklyGraphData(data.overall_data.AdaData, payload.current_fw),
            indHierarchyData: { ...data.individual_data },
            changedArticles: changedArticles,
            isAdjustedForecastChanged: isAdjustedForecastChanged,
        };

        yield put({ type: AdaActionsTypes.REVIEW_ADA_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.REVIEW_ADA_DATA_FAILED, error: "Network Request Failed!!!" }));
    }
}

function* fetchAdaHierarchy(action) {
    const { payload } = action;
    let { request, articles, isSearch = false, searchedArticles, total } = payload;
    const PARALLEL_CALLS_COUNT = 5;
    try {

        let parallelCalls = []
        let rowIndex = payload?.rowIndex;
        let searchRowIndex = payload?.rowIndex;
        if (isSearch) rowIndex = 0;
        let count = 1;
        let run = true;
        let totalArticleLength = total;
        if (isSearch) {
            articles = searchedArticles;
        }
        // Divide the articles array into small chunks so that we can get the store data for multiple articles together.
        const chunkSize = 20;  
        const chunkedArticles = chunkArray(articles, chunkSize);
        while(run) {
            if(count >= PARALLEL_CALLS_COUNT) {
                run = false;
            }
            if(rowIndex < articles.length) {
                parallelCalls.push(call(getAdaHierarchyData, {...request, articles: chunkedArticles[Math.floor(rowIndex/chunkSize)] }))
                if (isSearch) {
                    rowIndex = searchRowIndex;
                } else {
                    rowIndex = (payload.rowIndex) + (count * chunkSize) 
                }
            }
            count++;
        }
       if (isSearch) {
        rowIndex += articles.length;
       }
        const res = yield all(parallelCalls)
        
        let hierarchyData = []
        res?.forEach(item => {
            let newData = item?.data?.AdaData.lower_heirarchy_forecast_data;
            hierarchyData.push(...newData);
        })    
        
        let articleSet = new Set();
        forEach(hierarchyData, item => {
            articleSet.add(item.article)
        })
        totalArticleLength += articleSet.size; 

        // Sort as per the week and year in desc order
        // hierarchyData = hierarchyData.sort((a, b) => {
        //     // Compare the "fy" values first in ascending order (older year to newer year)
        //     if (a.fy < b.fy) return -1;
        //     if (a.fy > b.fy) return 1;
          
        //     // If the "fy" values are equal, compare the "fw" values in ascending order (01 to 45)
        //     return parseInt(a.fw, 10) - parseInt(b.fw, 10);
        //   });

        yield put({ 
            type: AdaActionsTypes.GET_ADA_HIERARCHY_DATA_SUCCESS,
            data: hierarchyData,
            nextIndexExcel: rowIndex,
            lowerHierarchyNextIndex: rowIndex,
            totalRecords: totalArticleLength,
        });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_HIERARCHY_DATA_FAILED, error: "Something went wrong!" }));
    }
}

function* fetchAdaHierarchyAllocation(action) {
    const { payload } = action;
    try {
        const response = yield call(getAdaHierarchyAllocationData, payload);
        yield put({ type: AdaActionsTypes.GET_ADA_HIERARCHY_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_HIERARCHY_DATA_FAILED, error: ERROR_MSG }));
    }
}

function* fetchAdaFilters(action) {
    const level_ln_map = {
        "level1": "l1_name",
        "level2": "l2_name",
        "level3": "l3_name",
        "level4": "l4_name",
        "level5": "l5_name",
        "level6":"l6_name",
        "level7":"l7_name",
        "assortment_indicator": "assortment_indicator",
      }
    try {
        const { payload, filterType } = action;
        let req = {};
        if (!isEmpty(payload)) {
            for (const key in payload) {
                !isEmpty(payload[key]) && !isEmpty(payload[key][0]) && (req[level_ln_map[key]|| key] = payload[key]?.map((ele) => ele?.value))
            }
        }
        if (filterType) {
            req["filter_type"] = filterType;
        }
        const res = yield call(getAdaVisualFilterData, req);

        if (res.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping;
            for(const topObject in res.data.data[0] )
            {
            // let topObject = Object.keys(res.data.data[0]);
            let k = "";
            if (topObject === "l1_name") {
                k = "departmentOptions";
            } else if (topObject === "l2_name") {
                k = "genderOptions";
            } else if (topObject === "l3_name") {
                k = "subCatOptions";
            } else if (topObject === "l4_name") {
                k = "dcsOptions";
            } else if (topObject === "l5_name") {
                k = "level5Options";
            } else if (topObject === "l6_name") {
                k = "level6Options";
            } else if (topObject === "l7_name") {
                k = "level7Options";
            } else if (topObject === "assortment_indicator") {
                k = "assortmentIndicatorOptions";
            } else if (topObject === "store_code") {
                k = "storeOptions";
            }

            data[k] = res.data.data[0][topObject]?.filter(val => val).map((element) => ({
                value: element,
                label: element,
            }));
        }

            data["articleIdOptions"] = res.data.data[0]["article"]?.filter(val => val).map((element) => ({
                value: element,
                label: element,
            }));

            yield put({ type: AdaActionsTypes.GET_ADA_FILTER_DATA_SUCCESS, data: data });
        }
    } catch (error) {
        yield put({ type: AdaActionsTypes.GET_ADA_FILTER_DATA_FAILED, error: ERROR_MSG });
    }
}

function* fetchAdaDriversData(action) {
    const { payload } = action;
    const { driversReq } = payload;

    try {
        const response = yield call(getAdaDriversData, driversReq);
        yield put({ type: AdaActionsTypes.GET_ADA_DRIVERS_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_DRIVERS_DATA_FAILED, error: ERROR_MSG }));
    }
}

function* fetchAdaAllocationData(action) {
    const { payload } = action;
    const { adaReq } = payload;

    try {
        const res = yield call(getAdaAllocationData, adaReq);

        let response = {
            adaData: res.data.AdaData,
            adaGraphData: getWeeklyGraphData(res.data.AdaData, payload.current_fw),
        };

        yield put({ type: AdaActionsTypes.GET_ADA_ALLOCATION_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_ALLOCATION_DATA_FAILED, error: ERROR_MSG }));
    }
}

function* fetchAdaStandAloneData(action) {
    const { payload } = action;

    try {
        const res = yield call(getAdaStandAloneData, payload);
        let response = {
            adaData: res.data.AdaData,
            adaGraphData: getWeeklyGraphData(res.data.AdaData, payload.current_fw),
            accuracy: res.data.accuracy
        };
        yield put({ type: AdaActionsTypes.GET_ADA_STANDALONE_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_STANDALONE_DATA_FAILED, error: ERROR_MSG }));
    }
}

function* saveChangesCaVisualWorker(action){
    const { payload } = action;
    try{
        const res = yield call(saveChangesCaVisual, payload);
    }
    catch(error){

    }
}

function* downloadCsvWorker(action){
    const { payload } = action;
    try{
        let excelData =[]
        payload.forEach(item=>{
            excelData.push({
                "Week":`W${item?.week}`,
                "IA Forecast":(item?.ia_forecast).toFixed(0),
                "Actual":item?.actual_sales,
                "Forecast Multiplier":item?.forecast_multiplier.toFixed(2),
                "Adjusted Forecasts":(item?.adjusted_forecast).toFixed(0)
            })
        }); 
        yield put({ type: AdaActionsTypes.DOWNLOAD_CSV_SUCCESS, excelData });

    }
    catch(error){
        yield put({ type: AdaActionsTypes.DOWNLOAD_CSV_ERROR, error: "Something went wrong!" });
    }
}

function* downloadExcelLowerWorker(action){
    const { payload } = action;
    try{
        let excelData =[]
        // Object.keys(payload?.articleMapping).map((k, rowIndex) => 
        // {
        //     Object.values(payload?.articleMapping[k]).map((item, i) => 
        //     {
        //         console.log('ss12333',item)

        //     })

        // }
        
        // )
        payload.data.forEach(item=>{
            excelData.push({
                "Style Color Id":item?.article,
                "Week": `W${item.fw}`,     
                "Adjusted Forecast": item.adjusted_forecast.toFixed(2)
            })
        })
            
        
        // let a = groupBy(payload,(obj=>{
        //     return obj.article
        // }));
        // Object.keys(a).map(key=>{
        //     a[key].map(value=>{
        //         console.log('asas',value,key)
        //     });
        // });
        
        // Object.keys(payload.articleMapping).forEach(key=>{
        //     console.log('asas',key,payload.articleMapping[key])
        //     // excelData.push({
        //     //     "Style Color Id":(item?.article),
        //     //     [`W${item?.fw}`]: item.adjusted_forecast,        
        //     // })
        // }); 
        yield put({ type: AdaActionsTypes.DOWNLOAD_EXCEL_LOWER_SUCCESS, excelData });

    }
    catch(error){
        yield put({ type: AdaActionsTypes.DOWNLOAD_EXCEL_LOWER_ERROR, error: "Something went wrong!" });
    }
}

function* fetchAdaStoreHierarchy(action) {
    const { payload } = action;
    
    try {
        const response = yield call(getAdaStoreHierarchyData, payload);
        yield put({ type: AdaActionsTypes.GET_ADA_STORE_HIERARCHY_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_STORE_HIERARCHY_DATA_FAILED, error: ERROR_MSG }));
    }
}

function* generateExcelWorker(action) {
    

    try {
        const { payload } = action;
        const { request, total, articles } = payload;
        
        let parallelCalls = []
        let rowIndex = payload?.rowIndex;
        let count = 1;
        let run = true;

        // Divide the articles array into small chunks so that we can get the store data for multiple articles together.  
        const chunkedArticles = chunkArray(articles, CHUNK_SIZE);

        const PARALLEL_CALLS_COUNT = 5;
        while(run) {
            if(count >= PARALLEL_CALLS_COUNT) {
                run = false;
            }
            if(rowIndex < chunkedArticles.length) {
                parallelCalls.push(call(getAdaStoreHierarchyData, {...request, articles: chunkedArticles[rowIndex] }))
            }
            rowIndex = payload.rowIndex + (count * payload.rowCount)
            count++;
        }
        const res = yield all(parallelCalls)
        let storeData = {}
        // TODO: add Error Handling here to make sure if any 1 API fails we need to give error for that Article 
        if (res[0]?.data) {        
            res?.forEach(item => {
                let newData = item?.data?.AdaData.lower_heirarchy_forecast_data;
                storeData = Object.assign(storeData, newData)
            })
            yield put(fetchExcelDataSuccess({data: storeData, totalCountExcel: chunkedArticles.length, nextIndexExcel: payload.rowIndex + (payload.rowCount * PARALLEL_CALLS_COUNT), nextIndex: payload.rowIndex + (payload.rowCount * PARALLEL_CALLS_COUNT) }));
  
        }
        else {
            yield put(fetchExcelDataError({error: ERROR_MSG }));
        }
    }
    catch (error) {
        yield put(fetchExcelDataError({error: ERROR_MSG }));
    }
  }

function* watchAdaSaveRequest() {
    yield takeLatest(AdaActionsTypes.SAVE_ADA_DATA_REQUEST, postAdaSaveReq);
}

function* watchAdaReviewRequest() {
    yield takeLatest(AdaActionsTypes.REVIEW_ADA_DATA_REQUEST, postAdaReviewReq);
}

function* watchAdaHierarchyDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_HIERARCHY_DATA_REQUEST, fetchAdaHierarchy);
}

function* watchAdaHierarchyAllocationDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_HIERARCHY_ALLOCATION_DATA_REQUEST, fetchAdaHierarchyAllocation);
}

function* watchAdaDriversDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_DRIVERS_DATA_REQUEST, fetchAdaDriversData);
}

function* getAdaFiltersData() {
    yield takeEvery(AdaActionsTypes.GET_ADA_FILTER_DATA_REQUEST, fetchAdaFilters);
}

function* watchAdaAllocationDataRequest() {
    yield takeEvery(AdaActionsTypes.GET_ADA_ALLOCATION_DATA_REQUEST, fetchAdaAllocationData);
}

function* watchAdaStandAloneDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_STANDALONE_DATA_REQUEST, fetchAdaStandAloneData);
}

function* watchSaveChangesCaVisualRequest(){
    yield takeLatest(AdaActionsTypes.SAVE_CHANGES_CA_VISUAL_REQUEST, saveChangesCaVisualWorker)
}
function* downloadCsvWatcher() {
    yield takeLatest(AdaActionsTypes.DOWNLOAD_CSV, downloadCsvWorker);
  }

function* downloadExcelLowerWatcher() {
    yield takeLatest(AdaActionsTypes.DOWNLOAD_EXCEL_LOWER, downloadExcelLowerWorker);
  } 

function* watchAdaStoreHierarchyDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_STORE_HIERARCHY_DATA_REQUEST, fetchAdaStoreHierarchy);
}

function* generateExcelWatcher() {
    yield takeLatest(AdaActionsTypes.GENERATE_EXCEL, generateExcelWorker)
}

export function* adaVisualSagas() {
    yield all([
        watchAdaAllocationDataRequest(),
        watchAdaStandAloneDataRequest(),
        getAdaFiltersData(),
        watchAdaDriversDataRequest(),
        watchAdaHierarchyDataRequest(),
        watchAdaReviewRequest(),
        watchAdaSaveRequest(),
        watchSaveChangesCaVisualRequest(),
        watchAdaHierarchyAllocationDataRequest(),
        downloadCsvWatcher(),
        downloadExcelLowerWatcher(),
        watchAdaStoreHierarchyDataRequest(),
        generateExcelWatcher()
    ]);
}