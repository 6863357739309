import * as actionTypes from "./DCConfigActionTypes"

/*
Function to dispatch filter options
*/

export const getSisterStoreFilters = (payload) => ({
    type: actionTypes.GET_SISTER_STORE_FILTERS,
    payload
})

/*
Function to dispatch save sister store filters
*/
export const saveSisterStoreFilters = (payload) => ({
    type: actionTypes.SAVE_SISTER_STORE_FILTERS,
    payload
})

/*
Function to dispatch save selected sister stores
*/
export const saveSelecetedSisterStores = (payload) => ({
    type: actionTypes.SAVE_SELECTED_SISTER_STORES,
    payload
})

/*
Function to dispatch save new store details
*/
export const upsertNewStoreDetails = (payload) => ({
    type: actionTypes.UPSERT_NEW_STORE_DETAILS,
    payload
})

export const resetAll = () => ({
    type: actionTypes.RESET_NEW_STORE_DETAILS
})

/*
Function to dispatch get new reserved article list
*/

export const getNewReservedArticleData = () => ({
    type: actionTypes.GET_NEW_RESERVED_ARTICLE_LIST,
})

/*
Function to dispatch save selected dc sources
*/

export const saveDCSource = (payload) => ({
    type: actionTypes.SAVE_SELECTED_DC,
    payload
})

/*
Function to dispatch save lead time dc mapping
*/

export const saveLeadTimeDcMapping = (payload) => ({
    type: actionTypes.SAVE_LEAD_TIME_DC_MAPPING,
    payload
})

/*
Function to dispatch get reserved articles of store in edit flow
*/

export const getReservedArticles = (payload) => ({
    type: actionTypes.GET_RESERVED_ARTICLES,
    payload
})

/*
Function to dispatch delete new store
*/

export const deleteNewStore = (payload) => ({
    type: actionTypes.DELETE_NEW_STORE,
    payload
})

export const resetDeleteData = () => ({
    type: actionTypes.RESET_DELETE_DATA,
})

/*
Function to save the sister store mapping
*/

export const setSisterStoreMapping = (payload) => ({
    type: actionTypes.SET_SISTER_STORE_MAPPING,
    payload
})

/*
Function to get store grades
*/

export const getStoreGrades = (payload) => ({
    type: actionTypes.GET_STORE_GRADES,
    payload
})

export const getNewStoreAllocations = (payload) => ({
    type: actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS,
    payload
})

export const setEditSisterStore = () => ({
    type: actionTypes.SET_EDIT_SISTER_STORE,
})