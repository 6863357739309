export const demandInitialState = {
    data: [],
    demandDataLoading: false,
    demandDataError: null,
    reviewData: [],
    reviewDataLoading: false,
    reviewDataError: null,
    addProductsData: [],
    addProductsDataLoading: false,
    addProductsDataError: null,
    selectedDemandRows: [],
    newStoreDataLoading: false,
    newStoreDataError: null,
    newStoreData: [],
    saveLoading: false,
    saveNewStoreSuccess: false,
    saveNewStoreError: null,
    newAllocationLoading: false,
    newAllocationData: [],
    newAllocationError: null,
    newAllocationSuccess: false,
    articleAllocationData: [],
    articleAllocationLoading: false,
    articleAllocationError: null,
    updateArticleAllocationSuccess: false,
    updateArticleAllocationLoading: false,
    updateArticleAllocationError: false,
    sizeCurveData: [],
    sizeCurveError: null,
    sizeCurveLoading: false,
    pageIndex: 0,
    style_index: 0,
    checkAllStyleIndex: 0,
    checkAllData: [],
    out_of_data: false,
    outOfDataCA: false,
    addPageIndex: 0,
    addStyle_index: 0,
    addCheckAllStyleIndex: 0,
    addCheckAllData: [],
    addOut_of_data: false,
    addOutOfDataCA: false,
    addDataPageIndex: 0
    // ppDataLoading: false,
    // ppData: ppData,
    // ppDataError: ppDataError
};