export const GET_SISTER_STORE_FILTERS = "@@newStoreSetup/GET_SISTER_STORE_FILTERS";
export const GET_SISTER_STORE_FILTERS_SUCCESS = "@@newStoreSetup/GET_SISTER_STORE_FILTERS_SUCCESS";
export const GET_SISTER_STORE_FILTERS_ERROR = "@@newStoreSetup/GET_SISTER_STORE_FILTERS_ERROR";

export const SAVE_SISTER_STORE_FILTERS = "@@newStoreSetup/SAVE_SISTER_STORE_FILTERS";
export const SAVE_SELECTED_SISTER_STORES = "@@newStoreSetup/SAVE_SELECTED_SISTER_STORES";
export const UPSERT_NEW_STORE_DETAILS = "@@newStoreSetup/UPSERT_NEW_STORE_DETAILS";
export const UPSERT_NEW_STORE_DETAILS_SUCCESS = "@@newStoreSetup/UPSERT_NEW_STORE_DETAILS_SUCCESS";
export const UPSERT_NEW_STORE_DETAILS_ERROR = "@@newStoreSetup/UPSERT_NEW_STORE_DETAILS_ERROR";

export const GET_NEW_RESERVED_ARTICLE_LIST = "@@newStoreSetup/GET_NEW_RESERVED_ARTICLE_LIST";
export const GET_NEW_RESERVED_ARTICLE_LIST_SUCCESS = "@@newStoreSetup/GET_NEW_RESERVED_ARTICLE_LIST_SUCCESS";
export const GET_NEW_RESERVED_ARTICLE_LIST_ERROR = "@@newStoreSetup/GET_NEW_RESERVED_ARTICLE_LIST_ERROR";

export const GET_RESERVED_ARTICLES = "@@newStoreSetup/GET_RESERVED_ARTICLES";
export const GET_RESERVED_ARTICLES_ERROR = "@@newStoreSetup/GET_RESERVED_ARTICLES_ERROR";
export const GET_RESERVED_ARTICLES_SUCCESS = "@@newStoreSetup/GET_RESERVED_ARTICLES_SUCCESS";

export const GET_STORE_GRADES = "@@newStoreSetup/GET_STORE_GRADES";
export const GET_STORE_GRADES_ERROR = "@@newStoreSetup/GET_STORE_GRADES_ERROR";
export const GET_STORE_GRADES_SUCCESS = "@@newStoreSetup/GET_STORE_GRADES_SUCCESS";

export const DELETE_NEW_STORE = "@@newStoreSetup/DELETE_NEW_STORE";
export const DELETE_NEW_STORE_ERROR = "@@newStoreSetup/DELETE_NEW_STORE_ERROR";
export const DELETE_NEW_STORE_SUCCESS = "@@newStoreSetup/DELETE_NEW_STORE_SUCCESS";
export const RESET_DELETE_DATA = "@@newStoreSetup/RESET_DELETE_DATA";

export const SAVE_SELECTED_DC =  "@@newStoreSetup/SAVE_SELECTED_DC";
export const SAVE_LEAD_TIME_DC_MAPPING =  "@@newStoreSetup/SAVE_LEAD_TIME_DC_MAPPING";

export const RESET_NEW_STORE_DETAILS = "@@newStoreSetup/RESET_NEW_STORE_DETAILS";
export const SET_SISTER_STORE_MAPPING = "@@newStoreSetup/SET_SISTER_STORE_MAPPING";

export const GET_NEW_STORE_PAST_ALLOCATIONS = "@@newStoreSetup/GET_NEW_STORE_PAST_ALLOCATIONS";
export const GET_NEW_STORE_PAST_ALLOCATIONS_SUCCESS = "@@newStoreSetup/GET_NEW_STORE_PAST_ALLOCATIONS_SUCCESS";
export const GET_NEW_STORE_PAST_ALLOCATIONS_ERROR = "@@newStoreSetup/GET_NEW_STORE_PAST_ALLOCATIONS_ERROR";

export const SET_EDIT_SISTER_STORE = "@@newStoreSetup/SET_EDIT_SISTER_STORE";
