import { handleActions, combineActions } from "redux-actions";
import immutable from "immutability-helper";
import {
  FETCH_STRATEGY_TABLE_DATA_SUCCESS,
  FETCH_STRATEGY_TABLE_DATA_ERROR,
  FETCH_FILTER_SUCCESS,
  FETCH_FILTER_ERROR,
  FETCH_STRATEGY_TABLE_DROPDOWN_SUCCESS,
  FETCH_STRATEGY_TABLE_DROPDOWN_ERROR,
  FETCH_SG_MIN_STOCK_SUCCESS,
  FETCH_SG_MIN_STOCK_ERROR,
  FETCH_STORE_TABLE_DATA_ERROR,
  FETCH_STORE_TABLE_DATA_SUCCESS,
  FETCH_DC_DATA_SUCCESS,
  FETCH_DC_DATA_ERROR,
  GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD,
  GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_SUCCESS,
  GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_ERROR,
  ENABLE_FILTERS,
  RESET_FILTERS_DATA,
  FETCH_FILTER_DATA,
  FETCH_STRATEGY_TABLE_DATA,
  FETCH_STORE_TABLE_DATA,
  EDIT_DATA_ADA,
  EDIT_DATA_ADA_SUCCESS,
  EDIT_DATA_ADA_FAILED,
  FETCH_DC_DATA,
  UPDATE_RESERVED_QTY,
  UPDATE_RESERVED_QTY_SUCCESS,
  UPDATE_RESERVED_QTY_ERROR,
  CREATE_ALLOCATION,
  CREATE_ALLOCATION_ERROR,
  CREATE_ALLOCATION_SUCCESS,
  SAVE,
  SAVE_SUCCESS,
  SAVE_ERROR,
  RESET_ALL,
  GETSTOREGROUPMINSTOCK_SUCCESS,
  GETSTOREGROUPMINSTOCK_ERROR,
  GET_DRAFTS,
  GET_DRAFTS_SUCCESS,
  GET_DRAFTS_ERROR,
  SAVE_DRAFT,
  SAVE_DRAFT_SUCCESS,
  SAVE_DRAFT_ERROR,
  DELETE_DRAFT,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_ERROR,
  VALIDATE_PLAN_NAME,
  VALIDATE_PLAN_NAME_SUCCESS,
  VALIDATE_PLAN_NAME_ERROR,
  RESET_SAVE_DRAFT_DATA,
  SET_LOADER,
  REVIEW_ADA,
  REVIEW_ADA_SUCCESS,
  REVIEW_ADA_ERROR,
  RESET_STYLE_INDEX,
  FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL,
  FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_SUCCESS,
  FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_ERROR,
  RESET_STYLE_INDEX_FOR_CHECKALL
} from "./actions";

const RESET_FETCH_FILTERS_DATA = combineActions(RESET_FILTERS_DATA, FETCH_FILTER_DATA)

export const strategyTableState = {
  data: [],
  loading: false,
  storeGroupStoreResult: {},
  out_of_data: false,
  style_index: 0,
  pageIndex: 0,
  storeMinStockResult: [],
  error: null,
  dataForCA: [] ,
  out_of_dataForCA: false,
  style_indexForCA: 0,
  pageIndexForCA: 0,
  errorForCA: null,
  divisions: [],
  departments: [],
  level4: [],
  level5: [],
  level6: [],
  level7: [],
  factoryType:[],
  subdepartments: [],
  storeCode: [],
  storeGroup: [],
  width: [],
  tableDropdowns: {},
  minStockData: null,
  storeTableData: [],
  dcTableData: [],
  disableFilters: false,
  filterlabels: {},
  storeDataLoading: false,
  dcDataLoading: false,
  saveSuccess: false,
  storeDataError: false,
  dcDataError: false,
  saveError: false,
  createAllocationError: false,
  createAllocationData: null,
  createAllocationSuccess: false,
  allocationCode: null,
  allocationCodeSuccess: false,
  allocationCodeError: false,
  changed_min_stock: {},
  saveDraftData: {},
  draftNameError: false,
  draftNameSuccess: false,
  editDataAdaSucess:false,
  reviewAdaLoading:false,
  reviewAdaSuccess:false,
  reviewAdaError:false,
  saveDraftLoading: false
  // createAllocationLoading: false,
};

const reducer = {
  strategyTable: handleActions(
    {
      [RESET_FETCH_FILTERS_DATA]: (state, { payload }) => {
        return immutable(state, {
          changed_min_stock: { $set: {} },
          data: { $set: [] },
          storeGroupStoreResult: { $set: {} },
          out_of_data: { $set: false },
          style_index: { $set: 0 },
          pageIndex: { $set: 0 },
          storeMinStockResult: { $set: [] },
          storeGroupData: { $set: [] },
          divisions: { $set: [] },
          storeCode: { $set: [] },
          storeGroup: { $set: [] }, 
          width: { $set: [] },
          departments: { $set: [] },
          level4: { $set: [] },
          level5: { $set: [] },
          level6: { $set: [] },
          level7: { $set: [] },
          filterType:{ $set: []},
          subdepartments: { $set: [] },
          storeTableData: { $set: null },
          dcTableData: { $set: null },
          loading: { $set: false },
          storeDataLoading: { $set: false },
          dcDataLoading: { $set: false },
          saveSuccess: { $set: false },
          createAllocationSuccess: { $set: false },
          error: { $set: null },
          storeDataError: { $set: false },
          dcDataError: { $set: false },
          saveError: { $set: false },
          createAllocationError: { $set: false },
          createAllocationData: { $set: null },
          allocationCodeError: { $set: false },
          allocationCode: { $set: null },
          allocationCodeSuccess: { $set: false },
          dataForCA: { $set: [] },
          out_of_dataForCA: { $set: false },
          style_indexForCA: { $set: 0 },
          pageIndexForCA: { $set: 0 },
          errorForCA: { $set: null },
          //  createAllocationLoading: { $set: false},
        });
      },
      [RESET_ALL]: (state, { payload }) => {
        return immutable(state, {
          changed_min_stock: { $set: {} },
          data: { $set: [] },
          storeGroupStoreResult: { $set: {} },
          out_of_data: { $set: false },
          style_index: { $set: 0 },
          pageIndex: { $set: 0 },
          storeMinStockResult: { $set: [] },
          storeGroupData: { $set: [] },
          divisions: { $set: [] },
          storeCode: { $set: [] },
          storeGroup: { $set: [] }, 
          width: { $set: [] },
          departments: { $set: [] },
          level4: { $set: [] },
          level5: { $set: [] },
          level6: { $set: [] },
          level7: { $set: [] },
          filterType:{ $set: []},
          subdepartments: { $set: [] },
          storeTableData: { $set: null },
          dcTableData: { $set: null },
          loading: { $set: false },
          storeDataLoading: { $set: false },
          dcDataLoading: { $set: false },
          saveSuccess: { $set: false },
          createAllocationSuccess: { $set: false },
          error: { $set: null },
          storeDataError: { $set: false },
          dcDataError: { $set: false },
          saveError: { $set: false },
          createAllocationError: { $set: false },
          createAllocationData: { $set: null },
          allocationCodeError: { $set: false },
          allocationCode: { $set: null },
          allocationCodeSuccess: { $set: false },
          filterlabels: { $set: {} },
          saveDraftData: { $set: {} },
          draftNameError: { $set: false },
          draftNameSuccess: { $set: false},
          reviewAdaError:{$set:false},
          reviewAdaLoading:{$set:false},
          reviewAdaSuccess:{$set:false},
          draftNameSuccess: { $set: false },
          dataForCA: { $set: [] },
          out_of_dataForCA: { $set: false },
          style_indexForCA: { $set: 0 },
          pageIndexForCA: { $set: 0 },
          errorForCA: { $set: null },
          saveDraftLoading: { $set: false }
        });
      },
      
      [REVIEW_ADA]: (state, { payload }) => {
        return immutable(state, {
          reviewAdaError: { $set: false },
          reviewAdaLoading: { $set: true }
        });
      },
      [REVIEW_ADA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          reviewAdaError: { $set: false },
          reviewAdaLoading: { $set: false},
          reviewAdaSuccess:{$set:true}
        });
      },
      [REVIEW_ADA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          reviewAdaError: { $set: true },
          reviewAdaLoading: { $set: false},
          reviewAdaSuccess:{$set:false}
        });
      },

      [RESET_STYLE_INDEX]: (state, { payload }) => {
        return immutable(state, {
          out_of_data: { $set: false },
          style_index: { $set: 0 },
          pageIndex: { $set: 0 },
        });
      },
      [RESET_STYLE_INDEX_FOR_CHECKALL]: (state, { payload }) => {
        return immutable(state, {
          dataForCA: { $set: [] },
          out_of_dataForCA: { $set: false },
          style_indexForCA: { $set: 0 },
          pageIndexForCA: { $set: 0 },
          errorForCA: { $set: null },
        });
      },
      [FETCH_STRATEGY_TABLE_DATA]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: false },
          loading: { $set: true }
        });
      },
      [FETCH_STRATEGY_TABLE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          data: { $set: (payload && payload.data && payload.data.data) || [] },
          storeGroupStoreResult: { $set: (payload && payload.data && payload.data?.storeGroupStoreResult) || {} },
          out_of_data: { $set: (payload && payload.data && payload.data?.out_of_data) || false },
          style_index: { $set: (payload && payload.data && payload.data?.style_index) || 0 },
          pageIndex: { $set: (payload && state.pageIndex + payload?.data?.data?.length) || 0 },
          storeMinStockResult: { $set: (payload && payload.data && payload.data?.storeMinStockResult || []) },
          error: { $set: null },
          loading: { $set: false },
        });
      },
      [FETCH_STRATEGY_TABLE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          data: { $set: [] },
          storeGroupStoreResult: { $set: {} },
          out_of_data: { $set: false },
          style_index: { $set: 0 },
          storeMinStockResult: { $set: [] },
          error: { $set: (payload && payload.error) || null },
          loading: { $set: false },
        });
      },
      [FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL]: (state, { payload }) => {
        return immutable(state, {
          out_of_dataForCA: { $set: false },
          errorForCA: { $set: null },
        });
      },
      [FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          dataForCA: { $set: (payload && payload.data && payload.data.data) || [] },
          out_of_dataForCA: { $set: (payload && payload.data && payload.data?.out_of_data) || false },
          style_indexForCA: { $set: (payload && payload.data && payload.data?.style_index) || 0 },
          pageIndexForCA: { $set: (payload && state.pageIndex + payload?.data?.data?.length) || 0 },
          errorForCA: { $set: null },
        });
      },
      [FETCH_STRATEGY_TABLE_DATA_FOR_CHECKALL_ERROR]: (state, { payload }) => {
        return immutable(state, {
          dataForCA: { $set: [] },
          out_of_dataForCA: { $set: false },
          style_indexForCA: { $set: 0 },
          pageIndexForCA: { $set: 0 },
          errorForCA: { $set: (payload && payload.error) || null },
        });
      },
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        if (payload) {
          switch (payload.key) {
            case "divisions":
              return immutable(state, {
                divisions: {
                  $set: payload.data.divisions ? payload.data.divisions : [],
                },
                departments: [],
                subdepartments: [],
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping,
                },
              });
            case "l10":
              return immutable(state, {
                departments: {
                  $set:
                    payload && payload.data.departments
                      ? payload.data.departments
                      : state.departments,
                },
                filterlabels : {
                  $set: 
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
              });
            case "l11":
            return immutable(state, {
              subdepartments: {
                $set:
                  payload && payload.data.subdepartments
                    ? payload.data.subdepartments
                    : state.subdepartments,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
            case "departments":
              return immutable(state, {
                departments: {
                  $set: payload.data.departments
                    ? payload.data.departments
                    : [],
                },
                subdepartments: {
                  $set: payload.data.subdepartments
                    ? payload.data.subdepartments
                    : [],
                },
                level4 : {
                  $set: payload.data.level4
                    ? payload.data.level4
                    : [],
                },
                level5 : {
                  $set: payload.data.level5
                    ? payload.data.level5
                    : [],
                },
                level6 : {
                  $set: payload.data.level6
                    ? payload.data.level6
                    : [],
                },
                level7 : {
                  $set: payload.data.level7
                    ? payload.data.level7
                    : [],
                },
                storeCode: {
                  $set: payload.data.store_code ? payload.data.store_code : [],
                },
                storeGroup: {
                  $set: payload.data.store_groups ? payload.data.store_groups : [],
                },
                factoryType:{ $set: payload.data.factoryType ? payload.data.factoryType : [], },
                width: { $set: payload.data.width ? payload.data.width : [] },
                // subdepartments: [],
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping,
                },
              });
            case "subdepartments":
              return immutable(state, {
                subdepartments: {
                  $set: payload.data.subdepartments
                    ? payload.data.subdepartments
                    : [],
                },
                level4 : {
                  $set: payload.data.level4
                    ? payload.data.level4
                    : [],
                },
                level5 : {
                  $set: payload.data.level5
                    ? payload.data.level5
                    : [],
                },
                level6 : {
                  $set: payload.data.level6
                    ? payload.data.level6
                    : [],
                },
                level7 : {
                  $set: payload.data.level7
                    ? payload.data.level7
                    : [],
                },
                storeCode: {
                  $set: payload.data.store_code ? payload.data.store_code : [],
                },
                storeGroup: {
                  $set: payload.data.store_groups ? payload.data.store_groups : [],
                },
                factoryType:{ $set: payload.data.factoryType ? payload.data.factoryType : [], },
                width: { $set: payload.data.width ? payload.data.width : [] },
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping,
                },
              });
            case "level4":
              return immutable(state, {
                level4: {
                  $set: payload.data.level4
                    ? payload.data.level4
                    : [],
                },
                level5 : {
                  $set: payload.data.level5
                    ? payload.data.level5
                    : [],
                },
                level6 : {
                  $set: payload.data.level6
                    ? payload.data.level6
                    : [],
                },
                level7 : {
                  $set: payload.data.level7
                    ? payload.data.level7
                    : [],
                },
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping,
                },
                storeCode: {
                  $set: payload.data.store_code ? payload.data.store_code : [],
                },
                storeGroup: {
                  $set: payload.data.store_groups ? payload.data.store_groups : [],
                },
                factoryType:{ $set: payload.data.factoryType ? payload.data.factoryType : [], },
                width: { $set: payload.data.width ? payload.data.width : [] },
              });
              case "level5":
                return immutable(state, {
                  level5 : {
                    $set: payload.data.level5
                      ? payload.data.level5
                      : [],
                  },
                  level6 : {
                    $set: payload.data.level6
                      ? payload.data.level6
                      : [],
                  },
                  level7 : {
                    $set: payload.data.level7
                      ? payload.data.level7
                      : [],
                  },
                  storeCode: {
                    $set: payload.data.store_code ? payload.data.store_code : [],
                  },
                  storeGroup: {
                    $set: payload.data.store_groups ? payload.data.store_groups : [],
                  },
                  factoryType:{ $set: payload.data.factoryType ? payload.data.factoryType : [], },
                  width: { $set: payload.data.width ? payload.data.width : [] },
                  filterlabels: {
                    $set:
                      payload && payload.data.filterMapping
                        ? payload.data.filterMapping
                        : state.filterMapping,
                  },
                });
              case "level6":
                return immutable(state, {
                  level6 : {
                    $set: payload.data.level6
                      ? payload.data.level6
                      : [],
                  },
                  level7 : {
                    $set: payload.data.level7
                      ? payload.data.level7
                      : [],
                  },
                  storeCode: {
                    $set: payload.data.store_code ? payload.data.store_code : [],
                  },
                  storeGroup: {
                    $set: payload.data.store_groups ? payload.data.store_groups : [],
                  },
                  factoryType:{ $set: payload.data.factoryType ? payload.data.factoryType : [], },
                  width: { $set: payload.data.width ? payload.data.width : [] },
                  filterlabels: {
                    $set:
                      payload && payload.data.filterMapping
                        ? payload.data.filterMapping
                        : state.filterMapping,
                  },
                });
              case "level7":
                return immutable(state, {
                  level7 : {
                    $set: payload.data.level7
                      ? payload.data.level7
                      : [],
                  },
                  storeCode: {
                    $set: payload.data.store_code ? payload.data.store_code : [],
                  },
                  storeGroup: {
                    $set: payload.data.store_groups ? payload.data.store_groups : [],
                  },
                  factoryType:{ $set: payload.data.factoryType ? payload.data.factoryType : [], },
                  width: { $set: payload.data.width ? payload.data.width : [] },
                  filterlabels: {
                    $set:
                      payload && payload.data.filterMapping
                        ? payload.data.filterMapping
                        : state.filterMapping,
                  },
                });
                default:
              return immutable(state, {
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping,
                },
              });
          }
        }
      },
      [FETCH_FILTER_ERROR]: (state, { payload }) => {
        return immutable(state, {
          divisions: { $set: [] },
          storeCode: { $set: [] },
          storeGroup: { $set: [] },
          departments: { $set: [] },
          subdepartments: {
            $set: [],
          },
          // error: { $set: (payload && payload.error) || null },
        });
      },
      [FETCH_STRATEGY_TABLE_DROPDOWN_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: null },
          tableDropdowns: {
            $set: (payload && payload.data) || {},
          },
        });
      },
      [FETCH_STRATEGY_TABLE_DROPDOWN_ERROR]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: (payload && payload.error) || null },
          tableDropdowns: { $set: {} },
        });
      },
      [FETCH_SG_MIN_STOCK_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: null },
          minStockData: {
            $set: (payload.data.value && payload.data) || null,
          },
        });
      },
      [FETCH_SG_MIN_STOCK_ERROR]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: (payload && payload.error) || null },
          minStockData: { $set: null },
        });
      },
      [FETCH_STORE_TABLE_DATA]: (state, { payload }) => {
        return immutable(state, {
          storeDataError: { $set: false },
          storeDataLoading: { $set: true },
          storeTableData: { $set: [] },
        });
      },
      [FETCH_STORE_TABLE_DATA_SUCCESS]: (state, { payload }) => {
        // return update(state, {
        //   error: { $set: null },
        //   storeTableData: { $push: (payload && [payload.data]) || null },
        // });
        return immutable(state, {
          storeDataError: { $set: false },
          storeTableData: { $set: (payload && payload.data) || null },
          storeDataLoading: { $set: false }
        });
      },
      [FETCH_STORE_TABLE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          storeDataError: { $set: (payload && payload.error) || false },
          storeTableData: { $set: null },
          storeDataLoading: { $set: false }
        });
      },
      [EDIT_DATA_ADA]: (state, { payload }) => {
        return immutable(state, {
          editDataAdaSucess:{$set: false}
        });
      },
      [EDIT_DATA_ADA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          editDataAdaSucess:{$set: true}
        });
      },
      [EDIT_DATA_ADA_FAILED]: (state, { payload }) => {
        return immutable(state, {
          editDataAdaSucess:{$set: false}
        });
      },
      [FETCH_DC_DATA]: (state, { payload }) => {
        return immutable(state, {
          dcDataError: { $set: false },
          dcDataLoading: { $set: true }
        });
      },
      [FETCH_DC_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          dcDataError: { $set: false },
          dcTableData: { $set: (payload && payload.data) || [] },
          dcDataLoading: { $set: false }
        });
      },
      [FETCH_DC_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          dcDataError: { $set: (payload && payload.error) || false },
          dcTableData: { $set: [] },
          dcDataLoading: { $set: false }
        });
      },
      [UPDATE_RESERVED_QTY]: (state, { payload }) => {
        return immutable(state, {
          saveError: { $set: false },
          saveSuccess: { $set: false },
          dcDataLoading: { $set: true },
        });
      },
      [UPDATE_RESERVED_QTY_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          saveError: { $set: false },
          saveSuccess: { $set: true },
          dcDataLoading: { $set: false },
        });
      },
      [UPDATE_RESERVED_QTY_ERROR]: (state, { payload }) => {
        return immutable(state, {
          saveError: { $set: (payload && payload.error) || false },
          saveSuccess: { $set: false },
          dcDataLoading: { $set: false },
        });
      },
      [CREATE_ALLOCATION]: (state, { payload }) => {
        return immutable(state, {
          createAllocationError: { $set: false },
          storeDataLoading: { $set: true },
          createAllocationSuccess: { $set: false },
        });
      },
      [CREATE_ALLOCATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          createAllocationError: { $set: false },
          createAllocationData: { $set: (payload && payload.data) || null },
          // storeDataLoading: { $set: false },
          createAllocationSuccess: { $set: true },
        });
      },
      [CREATE_ALLOCATION_ERROR]: (state, { payload }) => {
        return immutable(state, {
          createAllocationError: { $set: (payload && payload.error) || false },
          createAllocationData: { $set: null },
          storeDataLoading: { $set: false },
          createAllocationSuccess: { $set: false },
        });
      },
      [SAVE]: (state, { payload }) => {
        return immutable(state, {
          allocationCodeError: { $set: false },
          allocationCode: { $set: null },
          storeDataLoading: { $set: true },
          allocationCodeSuccess: { $set: false },
        });
      },
      [SAVE_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          allocationCodeError: { $set: false },
          allocationCode: { $set: (payload && payload.data) || null },
          storeDataLoading: { $set: false },
          allocationCodeSuccess: { $set: true },
        });
      },
      [SAVE_ERROR]: (state, { payload }) => {
        return immutable(state, {
          allocationCodeError: { $set: (payload && payload.error) || false },
          allocationCode: { $set: null },
          storeDataLoading: { $set: false },
          allocationCodeSuccess: { $set: false },
        });
      },
      [GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: null },
          data: { $set: null },
          loading: { $set: true },
          disableFilters: { $set: true },
        });
      },
      [GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_SUCCESS]: (
        state,
        { payload }
      ) => {
        return immutable(state, {
          data: { $set: (payload && payload.data && payload.data.data) || [] },
          storeGroupStoreResult: { $set: (payload && payload.data && payload.data?.storeGroupStoreResult) || {} },
          out_of_data: { $set: (payload && payload.data && payload.data?.out_of_data) || false },
          style_index: { $set: (payload && payload.data && payload.data?.style_index) || 0 },
          pageIndex: { $set: (payload && state.pageIndex + payload?.data?.data?.length) || 0 },
          storeMinStockResult: { $set: (payload && payload.data && payload.data?.storeMinStockResult || []) },
          error: { $set: null },
          loading: { $set: false },
        });
      },
      [GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD_ERROR]: (state, { payload }) => {
        return immutable(state, {
          data: { $set: [] },
          storeGroupStoreResult: { $set: {} },
          out_of_data: { $set: false },
          style_index: { $set: 0 },
          storeMinStockResult: { $set: [] },
          error: { $set: (payload && payload.error) || null },
          loading: { $set: false },
        });
      },
      [ENABLE_FILTERS]: (state) => {
        return immutable(state, {
          disableFilters: { $set: false },
        });
      },
      [GETSTOREGROUPMINSTOCK_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          changed_min_stock: { $set: (payload && payload.data) },
        });
      },
      [GETSTOREGROUPMINSTOCK_ERROR]: (state, { payload }) => {
        return immutable(state, {
          changed_min_stock: { $set: {} },
        });
      },
      [GET_DRAFTS]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: false },
          loading: { $set: true }
        });
      },
      [GET_DRAFTS_SUCCESS]: (state, { payload }) => {
        console.log(payload)
        return immutable(state, {
          data: { $set: (payload && payload.data && payload.data.data) || [] },
          storeGroupStoreResult: { $set: (payload && payload.data && payload.data?.storeGroupStoreResult) || {} },
          storeMinStockResult: { $set: (payload && payload.data && payload.data?.storeMinStockResult || []) },
          error: { $set: null },
          loading: { $set: false },
        });
      },
      [GET_DRAFTS_ERROR]: (state, { payload }) => {
        return immutable(state, {
          data: { $set: [] },
          storeGroupStoreResult: { $set: {} },
          storeMinStockResult: { $set: [] },
          error: { $set: (payload && payload.error) || null },
          loading: { $set: false },
        });
      },
      [SAVE_DRAFT]: (state, { payload }) => {
        return immutable(state, {
          saveDraftData: { $set: {} },
          saveDraftLoading: { $set: true }
        });
      },
      [SAVE_DRAFT_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          saveDraftData: { $set: (payload && payload.data) || {} },
          saveDraftLoading: { $set: false },
        });
      },
      [SAVE_DRAFT_ERROR]: (state, { payload }) => {
        return immutable(state, {
          saveDraftLoading: { $set: false },
          saveDraftData: { $set: (payload && payload.data) || {} },

        });
      },
      [VALIDATE_PLAN_NAME]: (state, { payload }) => {
        return immutable(state, {
          storeDataLoading: { $set: true },
          draftNameError: { $set: false },
          draftNameSuccess: {$set: false}
        });
      },
      [VALIDATE_PLAN_NAME_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          storeDataLoading: { $set: false },
          draftNameError: { $set: false },
          draftNameSuccess: {$set: true}
        });
      },
      [VALIDATE_PLAN_NAME_ERROR]: (state, { payload }) => {
        return immutable(state, {
          storeDataLoading: { $set: false },
          draftNameError: { $set: payload.error },
          draftNameSuccess: {$set: false}
        });
      },
      [RESET_SAVE_DRAFT_DATA]: (state, { payload }) => {
        return immutable(state, {
          saveDraftData: { $set: {} }
        })
      },
      [SET_LOADER]:  (state, { payload }) => {
        return immutable(state, {
          storeDataLoading: { $set: false }
        })
      },
    },
    strategyTableState
  ),
};

export default reducer;
