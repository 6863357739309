import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import PageLoader from "../../../../components/Loader/PageLoader";
import Notification from "../../../../components/Notification/Notifications";
import DemandConstraintsTable from "./DemandConstraintsTable";
import * as Notify from "../../../../components/Notification/Notifications";
import Filters from "./Filters";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import SetAllModal from "../../../../components/ErrorModal/SetAllModal";
import {
  getFinalCheckedRowsForCAData,
  getNewData,
  removeCheckedArticles,
} from "./DemandPagination";
import {
  getAddProductsData,
  resetAddProductsData,
  resetAddProductsIndex,
} from "../../actions/DemandAction/DemandAction";
import { connect } from "react-redux";

const AddProducts = (props) => {
  const {
    resetAddProductsData,
    getAddProducts,
    data,
    dataLoading,
    dataError,
    closeModal,
    sisterStores,
    resetAddProductsIndex,
    showModal,
    addProducts,
    store,
    sisterStore,
    dcSelected,
    sisterStoresL1Mapping,
    setMoveProducts,
    existingProducts
  } = props;

  const [RTInstance, setRTInstance] = useState({});
  const [showStoreSelection, setShowStorePopup] = useState(false);
  const [refStore, setRefStore] = useState();
  const [addProductsData, setAddProductsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [searchTermReq, setSearchTermReq] = useState({});
  const [sortReq, setSortReq] = useState({});
  // const [RTinstance, setRTinstance] = useState({})
  const [isFirstCall, setisFirstCall] = useState(true);
  const [prevAction, setPrevAction] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkAll, setCheckAll] = useState([]);
  const [callCheckAllApi, setCallCheckAllApi] = useState(true);
  const [selectedFilters, setFilters] = useState({});
  const [fetchCount, setFetchCount] = useState(0);
  const [checkAllData, setCheckAllData] = useState([]);
  const [isGetAllLoading, setIsGetAllLoading] = useState(false);
  const [prevCheckAllCall, setPrevCheckAllCall] = useState(false);
  const [checkAllReqArr, setCheckAllReqArr] = useState([]);

  useEffect(() => {
    // setTableData(data);
    if (data?.length > 0 && !isGetAllLoading) {
      if (RTInstance && RTInstance.data) {
        let l_newData = getNewData({
          p_newData: data,
          p_checkAll: checkAll,
        });
        setSelectedRows(l_newData?.finalCheckedRows);
        // let movedRows = movedProducts?.map((item) => item.article);
        // let dataforTable = [
        //   ...RTInstance.data,
        //   ...data
        //   // ...data.filter((item) => !movedRows?.includes(item.article)),
        // ];
        setTableData(data);
        // movedProducts?.length && props.setTablePageIndex(dataforTable?.length);
        // setDemandTableData();
        // }
      } else {
        // if (!isStoreChanged && data?.length) {
        //   setDemandTableData();
        // } else {
          setTableData([...data]);
        // }
      }
    }

    if (isFilterClicked) {
      setInitialIndex(0);
      setPrevIndex(0);
      setIsFilterClicked(false);
    }
  }, [data]);

  const handleAddProducts = (data, tableData) => {
    let flag = true;
    // if (RTInstance?.getSelectedRows?.length) {
    if(data?.length) {
      data?.forEach((row) => {
        if (!row?.sister_store_ref) {
          flag = false;
        }
      });
      if (flag) {
        data = data?.filter(item => !existingProducts?.includes(item.article))
        setMoveProducts(data);
        setTableData(tableData)
      } else {
        Notify.error(
          `Please select reference store for all the selected products!!`
        );
      }
    } else {
      Notify.error(`Please select atleast 1 product!!`);
    }
  };

  const applyFilters = (filters) => {
    setFilters(filters);
    resetState();
    getAddProducts({
      sister_stores: sisterStoresL1Mapping,
      dc: dcSelected,
      styleIndex: 0,
      ...filters,
    }, true);
  };

  useEffect(() => {
    return () => {
      resetAddProductsData();
    };
  }, []);

  const onSisterStoreChange = (val) => {
    setRefStore(val);
  };

  const sisterStorePrep = [
    {
      id: 1,
      isRequired: true,
      label: "Sistor Store",
      name: "sisterStore",
      className: "setAll__filter",
      onChange: onSisterStoreChange,
      placeHolder: `Select Store eligibility Group`,
      options: sisterStores.filter(
        (store) =>
          !Object.values(sisterStoresL1Mapping)
            .join()
            .split(",")
            .includes(store.label.split("-")[0])
      ),
      isMultiSelect: false,
      hideSelectAll: true,
    },
  ];

  const handleSetStoreOk = () => {
    let setAllRows = Object.keys(RTInstance?.state?.selectedRowIds);
    showStoreSelection && setShowStorePopup(false);
    let selectedRows = {};
    RTInstance?.getSelectedRows?.map((item) => (selectedRows[item] = true));
    setSelectedRows(selectedRows);
    setTableData((oldData) => {
      let newData = cloneDeep(oldData);
      setAllRows.forEach((setAllRow) => {
        newData[setAllRow]["sister_store_ref"] = refStore.value;
        newData[setAllRow]["sister_store_codes"] = [
          refStore.label.split("-")[0],
        ];
      });
      return newData;
    });
  };

  const handleSelectStoreClick = () => {
    if (RTInstance?.getSelectedRows?.length) {
      setShowStorePopup(true);
    } else {
      Notify.error(
        `Please select atleast 1 product to use Set All functionality!!`
      );
    }
  };

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if (split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",")
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        var id = p_term.split(split_type ? split_type : " ")
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {

          l_searchTermReq[p_id] = {
            "type": p_type,
            "values": id,
            "search": p_term
          }
        }
        return {
          ...l_searchTermReq
        }
      })
    }
    else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        let min = p_term[0] == "" ? null : p_term[0]
        let max = p_term[1] == "" ? null : p_term[1]
        if (min == null && max == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {
          l_searchTermReq[p_id] = {
            "type": p_type,
            "value": {
              "min": min,
              "max": max,
            }
          }
        }
        return {
          ...l_searchTermReq,
        }
      })
    }
    else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {
          l_searchTermReq[p_id] = {
            "type": p_type,
            "value": p_term
          }
        }
        return {
          ...l_searchTermReq,
        }
      })
    }
    isFirstCall && setisFirstCall(false)
    // setIsDisabled((prev) => {
    //   if (!prev) {
    //     return true;
    //   }
    // });
}

  const fecthDataWithSorting = (p_sort) => {
    if (!isEmpty(sortReq)) isFirstCall && setisFirstCall(false);
    setSortReq(p_sort[0]);
  };

  const fetchData = (index) => {
    if (
      prevIndex >= index ||
      index < pageIndex ||
      props?.out_of_data ||
      dataLoading
    ) {
      // if (props?.out_of_data || fetchCount || dataLoading) {
      return;
      // }
    }

    // retainCheckboxState()
    // setFetchCount(1)
    setPrevIndex(index);
    setInitialIndex(Math.floor(pageIndex / 10));
    // getDemandData({
    //     "sister_stores": sisterStoresL1Mapping,
    //     "dc": dcSourceSelected,
    //     // "sister_store_codes": sisterStoresL1Mapping[Object.keys(sisterStoresL1Mapping)],
    //     ...selectedFilters,
    //     "styleIndex": props.styleIndex,
    //     "searchColumns": searchTermReq,
    //     "sortColumn": sortReq,
    // })
    getAddProducts({
      styleIndex: props.styleIndex,
      searchColumns: searchTermReq,
      sortColumn :sortReq,
      sister_stores: sisterStoresL1Mapping,
      dc: dcSelected,
      ...selectedFilters,
    });
  };

  const updateSetAll = (value) => {
    let l_newAction = {
      searchTermReq: searchTermReq,
    };
    if (!value.checked) {
      setPrevAction("checkAll");
      l_newAction["checkAll"] = true;
    } else {
      setPrevAction("unCheckAll");
      l_newAction["unCheckAll"] = true;
      let l_updatedCheckedRows = removeCheckedArticles(
        checkedRows,
        searchTermReq
      );
      setCheckedRows(l_updatedCheckedRows);
    }
    setCheckAll((old) => [...old, l_newAction]);
  };

  const updateCheckedRows = (value) => {
    let l_newAction = {},
      l_article = value.instance.article;

    if (!value.checked) {
      setPrevAction("checkRow");
      if (prevAction === "checkRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy?.[l_lastValueIndex];
        l_checekdRows["checkedRows"].push(l_article);
        checkAllCopy.pop();
        setCheckAll([...checkAllCopy, l_checekdRows]);
      } else {
        l_newAction["checkedRows"] = [l_article];
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = { [l_article]: value.instance };
      setCheckedRows((old) => {
        return {
          ...old,
          ...l_checkedRow,
        };
      });
    } else {
      setPrevAction("unCheckRow");
      if (prevAction === "unCheckRow") {
        let checkAllCopy = [...checkAll];
        let l_lastValueIndex =
          checkAllCopy.length - 1 === -1 ? 0 : checkAllCopy.length - 1;
        let l_checekdRows = checkAllCopy[l_lastValueIndex];
        l_checekdRows["unCheckedRows"].push(l_article);
        checkAllCopy.pop();
        setCheckAll([...checkAllCopy, l_checekdRows]);
      } else {
        l_newAction["unCheckedRows"] = [l_article];
        setCheckAll((old) => [...old, l_newAction]);
      }

      let l_checkedRow = cloneDeep(checkedRows);
      delete l_checkedRow[l_article];
      setCheckedRows((old) => {
        return {
          ...l_checkedRow,
        };
      });
    }
  };

  useEffect(() => {
    setPageIndex(props.pageIndex);
    // setFetchCount(0)
  }, [props.pageIndex]);

  useEffect(() => {
    if (RTInstance) {
      //   let l_tableData = cloneDeep(tableInstance)
      //   let uniqUpdatedArticles = [...new Set(updatedArticles)];
      //   let l_updatedRows = l_tableData?.data?.filter(val => uniqUpdatedArticles.includes(val.article)) || []
      //   let l_finalUpdatedRows = getRecentUpdatesOnArticle(updatedRows,l_updatedRows)
      //   setUpdatedRows(l_finalUpdatedRows)
      //   if(userEditAfterValidationOnRest){
      //     let uniqUpdatedArticles = [...new Set(updatedArticlesForRest)];
      //     let l_updatedRows = l_tableData?.data?.filter(val => uniqUpdatedArticles.includes(val.article)) || []
      //     let l_finalUpdatedRows = getRecentUpdatesOnArticle(updatedRowsForRest,l_updatedRows)
      //     setUpdatedRowsForRest(l_finalUpdatedRows)
      //   }
      if (!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) {
        resetAddProductsIndex();
        callCheckAllApi && setCallCheckAllApi(false);
        setTableData([]);
        setRTInstance(null);
        setInitialIndex(0);
        setPrevIndex(0);
        setPageIndex(0);
        // setInitialSelectedRows({})
        // getDemandData({
        //     ...selectedFilters, "sister_stores": sisterStoresL1Mapping, "dc": dcSourceSelected,
        //     "styleIndex": 0, "searchColumns": searchTermReq, "sortColumn": sortReq
        // });
        getAddProducts({
          sister_stores: sisterStoresL1Mapping,
          dc: dcSelected,
          styleIndex: 0,
          searchColumns: searchTermReq,
          sortColumn: sortReq,
          ...selectedFilters,
          // "styleIndex": (props.checkAllStyleIndex || props.styleIndex),
        }, true);
      }
    }
  }, [searchTermReq, sortReq]);

  const resetState = () => {
    resetAddProductsIndex();
    setRTInstance({});
    setPrevIndex(0);
    setPageIndex(0);
    setInitialIndex(0);
    setIsFilterClicked(true);
    // setCheckAllData([])
    // setMoveProducts([])
    // setTableData([])
    // setPrevCheckAllCall(false)
    setSearchTermReq({});
    setSortReq({});
    setisFirstCall(true);
    setCheckAll([]);
  };

  const moveSelectedProducts = (products, setAll) => {
    let l_checkedUnchecked = checkAll.filter(
      (val) => val.checkAll || val.unCheckAll
    );
    if (!products && l_checkedUnchecked?.length && !prevCheckAllCall) {
      let l_checkedUnchecked = checkAll.filter(
        (val) => val.checkAll || val.unCheckAll
      );
      let l_checkAll = [];
      for (let i = 0; i < l_checkedUnchecked.length; i++) {
        if (l_checkedUnchecked[i].checkAll) {
          l_checkAll.push(l_checkedUnchecked[i]);
        }
        if (l_checkedUnchecked[i].unCheckAll) {
          l_checkAll = [];
        }
      }

      let checked = l_checkAll.filter((val) => val.checkAll);
      let unchecked = l_checkAll.filter((val) => val.unCheckAll);
      for (let i = 0; i < checked.length; i++) {
        for (let j = 0; j < unchecked.length; j++) {
          if (isEqual(checked[i].searchTermReq, unchecked[j].searchTermReq)) {
            checked.splice(i, 1);
          }
        }
      }
      setCheckAllReqArr(checked);
      // setCheckAllData(tableData)
      setIsGetAllLoading(true);
      // setTableData([])
      getCheckAllData(checked);
    } else {
      setPrevAction("");
      setIsGetAllLoading(false);
      setCheckAll([]);
      // let productsToAdd = products?.length ? products : demandSelectedRows
      let productsToAdd = products?.length
        ? products
        : RTInstance?.selectedFlatRows?.map((item) => item.original);
      let articles = productsToAdd?.map((item) => item.article);
      productsToAdd?.forEach(item => {
        item.sister_store_ref = item?.sister_store_ref ? item.sister_store_ref : tableData.filter((row) => row.article === item.article)[0]?.sister_store_ref;
      })
      let nonSelectedRows = tableData.filter(
        (row) => !articles.includes(row.article)
      );
      // setTableData(nonSelectedRows);
      if (setAll) {
        // setMoveProducts(productsToAdd);
        handleAddProducts(productsToAdd, nonSelectedRows)
      } else {
        // let all = [...productsToAdd, ...movedProducts]
        let allObj = {};
        productsToAdd?.forEach((item) => {
          allObj[item.article] = item;
        });
        // setMoveProducts(Object.values(allObj));
        handleAddProducts(Object.values(allObj), nonSelectedRows)
        // props?.setTablePageIndex({ pageIndex: nonSelectedRows?.length });
        setInitialIndex(0);
      }
    }
  };

  const getCheckAllData = (p_checkAllReq, data) => {
    if (!isEmpty(p_checkAllReq)) {
      let l_checkAllRequest = p_checkAllReq[0];
      if (
        l_checkAllRequest?.checkAll &&
        isEmpty(l_checkAllRequest?.searchTermReq)
      ) {
        setPrevCheckAllCall(true);
      }
      getAddProducts({
        sister_stores: sisterStoresL1Mapping,
        dc: dcSelected,
        styleIndex: props.checkAllStyleIndex,
        ...selectedFilters,
        searchColumns: l_checkAllRequest?.searchTermReq,
        sortColumn: sortReq,
        checkAllCall: true,
      });
    } else {
      moveSelectedProducts(data?.length ? data : checkAllData);
      setPrevAction("");
      setIsGetAllLoading(false);
    }
  };

  useEffect(() => {
    if (props?.checkAllData?.length) {
      let l_finalCheckedArticles = getFinalCheckedRowsForCAData(
        checkAll,
        props.checkAllData
      );
      // let data = cloneDeep(checkAllData).concat(props?.checkAllData)
      let data = cloneDeep(checkAllData).concat(
        Object.values(l_finalCheckedArticles)
      );
      props?.checkAllData?.length && setCheckAllData(data);
      if (!props.outOfDataCA && isGetAllLoading) {
        getCheckAllData(checkAllReqArr);
      } else if (props?.outOfDataCA) {
        let checkAllArr = [...checkAllReqArr];
        checkAllArr.shift();
        // setCheckAll(checkAllArr)
        setCheckAllReqArr(checkAllArr);
        getCheckAllData(checkAllArr, data);
      } else if (isGetAllLoading) {
        setPrevAction("");
      }
    } else if (props?.outOfDataCA) {
      let checkAllArr = [...checkAllReqArr];
      checkAllArr.shift();
      setCheckAllReqArr(checkAllArr);
      getCheckAllData(checkAllArr);
    }
  }, [props?.checkAllData, props.out_of_data]);

  const closePopUp = () => {
    setTableData([])
    closeModal()
  }

  return (
    <div className="modal__div" style={{ zIndex: 1000 }}>
      {showStoreSelection && (
        <SetAllModal
          filter={sisterStorePrep}
          heading={"Select Sistor Store"}
          modalBodyStyle={{ height: "33rem" }}
          handleOk={handleSetStoreOk}
          handleCancel={() => {
            setShowStorePopup(false);
          }}
          handleClose={() => {
            setShowStorePopup(false);
          }}
        />
      )}
      <Modal
        visible={showModal}
        width="80%"
        effect="fadeInDown"
        onClickAway={closePopUp}
      >
        {/* <Notification /> */}
        <div className="modal-container">
          <div className="modal-header">
            <h5 className="page-heading text-center m-0">Add Products</h5>
            <button type="button" className="close" data-dismiss="modal">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times text-right m-2 "
                  aria-hidden="true"
                  onClick={closePopUp}
                />
              </span>
            </button>
          </div>
          <div className="modal-middle-container modal-body">
            <Filters
              handleFilterRequest={applyFilters}
              l1_name={props?.l1_name}
              selectedDepartments={props?.selectedDepartments}
            />

            <PageLoader loader={dataLoading} gridLoader={true}>
              {dataError ? (
                <div className="error">{dataError}</div>
              ) : data ? (
                <div className="mx-2 p-2">
                  <DemandConstraintsTable
                    data={tableData ? tableData : []}
                    isAddProduct={true}
                    setRTInstance={setRTInstance}
                    addProSelectedRows={selectedRows}
                    paginateTable
                    fetchData={fetchData}
                    updateSetAll={(val) => updateSetAll(val)}
                    updateCheckedRows={(val) => updateCheckedRows(val)}
                    fecthDataWithSearchTerm={fecthDataWithSearchTerm}
                    fecthDataWithSorting={fecthDataWithSorting}
                    searchTermReq={searchTermReq}
                    out_of_data={props.out_of_data}
                    initialPageIndex={initialIndex > 0 ? initialIndex - 1 : initialIndex}
                    existingProducts={existingProducts}
                  />
                </div>
              ) : null}
            </PageLoader>
          </div>

          <div className="text-center" style={{ padding: "1rem" }}>
            <div className="center">
              <button
                className="btn btn-primary store-grp-modal__btn"
                onClick={closePopUp}
              >
                Close
              </button>
              <button
                className="btn btn-primary fn__btn mr-4"
                onClick={handleSelectStoreClick}
              >
                Select Sister Store
              </button>
              <button
                className="btn btn-primary fn__btn"
                // onClick={handleAddProducts}
                onClick={() => {
                  moveSelectedProducts(false);
                }}
              >
                Add Products
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (newStoreSetup) => {
  const { demandConstraints } = newStoreSetup;
  return {
    addProductsDataLoading: demandConstraints.addProductsDataLoading,
    addProductsData: demandConstraints.addProductsData,
    addProductsDataError: demandConstraints.addProductsDataError,
    styleIndex: demandConstraints?.addStyle_index,
    checkAllStyleIndex: demandConstraints?.addCheckAllStyleIndex,
    pageIndex: demandConstraints?.addPageIndex,
    out_of_data: demandConstraints?.addOut_of_data,
    checkAllData: demandConstraints?.addCheckAllData,
    addDataPageIndex: demandConstraints?.addDataPageIndex,
    outOfDataCA: demandConstraints?.addOutOfDataCA,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAddProductsData: (payload) => dispatch(getAddProductsData(payload)),
  resetAddProductsData: () => dispatch(resetAddProductsData()),
  resetAddProductsIndex: () => dispatch(resetAddProductsIndex()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProducts);
