import React, { useEffect, useState } from "react";
import "../Reporting.css";
import { connect } from "react-redux";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import PageLoader from '../../../components/Loader/PageLoader';
// import Filters from "./Filters"
import { getExcessInvData } from "./Actions/ExcessAction"
import { isEmpty, cloneDeep } from "lodash";
import ColumnFilterServerSide from "../../../components/Filters/ColumnFilterServerSide";
import NumberRangeColumnFilterServerSide from "../../../components/Filters/NumberRangeColumnFilterServerSide";
import { addDollar, numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import { EXCESS_INV_COLUMNS_FORMATTING } from "../../../components/ExportFile/ExportExcelConstants";
import WeekFilter from "../LostSalesReport/WeekFilter";
import { getExcessTableData } from "../../../routes/api";
import ParallelExcelDownload from "../../../components/ParallelExcelDownload/ParallelExcelDownload";
import SwitchInput from "../../../components/SwitchInput/SwitchInput";

const pageSize = 10
const rowCount = 100

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ExcessTable = (props) => {

    const { quarters } = props

    const [totalDetails, setTotalDetail] = useState({ units: 0, cost: 0 })
    const [isFilterSelected, setIsFilterSelected] = useState(false)
    const [filters, setFilters] = useState({})
    const [excessData, setExcessData] = useState([])
    const [RTinstance, setRTinstance] = useState([])
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [isFirstCall, setisFirstCall] = useState(true)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [isQuarterView, setIsQuarterView] = useState(false);
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([])
    const [isShowTable, setIsShowTable] = useState(true);

    const columns = [
        {
            Header: "Fiscal Year",
            accessor: "fiscal_year",
            disableFilters: true,
            sticky: "left",
            width: 100
        },
        {
            sticky: "left",
            Header: "Fiscal Quarter",
            accessor: "quarter",
            disableFilters: true,
            width: 120,
          },
        {
            Header: "Fiscal Week",
            accessor: "fiscal_week",
            disableFilters: true,
            sticky: "left",
            width: 120
        },
        {
            Header: "Store Number",
            accessor: "store_code",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            sticky: "left"
        },
        {
            Header: "Store Name",
            accessor: "store_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.store_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 250,
            sticky: "left"
        },
        {
            Header: `${updateArticleName(false)} ID`,
            accessor: "article",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article?.search} placeholder={"Search in bulk by ','"} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
            ),
            sticky: "left",
            filter: 'bulkFilterCommaSeperated',
        },
        {
            sticky: "left",
            Header: "Size",
            accessor: "size",
            Filter: (instance) => (
              <ColumnFilterServerSide
                {...instance}
                searchTermValue={searchTermReq?.size?.value}
                changeHandler={(term, id) =>
                  fecthDataWithSearchTerm(term, id, "string")
                }
              />
            ),
            width: 100,
        },
        {
            Header: "Style ID",
            accessor: 'style_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 100
        },
        {
            Header: "Color ID",
            accessor: 'color_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 100
        },
        {
            Header: "Style Description",
            accessor: "style_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 300
        },
        {
            Header: "Style Color",
            accessor: "color_desc",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            width: 200
        },
        {
            Header: "Color",
            accessor: "color",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),

        },
        {
            Header: "Inventory Closing Balance",
            accessor: "inv",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.inv?.value?.min} maxSearchTermValue={searchTermReq?.inv?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "ROS",
            accessor: "ros",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.ros?.value?.min} maxSearchTermValue={searchTermReq?.ros?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Units Sold",
            accessor: "units",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.units?.value?.min} maxSearchTermValue={searchTermReq?.units?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "WOS",
            accessor: "wos",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.wos?.value?.min} maxSearchTermValue={searchTermReq?.wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "WOS Threshold",
            accessor: "target_wos",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.target_wos?.value?.min} maxSearchTermValue={searchTermReq?.target_wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Excess Inventory (Units)",
            accessor: "excess_inv",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.excess_inv?.value?.min} maxSearchTermValue={searchTermReq?.excess_inv?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            filter: 'between',
        },
        {
            Header: "Excess Inventory (Cost)",
            accessor: "excess_inv_cost",
            Filter: (instance) => (
                <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.excess_inv_cost?.value?.min} maxSearchTermValue={searchTermReq?.excess_inv_cost?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
            ),
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? addDollar(numberUSFormatting(inst.value))
                        : null}
                </div>),
            filter: 'between',
        },
    ]

    const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
        if (p_type === "array") {
            if(split_type) {
              p_term = p_term.replace(/\s*,\s*/g, ",")
            }
            setSearchTermReq((old) => {
              let l_searchTermReq = cloneDeep(old)
              var id = p_term.split(split_type ? split_type : " ")
              if (p_term?.trim() == 0) {
                l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
              }
              else {
      
                l_searchTermReq[p_id] = {
                  "type": p_type,
                  "values": id,
                  "search": p_term
                }
              }
              return {
                ...l_searchTermReq
              }
            })
          }
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)
    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq))
            isFirstCall && setisFirstCall(false)
        setSortReq(p_sort[0])
    }

    useEffect(() => {
        if ((!isEmpty(searchTermReq) || !isEmpty(sortReq) || !isFirstCall) && !isEmpty(filters)) {
            setExcessData([])
            setRTinstance(null)
            setInitialIndex(0)
            setPrevIndex(0)
            props.getExcessInvData({ ...filters, row_index: 0, row_count: rowCount, searchColumns: searchTermReq, sortColumn: Array.isArray(sortReq) ? sortReq?.[0] : sortReq })
        }
    }, [searchTermReq, sortReq])


    useEffect(() => {
        // if(selectedWeek) {
        //     // setRTinstance(null)
        //     setExcessData(props.tableData)
        // }
        if (props.tableData?.length > 0) {
            let data = []
            if (RTinstance && RTinstance.data) {
                data = [...RTinstance.data, ...props.tableData]
                setExcessData([...RTinstance.data, ...props.tableData]);
            }
            else {
                data = [...props.tableData]
                setExcessData([...props.tableData]);
            }
            // calcTotal(data)
        }
        else {
            setExcessData([])
            setRTinstance(null)
        }

    }, [props.tableData])

    useEffect(() => {
        if (quarters.length && isFilterSelected) {
            // setFiscalWeekOptions(fiscalWeek);
            getExcessData([quarters[0]?.year], [quarters[0]?.quarter], quarters[0]?.weeks)
        }
    }, [quarters])

    useEffect(() => {
        if (!isEmpty(props.sum)) {
            const { inv_cost_sum, inv_sum } = props.sum
            setTotalDetail({ units: inv_sum, cost: inv_cost_sum })
        }
    }, [props.sum])

    const handleOnChange = (year, quarter, week) => {
        setSearchTermReq({})
        setExcessData([])
        setRTinstance(null)
        setInitialIndex(0)
        setPrevIndex(0)
        setTotalCount(0);
        setNextIndex(0);
        // resetExcelDetails();
        setIsFilterSelected(false)
        let req = {
            ...filters, 
            year: [year], 
            quarter: [quarter], 
            week,
            screen_type : isQuarterView ? "Quarter": "Week",
            sortColumn: { id: isQuarterView ? "quarter" : 'fiscal_week', desc: true }
        }
        setFilters(req)
        // props.getExcessInvData({ ...req, row_index: 0, row_count: rowCount, sortColumn: { id: isQuarterView ? "quarter" : 'fiscal_week', desc: true } })
    }

    useEffect(() => {
        setIsFilterSelected(true)
    }, [props.filters])

    const getExcessData = (year, quarter, week) => {
        if (!isEmpty(props.filters) && props.call && week?.length) {
            let req = { ...props.filters, year, quarter, week }
            setSearchTermReq({})
            setFilters(req)
            setPrevIndex(0)
            setInitialIndex(0)
            setRTinstance([])
            setExcessData([])
            props.getExcessInvData({ ...req, row_index: 0, row_count: 100, "sortColumn":{"id": isQuarterView ? "quarter" : 'fiscal_week', "desc": true} })

            setisFirstCall(true)
        }
    }

    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
            return;
        // if (prevIndex >= index || index < nextIndex || nextIndex >= totalCount)
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / pageSize - 1))
        props.getExcessInvData({ ...filters, row_index: nextIndex, row_count: rowCount, searchColumns: searchTermReq, sortColumn: Array.isArray(sortReq) ? sortReq?.[0] : sortReq })
    }


    useEffect(() => {
        setTotalCount(props.totalCount)
        setNextIndex(props.nextIndex)
    }, [props.totalCount, props.nextIndex])

    const formatExcelData = (downloadData) => {
        let data = []
        let obj = {}
        downloadData.forEach(item => {
            obj = {}
            obj["Store Number"] = item.store_code
            obj["Store Name"] = item.store_name
            !isQuarterView && (obj["Fiscal Week"] = item.fiscal_week)
            obj["Fiscal Quarter"] = item.quarter
            obj["Fiscal Year"] = item.fiscal_year
            obj[`${updateArticleName(false)} ID`] = item.article
            obj["Style ID"] = item.style_code
            obj["Color ID"] = item.color_code
            obj["Style Description"] = item.style_name
            obj["Style Color"] = item.color_desc
            obj["Color"] = item.color
            obj["Size"] = item.size
            obj["Inventory Closing Balance"] = item.inv
            obj["ROS"] = item.ros
            obj["Units Sold"] = item.units
            obj["WOS"] = item.wos
            obj["WOS Threshold"] = item.target_wos
            obj["Excess Inventory (Units)"] = item.excess_inv
            obj["Excess Inventory (Cost)"] = item.excess_inv_cost
            data.push(obj)
        })
        return data;
    }

    const toggleQuarterView = (status) => {
      setIsShowTable(false)
      setIsQuarterView(status);
      let request = { 
          ...filters,
          row_index: 0,
          sortColumn: {id: status ? "quarter" : "fiscal_week", desc: true},
          screen_type: status ? "Quarter" : "Week",
      };
      setInitialHiddenColumns(status ? ["fiscal_week"] : []);
      setFilters(request)
    //   props.getExcessInvData({
    //     ...request,
    //     row_index: 0,
    //     sortColumn: {id: status ? "quarter" : "fiscal_week", desc: true},
    //     screen_type: status ? "Quarter" : "Week",
    //   });
      setTimeout(() => {
        setIsShowTable(true)
      }, 100)
    };


    return (
        <PageLoader loader={props.tableDataLoading || props.graphLoading}>
            {/* <DownloadProgressBar nextIndex={excelData?.length} totalCount={totalCountExcel || totalCount} showModal={downloadExcelLoading}
                closeModal={closeDownloadModal} /> */}
            {
                props.tableDataSuccess ?
                    <div>
                        <div className="d-flex justify-content-between">
                            <span className="detail__container">
                                <h5 className="detail__heading">Total Excess Inv Units: {numberUSFormatting(totalDetails?.units)}</h5>
                                <h5 className="detail__heading">Total Excess Inv Cost: $ {numberUSFormatting(totalDetails?.cost?.toFixed(0))}</h5>
                            </span>
                            <SwitchInput label={"Aggregate by quarter"} onChangeHandler={toggleQuarterView} />
                        </div>

                        <ParallelExcelDownload 
                            apiURL={getExcessTableData} 
                            filters={filters} 
                            formatExcelData={formatExcelData}
                            existingData = {excessData}
                            excelColumnsToBeFormatted={EXCESS_INV_COLUMNS_FORMATTING}
                            searchTermReq={searchTermReq}
                            sortReq={sortReq}
                            totalCount={totalCount}
                            nextIndex={nextIndex}
                            fileName={`excess_inv_report_${new Date().getTime()}`}
                            excelFormatColumnIndex={{hideColumnIndex: isQuarterView ? 2 : 0, diff: isQuarterView ? -1 : 0}}
                        />
                        <div className="mr-4 row ml-0">
                                {/* <ExcessWeekFilter latestWeek={latestWeek} fiscalWeekOptions={fiscalWeekOptions} handleWeekSelect={handleWeekSelect} /> */}
                                <WeekFilter 
                                    showWeekFilter={!isQuarterView}
                                    quarters={quarters}
                                    handleOnChange={handleOnChange}
                                />
                                
                        </div>
                        {isShowTable &&
                        <ReactTableWithPlugins
                            shouldPagination
                            hideDropdown
                            totalRecordsLen={excessData?.length}
                            totalCount={totalCount}
                            data={excessData ? excessData : []}
                            columns={columns}
                            renderMarkup='TableMarkup'
                            keyRT="sortAndSearch"
                            fetchData={(data) => fetchData(data)}
                            initialPageIndex={initialIndex}
                            pageSize={pageSize}
                            getInstanceOnMount={(instance) => {
                                setRTinstance(instance)
                            }}
                            getManualSortByOptions={(instance) => {
                                fecthDataWithSorting(instance)
                            }}
                            manualSortBy={true}
                            tableId="excess_inv"
                            sortBy={[{ id: isQuarterView ? "quarter" : 'fiscal_week', desc: true }]}
                            initialHiddenColumns={initialHiddenColumns}
                        />}
                    </div>
                    : (props.tableDataError ? <div className="error">{props.tableDataError}</div> : null)

            }
        </PageLoader>
    )
}

const mapStateToProps = ({ excessReport }) => {
    return {
        tableData: excessReport.excessTableData,
        tableDataLoading: excessReport.tableDataLoading,
        graphLoading: excessReport.graphDataLoading,
        tableDataError: excessReport.tableDataError,
        tableDataSuccess: excessReport.tableDataSuccess,
        nextIndex: excessReport.nextIndex,
        weeks: excessReport.weeks,
        totalCount: excessReport.totalCount,
        sum: excessReport.sum,
        yearData: excessReport.yearData,
        quarters: excessReport.quarters
    }
};

const mapDispatchToProps = (dispatch) => ({
    getExcessInvData: (payload) => dispatch(getExcessInvData(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExcessTable);
