// Library Components
import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// CSS
import "./SidebarComponent.scss";

// Constants
import { DEMO, STRATEGY_PAGE, STORE_PAGE, CONSTRAINTS_PAGE, PROFILE_PAGE, DASHBOARD_PAGE, USER_MANAGEMENT_PAGE, VIEW_ALLOCATION_PAGE, REPORTING_PAGE, ADA_VISUAL_PAGE, PRODUCT_STORE_STATUS, RAISE_TICKET } from "../../constants/routeConstants";
// import calendar from "../../img/calendar.svg";
import dashboard from "../../img/Dashboard.png"
import store from "../../img/Store eligibility.png"
import Constraints from "../../img/Constraints.png"
import Configuration from "../../img/Configuration.png"
import Reporting from "../../img/Reporting.png";
import Ada from "../../img/Ada.svg";
import product from "../../img/Product Profile.png"
import createAllocation from "../../img/Create allocation.png"
import viewAllocation from "../../img/View allocation.png"
import userManagement from "../../img/User management.png"
import { hideToProd, isHideIcon, raiseTicketLink, reloadPageWithUpdateToken } from "../../utils/commonUtilities";

// import { setGAPageView, setGAEvent } from '../../utils/GoogleAnalytics/googleAnalytics'
const isLocalNode = function (node, target) {
  // In Internet Explorer (IE), Node.contains() only works on Element nodes,
  // not Text nodes. As such, let's travel up to the nearest Element node.
  // --
  // NOTE: This doesn't necessarily apply to this demo, which only reacts to
  // Element node interactions. However, I'm adding it here as a mental note.
  do {
    if (node === target) {
      return true;
    }

    target = target.parentNode;
  } while (target);

  return false;
};

const findUpTag = function (el, tag) {
  while (el.parentNode) {
    el = el.parentNode;
    if (el === tag) return el;
  }
  return null;
};

class SidebarComponent extends React.Component {
  state = {
    class: "",
    display: "",
    disabledPromoCal: false,
    disableCreatePromo: false,
    disableStoreConfig: false,
    groupId: null
  };

  // refreshPage = () => {
  //   setTimeout(() => {
  //     window.location.reload()
  //   }, 1000)
  // }

  toggleSideBarExpansionHandler = (expand, no_reload) => {
    // setGAPageView(window.location.pathname)
    // setGAEvent("Icon", "Navigate to page")
    !no_reload && reloadPageWithUpdateToken()
    if (expand === true || this.state.class !== "active") {
      this.setState({
        class: "active",
        display: "inline-block",
      });
      return;
    }
    if (this.state.class === "active") {
      this.setState({
        class: "",
        display: "",
      });
    }
  };
  onLogoutClick() {
    this.props.history.push("/login");
  }

  handleResize = (event) => {
    setTimeout(() => {
      if (this.wrapperRef && !isLocalNode(this.wrapperRef, event.target)) {
        this.toggleSideBarExpansionHandler();
      }
    }, 300);
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  onLeaveHover = () => {
    this.setState({
      class: "",
      display: "",
    });
  };

  handleClickOutside = (event) => {
    if (!findUpTag(event.target, this.wrapperRef)) {
      this.onLeaveHover();
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    let groupId = localStorage.getItem('group_id');
    this.setState({
      groupId: groupId
    })
  }

  componentDidUpdate() {
    if(!this.state.groupId){
      let groupId = localStorage.getItem('group_id');
      this.setState({
        groupId: groupId
      })
    }
  }

  // Used for setting up permissions for specific pages
  // componentWillReceiveProps(nextProps) {
  // }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    let pathname = this.props.location.pathname;
    // console.log("userRole",this.props.userRole, isSuperUser);

    return (
      <nav id="sidebar" className={this.state.class} ref={this.setWrapperRef}>
        <div className="sidebar-toggle-arrow">
          {this.state.class === "active" ? (
            <i
              className="fa fa-angle-left sidebar-toggle-arrow-icon sidebar-toggle-arrow-left-icon"
              aria-hidden="true"
              onClick={() => this.toggleSideBarExpansionHandler(false, true)}
            ></i>
          ) : (
            <i
              className="fa fa-angle-right sidebar-toggle-arrow-icon sidebar-toggle-arrow-right-icon"
              aria-hidden="true"
              onClick={() => this.toggleSideBarExpansionHandler(false, true)}
            ></i>
          )}
        </div>
        <div className="sidebar-header" />
        <ul className="list-unstyled">
          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              DASHBOARD_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={DASHBOARD_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={dashboard}
                  alt=""
                  title="Dashboard"
                />
                <span className="hidetext fontstyling">Dashboard</span>
              </div>
            </Link>
          </li>

          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              STORE_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={STORE_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={store}
                  alt=""
                  title="Store"
                />
                <span className="hidetext fontstyling">Store Eligibility Groups</span>
              </div>
            </Link>
          </li>

          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              CONSTRAINTS_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={CONSTRAINTS_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={Constraints}
                  alt=""
                  title="Constraints"
                />
                <span className="hidetext fontstyling">Constraints</span>
              </div>
            </Link>
          </li>

          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              PROFILE_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={PROFILE_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={product}
                  alt=""
                  title="Product Profile"
                />
                <span className="hidetext fontstyling">Product Profile</span>
              </div>
            </Link>
          </li>

          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              STRATEGY_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={STRATEGY_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={createAllocation}
                  alt=""
                  title="Create Allocation"
                />
                <span className="hidetext fontstyling">Create New Allocation</span>
              </div>
            </Link>
          </li>


          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              VIEW_ALLOCATION_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={VIEW_ALLOCATION_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={viewAllocation}
                  alt=""
                  title="View Allocation"
                />
                <span className="hidetext fontstyling">View Past Allocations</span>
              </div>
            </Link>
          </li>

          {
            !isHideIcon("reporting") &&
           <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              REPORTING_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={REPORTING_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={Reporting}
                  alt=""
                  title="Reporting"
                />
                <span className="hidetext fontstyling">Reporting</span>
              </div>
            </Link>
          </li> 
          }

          {
            !isHideIcon("ADA") &&
            <li
              onClick={() => this.toggleSideBarExpansionHandler()}
              className={classNames(
                "link",
                ADA_VISUAL_PAGE.includes(pathname) ? "active" : "",
                this.state.groupId == 2 ? "hide" : ""
              )}
            >
              <Link
                to={ADA_VISUAL_PAGE}
                className={this.state.disabledPromoCal ? "disabledCursor" : ""}
                onClick={(event) => {
                  if (this.state.disabledPromoCal) {
                    event.preventDefault();
                  }
                }}
              >
                <div className="link-label">
                  <img
                    className="icon"
                    src={Ada}
                    alt=""
                    title="Ada Visual"
                  />
                  <span className="hidetext fontstyling">Ada Visual</span>
                </div>
              </Link>
            </li>
          }


          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              PRODUCT_STORE_STATUS.includes(pathname) ? "active" : "",
              this.state.groupId == 2 ? "hide" : ""
            )}
          >
            <Link
              to={PRODUCT_STORE_STATUS}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={Configuration}
                  alt=""
                  title="Produ&ct & Store Status"
                />
                <span className="hidetext fontstyling">Configuration</span>
              </div>
            </Link>
          </li>

          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className={classNames(
              "link",
              USER_MANAGEMENT_PAGE.includes(pathname) ? "active" : "",
              this.state.groupId == 3 ? "hide" : ""
            )}
          >
            <Link
              to={USER_MANAGEMENT_PAGE}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                <img
                  className="icon"
                  src={userManagement}
                  alt=""
                  title="User Management"
                />
                <span className="hidetext fontstyling">User Management</span>
              </div>
            </Link>
          </li>
          <li
            onClick={() => this.toggleSideBarExpansionHandler()}
            className="link"
          >
            <a
              target="_blank"
              href={raiseTicketLink()}
              className={this.state.disabledPromoCal ? "disabledCursor" : ""}
              onClick={(event) => {
                if (this.state.disabledPromoCal) {
                  event.preventDefault();
                }
              }}
            >
              <div className="link-label">
                {/* <img
                  className="icon"
                  src={phone}
                  alt=""
                  title="Contact Us"
                /> */}
                <i class="icon fa fa-phone" style = {{color: "#ffffff !important;"}}></i>
                <span className="hidetext fontstyling">Contact Us</span>
              </div>
            </a>
          </li>
          {/* Navigation path to AdaVisual StandAlone Screen */}

        </ul>
      </nav>
    );
  }
}

export default withRouter(SidebarComponent);
