export const nsApproveInitState = {
    data: [],
    dataLoading: false,
    dataError: null,
    articleData: {},
    articleDataLoading: false,
    articleDataError: null,
    approveNewStoreArticlesLoading: false,
    approveNewStoreArticlesError: null,
    approveNewStoreArticlesSuccess: false
}