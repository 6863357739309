import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import {
  FETCH_FILTER_SUCCESS,
  GET_CHART_DATA_ERROR,
  GET_CHART_DATA_SUCCESS,
  RESET_FILTERS_DATA,
  CLEAR_CHART_DATA,
  GET_STYLE_INV_POPUP_DATA_SUCCESS,
  GET_STYLE_INV_POPUP_DATA_ERROR,
  GET_REVIEW_RECOMMEND_DATA_PROGRESS,
  GET_REVIEW_RECOMMEND_DATA_SUCCESS,
  GET_REVIEW_RECOMMEND_DATA_ERROR,
  GET_CHART_DATA,
  FETCH_FILTER_ERROR,
  FETCH_FILTER_DATA,
  GET_TRANSACTION_DATA,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  GET_RECOMMENDED_ACTIONS_DATA,
  GET_RECOMMENDED_ACTIONS_DATA_SUCCESS,
  GET_RECOMMENDED_ACTIONS_DATA_ERROR,
  GET_STYLE_INVENTORY_DATA_SUCCESS,
  GET_STYLE_INVENTORY_DATA_ERROR,
  GET_STYLE_INVENTORY_DATA,
  DOWNLOAD_EXCEL_DATA,
  RESET_DOWNLOAD_INDEX,
  GET_DOWNLOAD_EXCEL_DATA_SUCCESS,
  GET_DOWNLOAD_EXCEL_DATA_ERROR,
  GET_CHECK_ALL_DATA_SUCCESS,
  RESET_PAGE_INDEX
} from "./DashboardActions";

export const dashboardState = {
  weekSupply: null,
  storeStock: null,
  overallStock: null,
  pieLegends: null,
  styleInvData: null,
  recommendedData: null,
  reviewRecommendedData: null,
  styleInvPopupData: null,
  error: null,
  level1Options: [],
  level2Options: [],
  level3Options: [],
  level4Options: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  dcOptions: [],
  factoryTypeOptions:[],
  filterlabels: {},
  loading: false,
  recommendedActionsloading: false,
  recommendedActionserror: null,
  refreshDetails: null,
  styleInventoryloading: false,
  styleInvtotalCount: 0,
  styleInvnextIndex: 0,
  recDatatotalCount: 0,
  recDatanextIndex: 0,
  downloadExcelData: [],
  downloadNextIndex: 0,
  downloadTotalCount: 0,
  downloadExcelError: null,
  downloadLoading: false,
  checkAllData: [],
  checkAllNextIndex: 0,
  checkAllTotalCount: 0,
  reviewRecommendOutofData: false,
  reviewPageIndex: 0,
  reviewRecommendLoading: false,
  lmsStoreAttributeOptions: [],
  lmsStoreValueOptions: [],
  storeCodes: [],
  styleIdOptions: [],
  styleColorIdOptions: []
};

const reducer = {
  dashboard: handleActions(
    {
      [FETCH_FILTER_DATA]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: null },
        });
      },
      [FETCH_FILTER_ERROR]: (state, { payload }) => {
        return immutable(state, {
          // error: { $set: (payload && payload.error) || null },
        });
      },
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        switch (payload.key) {
          case "l1":
            return immutable(state, {
              level1Options: {
                $set:
                  payload && payload.data.level1Options
                    ? payload.data.level1Options
                    : state.level1Options,
              },
              level2Options: [],
              level3Options: [],
              lmsStoreAttributeOptions : {
                $set:
                payload && payload.data.lmsStoreAttributeOptions
                 ? payload.data.lmsStoreAttributeOptions
                 : state.lmsStoreAttributeOptions,
              },
              lmsStoreValueOptions : {
                $set:
                payload && payload.data.lmsStoreValueOptions
                 ? payload.data.lmsStoreValueOptions
                 : state.lmsStoreValueOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
            case "l10":
              return immutable(state, {
                level2Options: {
                  $set:
                    payload && payload.data.level2Options
                      ? payload.data.level2Options
                      : state.level2Options,
                },
                dcOptions: {
                  $set:
                    payload && payload.data.dcOptions
                      ? payload.data.dcOptions
                      : state.dcOptions,
                },
                factoryTypeOptions : {
                  $set:
                  payload && payload.data.factoryTypeOptions
                   ? payload.data.factoryTypeOptions
                   : state.factoryTypeOptions,
                },
                filterlabels : {
                  $set: 
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
              });
          case "l11":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l2":
            return immutable(state, {
              level2Options: {
                $set:
                  payload && payload.data.level2Options
                    ? payload.data.level2Options
                    : state.level2Options,
              },
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              factoryTypeOptions : {
                $set:
                payload && payload.data.factoryTypeOptions
                 ? payload.data.factoryTypeOptions
                 : state.factoryTypeOptions,
              },
              styleIdOptions : {
                $set:
                payload && payload.data.styleIdOptions
                 ? payload.data.styleIdOptions
                 : state.styleIdOptions,
              },
              styleColorIdOptions : {
                $set:
                payload && payload.data.styleColorIdOptions
                 ? payload.data.styleColorIdOptions
                 : state.styleColorIdOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l3":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              factoryTypeOptions : {
                $set:
                payload && payload.data.factoryTypeOptions
                 ? payload.data.factoryTypeOptions
                 : state.factoryTypeOptions,
              },
              styleIdOptions : {
                $set:
                payload && payload.data.styleIdOptions
                 ? payload.data.styleIdOptions
                 : state.styleIdOptions,
              },
              styleColorIdOptions : {
                $set:
                payload && payload.data.styleColorIdOptions
                 ? payload.data.styleColorIdOptions
                 : state.styleColorIdOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l4":
            return immutable(state, {
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              factoryTypeOptions : {
                $set:
                payload && payload.data.factoryTypeOptions
                 ? payload.data.factoryTypeOptions
                 : state.factoryTypeOptions,
              },
              styleIdOptions : {
                $set:
                payload && payload.data.styleIdOptions
                 ? payload.data.styleIdOptions
                 : state.styleIdOptions,
              },
              styleColorIdOptions : {
                $set:
                payload && payload.data.styleColorIdOptions
                 ? payload.data.styleColorIdOptions
                 : state.styleColorIdOptions,
              },
              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l6":
            return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              factoryTypeOptions : {
                $set:
                payload && payload.data.factoryTypeOptions
                 ? payload.data.factoryTypeOptions
                 : state.factoryTypeOptions,
              },
              styleIdOptions : {
                $set:
                payload && payload.data.styleIdOptions
                 ? payload.data.styleIdOptions
                 : state.styleIdOptions,
              },
              styleColorIdOptions : {
                $set:
                payload && payload.data.styleColorIdOptions
                 ? payload.data.styleColorIdOptions
                 : state.styleColorIdOptions,
              },
              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
            case "l7":
              return immutable(state, {
                level6Options: {
                  $set:
                    payload && payload.data.level6Options
                      ? payload.data.level6Options
                      : state.level6Options,
                },
                level7Options: {
                  $set:
                    payload && payload.data.level7Options
                      ? payload.data.level7Options
                      : state.level7Options,
                },
                dcOptions: {
                  $set:
                    payload && payload.data.dcOptions
                      ? payload.data.dcOptions
                      : state.dcOptions,
                },
                factoryTypeOptions : {
                  $set:
                  payload && payload.data.factoryTypeOptions
                   ? payload.data.factoryTypeOptions
                   : state.factoryTypeOptions,
                },
                styleIdOptions : {
                  $set:
                  payload && payload.data.styleIdOptions
                   ? payload.data.styleIdOptions
                   : state.styleIdOptions,
                },
                styleColorIdOptions : {
                  $set:
                  payload && payload.data.styleColorIdOptions
                   ? payload.data.styleColorIdOptions
                   : state.styleColorIdOptions,
                },
                // subdepartments: [],
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping,
                },
              });
            case "l8":
              return immutable(state, {
                level7Options: {
                  $set:
                    payload && payload.data.level7Options
                      ? payload.data.level7Options
                      : state.level7Options,
                },
                 dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              factoryTypeOptions : {
                $set:
                payload && payload.data.factoryTypeOptions
                 ? payload.data.factoryTypeOptions
                 : state.factoryTypeOptions,
              },
              styleIdOptions : {
                $set:
                payload && payload.data.styleIdOptions
                 ? payload.data.styleIdOptions
                 : state.styleIdOptions,
              },
              styleColorIdOptions : {
                $set:
                payload && payload.data.styleColorIdOptions
                 ? payload.data.styleColorIdOptions
                 : state.styleColorIdOptions,
              },
                // subdepartments: [],
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping,
                },
              });  
            case "dc":
            return immutable(state, {
              dcOptions: {
                $set:
                  payload && payload.data.data
                    ? payload.data.data
                    : state.dcOptions,
              },
              factoryTypeOptions : {
                $set:
                payload && payload.data.factoryTypeOptions
                 ? payload.data.factoryTypeOptions
                 : state.factoryTypeOptions,
              },
              styleIdOptions : {
                $set:
                payload && payload.data.styleIdOptions
                 ? payload.data.styleIdOptions
                 : state.styleIdOptions,
              },
              styleColorIdOptions : {
                $set:
                payload && payload.data.styleColorIdOptions
                 ? payload.data.styleColorIdOptions
                 : state.styleColorIdOptions,
              },
              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
            case "lmsStoreValue":
              return immutable(state, {
                lmsStoreValueOptions : {
                  $set:
                  payload && payload.data.lmsStoreValueOptions
                   ? payload.data.lmsStoreValueOptions
                   : state.lmsStoreValueOptions,
                },
              });
            case "style_color":
              return immutable(state, {
                styleColorIdOptions : {
                  $set:
                  payload && payload.data.styleColorIdOptions
                   ? payload.data.styleColorIdOptions
                   : state.styleColorIdOptions,
                },
              });
          default:
            return immutable(state, {
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
        };
      },
      [CLEAR_CHART_DATA]: (state, { payload }) => {
        return immutable(state, {
          weekSupply: { $set: null },
          storeStock: { $set: null },
          overallStock: { $set: null },
          pieLegends: { $set: null },
          styleInvData: { $set: null },
          recommendedData: { $set: null },
          reviewRecommendedData: { $set: null },
          styleInvPopupData: { $set: null },
          error: { $set: null },
          level1Options: { $set: [] },
          level2Options: { $set: [] },
          level3Options: { $set: [] },
          level4Options: { $set: [] },
          level5Options: { $set: [] },
          dcOptions: { $set: [] },
          filterlabels: { $set: {} },
          loading: { $set: false },
          styleInventoryloading: { $set: false },
          recommendedActionsloading: { $set: false },
          recommendedActionserror: { $set: null },
          refreshDetails: { $set: null },
          styleInvtotalCount: { $set: 0 },
          styleInvnextIndex: { $set: 0 },
          recDatatotalCount: { $set: 0 },
          recDatanextIndex: { $set: 0 },
          downloadExcelData: { $set: [] },
          downloadNextIndex: { $set: 0 },
          downloadTotalCount: { $set: 0 },
          reviewPageIndex: {$set: 0},
          reviewRecommendOutofData: {$set: false},
          styleIdOptions: {$set: []},
          styleColorIdOptions: {$set: []}
        });
      },
      [RESET_FILTERS_DATA]: (state, { payload }) => {
        return immutable(state, {
          level2Options: { $set: [] },
          level3Options: { $set: [] },
          level4Options: { $set: [] },
          level5Options: { $set: [] },
          level6Options: { $set: [] },
          level7Options: { $set: [] },
          lmsStoreValueOptions: { $set: [] },
          styleIdOptions: {$set: []},
          styleColorIdOptions: {$set: []}
          // dcOptions: { $set: [] },
        });
      },
      [GET_CHART_DATA]: (state, { }) => {
        return immutable(state, {
          loading: { $set: true },
          error: { $set: null },
        })
      },
      [GET_CHART_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          weekSupply: { $set: (payload && payload.weekSupply) || null },
          storeStock: { $set: (payload && payload.storeStock) || null },
          overallStock: { $set: (payload && payload.overallStock) || null },
          pieLegends: { $set: (payload && payload.pieLegends) || null },
          // styleInvData: {
          //   $set: (payload && payload.styleInventoryData) || null,
          // },
          // recommendedData: {
          //   $set: (payload && payload.recommendedData) || null,
          // },
          error: { $set: null },
          loading: { $set: false },
        });
      },
      [GET_CHART_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          weekSupply: { $set: null },
          storeStock: { $set: null },
          overallStock: { $set: null },
          // styleInvData: { $set: null },
          // recommendedData: { $set: null },
          error: { $set: (payload && payload.error) || null },
          loading: { $set: false },
        });
      },
      [GET_STYLE_INVENTORY_DATA]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: null },
          styleInventoryloading: { $set: true },
          // styleInvtotalCount: { $set: 0 },
          styleInvnextIndex: { $set: 0 },
          // checkAllData: {$set: []},
          checkAllNextIndex: { $set: 0 },
          checkAllTotalCount: { $set: 0 },
          styleInvData: { $set: []},
        })
      },
      [GET_STYLE_INVENTORY_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          styleInvData: {
            $set: (payload && payload.data) || null,
          },
          storeCodes: {
            $set: (payload && payload.storeCodes) || null,
          },
          error: { $set: null },
          styleInventoryloading: { $set: false },
          styleInvtotalCount: { $set: (payload && payload.totalCount) || 0 },
          styleInvnextIndex: { $set: (payload && payload.nextIndex) || 0 }
        })
      },
      [GET_STYLE_INVENTORY_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          styleInvData: { $set: null },
          error: { $set: (payload && payload.error) || null },
          styleInventoryloading: { $set: false },
        })
      },
      [GET_RECOMMENDED_ACTIONS_DATA]: (state, { }) => {
        return immutable(state, {
          recommendedActionsloading: { $set: true },
          recommendedActionserror: { $set: null },
        })
      },
      [GET_RECOMMENDED_ACTIONS_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          recommendedData: {
            $set: (payload && payload.recommendedData) || null,
          },
          recommendedActionserror: { $set: null },
          recommendedActionsloading: { $set: false },
        });
      },
      [GET_RECOMMENDED_ACTIONS_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          recommendedData: { $set: null },
          recommendedActionserror: { $set: (payload && payload.error) || null },
          recommendedActionsloading: { $set: false },
        });
      },
      [GET_TRANSACTION_DATA]: (state, { }) => {
        return immutable(state, {
          refreshDetails: { $set: null },
          styleInvData: { $set: null }
        })
      },
      [GET_TRANSACTION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          refreshDetails: { $set: (payload && payload.data) || null }
        });
      },
      [GET_TRANSACTION_ERROR]: (state, { payload }) => {
        return immutable(state, {
          refreshDetails: { $set: null }
        });
      },
      [GET_STYLE_INV_POPUP_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          styleInvPopupData: { $set: (payload && payload.data) || null },
          error: { $set: null },
        });
      },
      [GET_STYLE_INV_POPUP_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          styleInvPopupData: { $set: null },
          error: { $set: (payload && payload.error) || null },
        });
      },
      [RESET_PAGE_INDEX]: (state, { payload }) => {
        return immutable(state, {
          reviewRecommendOutofData: { $set: false },
          reviewPageIndex: { $set: 0 },
        });
      },
      [GET_REVIEW_RECOMMEND_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          reviewRecommendedData: { $set: (payload && payload.data) || null },
          error: { $set: null },
          recDatatotalCount: { $set: (payload && payload.totalCount) || 0 },
          recDatanextIndex: { $set: (payload && payload.nextIndex) || 0 },
          reviewRecommendOutofData: {$set: (payload && payload?.out_of_data)},
          reviewPageIndex: { $set: (payload && state.reviewPageIndex + payload.data?.length) || 0 },
          reviewRecommendLoading: { $set: false },
        });
      },
      [GET_REVIEW_RECOMMEND_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          reviewRecommendedData: { $set: null },
          error: { $set: (payload && payload.error) || null },
          reviewRecommendOutofData: {$set: false}
        });
      },
      [GET_REVIEW_RECOMMEND_DATA_PROGRESS]: (state, { payload }) => {
        return immutable(state, {
          reviewRecommendedData: { $set: null },
          recDatatotalCount: { $set: 0 },
          recDatanextIndex: { $set: 0 },
          error: { $set: null },
          reviewRecommendLoading: { $set: true },
        });
      },
      [DOWNLOAD_EXCEL_DATA]: (state, { payload }) => {
        return immutable(state, {
          downloadExcelData: { $set: (payload && payload.data) || null },
          // downloadNextIndex: { $set: (payload && payload.nextIndex) || 0 },
          // downloadTotalCount: { $set: (payload && payload.totalCount) || 0 },
          downloadExcelError: { $set: null },
          downloadLoading: { $set: true }
          // styleInventoryloading: { $set: payload.nextIndex > payload.totalCount ? false : true },
        });
      },
      [GET_DOWNLOAD_EXCEL_DATA_SUCCESS]: (state, payload) => {
        return immutable(state, {
          downloadExcelData: { $set: (payload && state.downloadLoading && payload.data) || null },
          downloadNextIndex: { $set: (payload && state.downloadLoading && payload.nextIndex) || 0 },
          downloadTotalCount: { $set: (payload && state.downloadLoading && payload.totalCount) || 0 },
          downloadExcelError: { $set: null }
          // styleInventoryloading: { $set: payload.nextIndex > payload.totalCount ? false : true },
        });
      },
      [GET_DOWNLOAD_EXCEL_DATA_ERROR]: (state, payload) => {
        return immutable(state, {
          downloadExcelData: { $set: [] },
          downloadNextIndex: { $set: 0 },
          downloadTotalCount: { $set: 0 },
          downloadExcelError: { $set: payload.error }
          // styleInventoryloading: { $set: payload.nextIndex > payload.totalCount ? false : true },
        });
      },
      [GET_CHECK_ALL_DATA_SUCCESS]: (state, {payload}) => {
        return immutable(state, {
          checkAllData: { $set: payload && payload.data || [] },
          checkAllNextIndex: { $set: payload && payload.nextIndex || 0 },
          checkAllTotalCount: { $set: payload && payload.totalCount || 0 },
          styleInventoryloading: { $set: payload.nextIndex > payload.totalCount ? false : true },
        });
      },
      [RESET_DOWNLOAD_INDEX]: (state, { payload }) => {
        return immutable(state, {
          downloadExcelData: { $set: [] },
          downloadNextIndex: { $set: 0 },
          downloadTotalCount: { $set: 0 },
          styleInventoryloading: { $set: false },
          downloadExcelError: { $set: null },
          downloadLoading: { $set: false },
          checkAllData: { $set: [] },
          checkAllNextIndex: { $set: 0 },
          checkAllTotalCount: { $set: 0 },
        });
      },
    },
    dashboardState
  ),
};

export default reducer;
