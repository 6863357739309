export const dcConfigInitialState = {
    filterData: [],
    filterDataLoading: false,
    filterDataError: null,
    sisterStoreFilters: [],
    selectedSisterStores: {},
    saveNewStoreSuccess: false,
    saveNewStoreError: false,
    saveNewStoreLoading: false,
    articleReservedData: [],
    articleReservedDataLoading: false,
    articleReservedDataError: null,
    selectedDc: [],
    leadTimeDcMapping: {},
    reservedArticles: [],
    reservedArticlesLoading: false,
    reservedArticlesError: null,
    deleteNewStoreLoading: false,
    deleteNewStoreSuccess: null,
    deleteNewStoreError: null,
    sisterStoreMapping: {},
    storeGrades: [],
    newStoreAllocations: [],
    newStoreAllocationsLoading: false,
    newStoreAllocationsError: null,
    assort: {},
    existingLength: false,
    isEdit: false
};