import * as actionTypes from "../../actions/DCConfigAction/DCConfigActionTypes"
import { dcConfigInitialState } from "./DCConfigInitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { cloneDeep } from "lodash";

export default {
    sisterStoreDCConfig: handleActions(
        {
            [actionTypes.GET_SISTER_STORE_FILTERS]: (state, {payload}) => {
                if(payload?.l2_name) {
                    let data = cloneDeep(state.assort)
                    delete data[payload.index]
                    return immutable(state, {
                        assort: { $set: data || {} },
                        filterDataLoading: { $set: false },
                        // filterData: { $set: state.filterData}
                        // demandDataError: { $set: null }
                    });
                }
                else {
                    return immutable(state, {
                        filterData: { $set: [] },
                        filterDataLoading: { $set: true },
                        // demandDataError: { $set: null },
                    });
                }
            },
            [actionTypes.GET_SISTER_STORE_FILTERS_SUCCESS]: (state, payload) => {
                if(payload?.assort) {
                    let data = cloneDeep(state.assort)
                    data[payload.index] = payload.data
                    return immutable(state, {
                        assort: { $set: data || {} },
                        filterDataLoading: { $set: false },

                    });
                }
                else {
                    return immutable(state, {
                        filterData: { $set: (payload && payload.data) || [] },
                        filterDataLoading: { $set: false },
                    });
                }
            },
            [actionTypes.GET_SISTER_STORE_FILTERS_ERROR]: (state, payload) => {
                return immutable(state, {
                    filterData: { $set: [] },
                    filterDataLoading: { $set: false },
                    // demandDataError: { $set: (payload && payload.error) || null }
                });
            },
            [actionTypes.SAVE_SISTER_STORE_FILTERS]: (state, data) => {
                return immutable(state, {
                    sisterStoreFilters: { $set: data.payload },
                    existingLength: { $set: state.existingLength ? state.existingLength : (data?.approved ? data.payload?.length : 0) }
                });
            },
            [actionTypes.SAVE_SELECTED_SISTER_STORES]: (state, data) => {
                return immutable(state, {
                    selectedSisterStores: { $set: data.payload },
                    isEdit: { $set: false }
                });
            },
            [actionTypes.SET_EDIT_SISTER_STORE]: (state, data) => {
                return immutable(state, {
                    isEdit: { $set: true }
                });
            },
            [actionTypes.UPSERT_NEW_STORE_DETAILS]: (state, payload) => {
                return immutable(state, {
                    saveNewStoreLoading: { $set: true },
                    saveNewStoreSuccess: { $set: false },
                    saveNewStoreError: { $set: false },
                });
            },
            [actionTypes.UPSERT_NEW_STORE_DETAILS_ERROR]: (state, payload) => {
                return immutable(state, {
                    saveNewStoreLoading: { $set: false },
                    saveNewStoreSuccess: { $set: payload?.error },
                    saveNewStoreError: { $set: true },
                });
            },
            [actionTypes.UPSERT_NEW_STORE_DETAILS_SUCCESS]: (state, data) => {
                return immutable(state, {
                    saveNewStoreLoading: { $set: false },
                    saveNewStoreSuccess: { $set: true },
                    saveNewStoreError: { $set: false },
                });
            },
            [actionTypes.GET_NEW_RESERVED_ARTICLE_LIST]: (state, payload) => {
                return immutable(state, {
                    articleReservedData: { $set: [] },
                    articleReservedDataLoading: { $set: true },
                    articleReservedDataError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_RESERVED_ARTICLE_LIST_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    articleReservedData: { $set: payload?.data || [] },
                    articleReservedDataLoading: { $set: false },
                    articleReservedDataError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_RESERVED_ARTICLE_LIST_ERROR]: (state, payload) => {
                return immutable(state, {
                    articleReservedData: { $set: [] },
                    articleReservedDataLoading: { $set: false },
                    articleReservedDataError: { $set: payload?.error }
                });
            },
            [actionTypes.SAVE_SELECTED_DC]: (state, { payload }) => {
                return immutable(state, {
                    selectedDc: { $set: payload.data || [] },
                });
            },
            [actionTypes.SAVE_LEAD_TIME_DC_MAPPING]: (state, { payload }) => {
                return immutable(state, {
                    leadTimeDcMapping: { $set: payload.data || {} },
                });
            },
            [actionTypes.GET_RESERVED_ARTICLES]: (state, payload) => {
                return immutable(state, {
                    reservedArticles: { $set: [] },
                    reservedArticlesLoading: { $set: true },
                    reservedArticlesError: { $set: null }
                });
            },
            [actionTypes.GET_RESERVED_ARTICLES_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    reservedArticles: { $set: payload?.data || [] },
                    reservedArticlesLoading: { $set: false },
                    reservedArticlesError: { $set: null }
                });
            },
            [actionTypes.GET_RESERVED_ARTICLES_ERROR]: (state, payload) => {
                return immutable(state, {
                    reservedArticles: { $set: [] },
                    reservedArticlesLoading: { $set: false },
                    reservedArticlesError: { $set: payload?.error }
                });
            },
            [actionTypes.DELETE_NEW_STORE]: (state, payload) => {
                return immutable(state, {
                    deleteNewStoreLoading: { $set: true },
                    deleteNewStoreSuccess: { $set: null },
                    deleteNewStoreError: { $set: null }
                });
            },
            [actionTypes.DELETE_NEW_STORE_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    deleteNewStoreLoading: { $set: false },
                    deleteNewStoreSuccess: { $set: true },
                    deleteNewStoreError: { $set: null }
                });
            },
            [actionTypes.DELETE_NEW_STORE_ERROR]: (state, payload) => {
                return immutable(state, {
                    deleteNewStoreLoading: { $set: false },
                    deleteNewStoreSuccess: { $set: null },
                    deleteNewStoreError: { $set: payload?.error }
                });
            },
            [actionTypes.GET_STORE_GRADES]: (state, payload) => {
                return immutable(state, {
                    storeGrades: { $set: [] }
                });
            },
            [actionTypes.GET_STORE_GRADES_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storeGrades: { $set: payload?.data || [] },
                });
            },
            [actionTypes.GET_STORE_GRADES_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeGrades: { $set: [] }
                });
            },
            [actionTypes.RESET_DELETE_DATA]: (state, payload) => {
                return immutable(state, {
                    deleteNewStoreLoading: { $set: false },
                    deleteNewStoreSuccess: { $set: false },
                    deleteNewStoreError: { $set: null }
                });
            },
            [actionTypes.SET_SISTER_STORE_MAPPING]: (state, data) => {
                return immutable(state, {
                    sisterStoreMapping: { $set: data.payload },
                });
            },
            [actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS]: (state, payload) => {
                return immutable(state, {
                    newStoreAllocations: { $set: [] },
                    newStoreAllocationsLoading: { $set: true },
                    newStoreAllocationsError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    newStoreAllocations: { $set: payload?.data || [] },
                    newStoreAllocationsLoading: { $set: false },
                    newStoreAllocationsError: { $set: null }
                });
            },
            [actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS_ERROR]: (state, payload) => {
                return immutable(state, {
                    newStoreAllocations: { $set: [] },
                    newStoreAllocationsLoading: { $set: false },
                    newStoreAllocationsError: { $set: payload?.error }
                });
            },
            [actionTypes.RESET_NEW_STORE_DETAILS]: (state, data) => {
                return immutable(state, {
                    filterData: { $set: [] },
                    filterDataLoading: { $set: false },
                    filterDataError: { $set: null },
                    sisterStoreFilters: { $set: [] },
                    selectedSisterStores: { $set: {} },
                    saveNewStoreSuccess: { $set: false },
                    saveNewStoreError: { $set: false },
                    saveNewStoreLoading: { $set: false },
                    articleReservedData: { $set: [] },
                    articleReservedDataLoading: { $set: false },
                    articleReservedDataError: { $set: null },
                    leadTimeDcMapping: { $set: {} },
                    reservedArticles: { $set: [] },
                    reservedArticlesLoading: { $set: false },
                    reservedArticlesError: { $set: null },
                    reservedArticles: {$set: []},
                    reservedArticlesError: { $set: null },
                    reservedArticlesLoading: { $set: false },
                    deleteNewStoreLoading: { $set: false },
                    deleteNewStoreSuccess: { $set: null },
                    deleteNewStoreError: { $set: null },
                    sisterStoreMapping: { $set: {} },
                    // storeGrades: { $set: [] },
                    newStoreAllocations: { $set: [] },
                    newStoreAllocationsLoading: { $set: true },
                    newStoreAllocationsError: { $set: null },
                    existingLength: { $set: 0 },
                    assort: { $set: {} }
                });
            }
        },
        dcConfigInitialState
    )
}